import { createNumberMask, emailMask } from 'text-mask-addons';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function getMask(type: string) {
  switch (type) {
    case 'celular':
      return [
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];

    case 'text':
      return false;

    case 'email':
      return emailMask;

    case 'number':
      return [/\d*/];

    case 'rn':
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];

    case 'rg':
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];

    case 'currency':
      return createNumberMask({
        prefix: 'R$ ',
        suffix: '',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2,
      });

    case 'cpf':
      return [
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ];

    case 'cnpj':
      return [
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
      ];

    default:
      return [/\*/g];
  }
}
