import { IMenu, MENU_ITEMS } from './menu';
import logoSvg from '../../assets/svg/logo-min.svg';
import { ReactSVG } from 'react-svg';
import { NavLink, useNavigate } from 'react-router-dom';
// import MenuSwitch from './menuSwitch';
import { classNames } from '../../utils/functions';
import MenuLeftPair from './menuLeftPair';
import { useSocket } from '../../hooks/socket.context';
import { useAuth } from '../../hooks/auth.hook';
import { Comunication } from '../../app.models';

const menuSimpleClass = (active: boolean, containTitle = true) =>
  classNames(
    'flex justify-start relative -top-5',
    'text-primary1 md:text-default-white',
    'w-12 h-12 relative z-50',
    containTitle && 'hover:w-full hover:left-0 group',
    'rounded-full shadow',
    active
      ? 'text-default-white bg-primary1'
      : 'bg-default-white text-primary1',
  );

const MenuSimple = (menu: IMenu) => {
  return (
    <NavLink
      to={menu.link}
      onClick={() => {
        if (location.pathname === menu.link.trim()) return;
        menu.onClick && menu.onClick();
      }}
    >
      {({ isActive }) => (
        <div className="max-h-12">
          {menu.info !== null ? (
            <div className="bg-red-600 p-[2px] relative w-5 h-5 rounded-full text-xs text-white text-center z-[60] animate-pulse">
              {menu.info.toString()}
            </div>
          ) : (
            <div className=" relative w-5 h-5 rounded-full"></div>
          )}
          <div className={menuSimpleClass(isActive)}>
            <div className="flex justify-start items-center space-x-1">
              <span
                className={classNames(
                  isActive ? 'text-default-white' : ' text-primary1',
                  'p-3',
                )}
                title={menu.title}
              >
                {menu.icon}
              </span>
              {menu.title && (
                <span
                  className={classNames(
                    'hidden group-hover:flex',
                    isActive ? 'text-default-white' : 'text-primary1',
                    'pr-4',
                  )}
                >
                  {menu.title}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </NavLink>
  );
};

interface MenuLeftMinProps {
  menuMin: boolean;
  setMenuMin: (value: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function MenuLeftMin(props: MenuLeftMinProps) {
  const navigate = useNavigate();
  const { comunications } = useSocket();
  const { getJwt } = useAuth();

  return (
    <div className="grid grid-cols-1 justify-center px-1">
      {/* <div className="py-1">
        <MenuSwitch checked={props.menuMin} onChange={props.setMenuMin} />
      </div> */}
      <div className="h-14 w-full items-center hidden md:flex mt-4 hover:opacity-50">
        <button
          type="button"
          onClick={() => navigate('/')}
          className="text-default-white fill-current w-full p-1"
        >
          <ReactSVG src={logoSvg} width={14} />
        </button>
      </div>
      <ul className="mt-4 flex flex-col mr-auto space-y-2 mx-1">
        {MENU_ITEMS.map((item, idx) => {
          const filter = (c: Comunication) =>
            c.archived === false &&
            c.status === 'closed' &&
            c.receiver.id === getJwt()?.id;
          switch (item.info) {
            case 'comunication': {
              return (
                <li key={idx} className="left-0 relative">
                  <MenuSimple
                    {...item}
                    info={
                      comunications.filter(filter).length > 0
                        ? comunications.filter(filter).length
                        : null
                    }
                  />
                </li>
              );
            }
            default:
              return (
                <li key={idx} className="left-0 relative">
                  <MenuSimple {...item} />
                </li>
              );
          }
        })}
      </ul>
      <MenuLeftPair />
    </div>
  );
}
