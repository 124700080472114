import { toast } from 'react-toastify';
import { LOCAL_STORAGE_PREFIX } from '../app.settings';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const arrayGroupBy = (array: any[], key: string): any[] => {
  const keys = key.split('.');
  const count = keys.length;
  try {
    switch (count) {
      case 1: {
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]]] = acc[cur[keys[0]]] || []).push(cur);
          return acc;
        }, []);
      }
      case 2:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]]] = acc[cur[keys[0]][keys[1]]] || []).push(
            cur,
          );
          return acc;
        }, []);
      case 3:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]][keys[2]]] =
            acc[cur[keys[0]][keys[1]][keys[2]]] || []).push(cur);
          return acc;
        }, []);
      case 4:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]][keys[2]][keys[3]]] =
            acc[cur[keys[0]][keys[1]][keys[2]][keys[3]]] || []).push(cur);
          return acc;
        }, []);
      case 5:
        return array.reduce((acc, cur) => {
          (acc[cur[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]]] =
            acc[cur[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]]] || []).push(
            cur,
          );
          return acc;
        }, []);
      default:
        return [];
    }
  } catch {
    return [];
  }
};

export function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export const replaceImgWithError = (e: any, imgHref?: string) => {
  e.target.onerror = null;
  e.target.src = imgHref || 'https://via.placeholder.com/100?text=Not Found';
};

export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export const getTake = () =>
  localStorage.getItem(LOCAL_STORAGE_PREFIX + 'page-take') || 50;

export function flatten(i: any, arrayField: string | number): any[] {
  if (Array.isArray(i)) return i.map(c => flatten(c, arrayField));
  // eslint-disable-next-line no-prototype-builtins
  if (i.hasOwnProperty(arrayField))
    return [
      { ...i, [arrayField]: null },
      ...i[arrayField].map((c: any) => flatten(c, arrayField)),
    ];
  return { ...i, [arrayField]: null };
}

export function showErrors(errors: string | string[]): void {
  if (Array.isArray(errors)) {
    errors.map((error: string) => toast.error(error));
  } else {
    toast.error(errors);
  }
}

export const rightString = (str: string, length: number): string => {
  return str.substring(str.length - length);
};

export const getPhoneFormat = (phone: string): string => {
  const newPhone = rightString(phone, 10);
  // insert 9 digit into celular number
  const celularNumber = newPhone.substring(0, 2) + '9' + newPhone.substring(2);
  return celularNumber.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
};

export const secondsToMinutes = (sec: number) => {
  const days = Math.floor(sec / (24 * 60 * 60));
  const daysms = sec % (24 * 60 * 60);
  const hours = Math.floor(daysms / (60 * 60));
  const hoursms = sec % (60 * 60);
  const minutes = Math.floor(hoursms / 60);

  return `${days >= 1 ? `${days}d` : ``} ${hours >= 1 ? `${hours}h` : ``} ${
    minutes >= 1 ? `${minutes}m` : `${sec}s`
  }`;
};

export const getSatisfaction = (satisfaction: number): string => {
  if (satisfaction === 1) {
    return 'Nada Satisfeito';
  }
  if (satisfaction === 2) {
    return 'Satisfeito';
  }
  if (satisfaction === 3) {
    return 'Muito satisfeito';
  }

  return 'N/D';
};

export const rawPhone = (_phone: string): string => {
  const phone = _phone.replace(/\D/g, '');
  return phone.slice(0, 2) + phone.slice(3);
};

export function blobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
