/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { ApiResponse } from '../../app.models';

import api from '../../axios';
import { showErrors } from '../../utils/functions';

export async function read(): Promise<Record<string, any>> {
  try {
    const url = '/config';
    const { data } = await api.get<ApiResponse<Record<string, any>>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function write(
  config: Record<string, any>,
): Promise<Record<string, any>> {
  try {
    const url = '/config';
    const { data } = await api.post<ApiResponse<Record<string, any>>>(
      url,
      config,
    );
    toast.success('Configurações salvas com sucesso!');
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function readGeneral(): Promise<Record<string, any>> {
  try {
    const url = '/config/general';
    const { data } = await api.get<ApiResponse<Record<string, any>>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function writeGeneral(
  general: Record<string, any>,
): Promise<Record<string, any>> {
  try {
    const url = '/config/general';
    const { data } = await api.post<ApiResponse<Record<string, any>>>(
      url,
      general,
    );
    toast.success('Configurações salvas com sucesso!');
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function readInstances(): Promise<Record<string, any>> {
  try {
    const url = '/config/instances';
    const { data } = await api.get<ApiResponse<Record<string, any>>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function readApiVersion(): Promise<Record<string, any>> {
  try {
    const url = '';
    const { data } = await api.get<ApiResponse<Record<string, any>>>(url);
    return data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function readAppVersion(): Promise<Record<string, any>> {
  return await new Promise(resolve =>
    resolve({
      name: process.env.REACT_APP_NAME,
      version: process.env.REACT_APP_VERSION,
    }),
  );
}
