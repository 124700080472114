import { ButtonHTMLAttributes } from 'react';
import { classNames } from '../../utils/functions';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: JSX.Element;
  title?: string;
  colors?: string;
  waiting?: boolean;
}

export default function Button(props: ButtonProps) {
  return (
    <button
      className={classNames(
        'disabled:bg-gray-500 disabled:textWhite',
        'flex justify-center items-center rounded-lg px-4 py-2',
        props.colors ? props.colors : 'bg-primary1 text-primary5',
        'hover:opacity-70 hover:text-white',
      )}
      disabled={props.waiting}
      {...props}
    >
      {props.waiting && (
        <svg
          className="-ml-1 mr-3 h-5 w-5 animate-spin textWhite"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {props.icon && (
        <span className={props.title ? 'mr-2' : ''}>{props.icon}</span>
      )}
      <span className="font-medium subpixel-antialiased text-sm">
        {props.title}
      </span>
    </button>
  );
}
