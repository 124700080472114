import { SearchIcon, XIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES_MAP from '../../../app.map.routes';
import Button from '../../../components/layout/button';
import { Repository } from './repository.model';
import { search } from './repository.service';

import _audio from '../../../assets/svg/audio.svg';
import _video from '../../../assets/svg/video.svg';
import _document from '../../../assets/svg/document.svg';
import ListRepository from './listRepository';
import LazyLoadingImage from '../../../components/lazyLadingImage';
import AppPaginate from '../../../components/paginate';

export const getRepositoryType = (type: string, path: string, size = '48') => {
  switch (type) {
    case 'Document':
      return {
        type: 'Documento',
        icon: React.createElement('img', {
          src: _document,
          width: size,
          height: size,
          alt: 'Documento',
          className: 'mx-auto',
        }),
      };
    case 'Audio':
      return {
        type: 'Aúdio',
        icon: React.createElement('img', {
          src: _audio,
          width: size,
          height: size,
          alt: 'Aúdio',
          className: 'mx-auto',
        }),
      };
    case 'Video':
      return {
        type: 'Vídeo',
        icon: React.createElement('img', {
          src: _video,
          width: size,
          height: size,
          alt: 'Vídeo',
          className: 'mx-auto',
        }),
      };
    case 'Image':
      return {
        type: 'Imagem',
        icon: React.createElement(LazyLoadingImage, {
          src: path,
          width: parseInt(size),
          height: parseInt(size),
          alt: 'Documento',
          classNames: ['mx-auto'],
        }),
      };
    default:
      return {
        type: 'Não identificado',
        icon: null,
      };
  }
};

const CadRepository: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit, reset } = useForm();

  const [pageCount, setPageCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchText, setSearchText] = useState<Record<string, any>>({});

  useEffect(() => {
    search(searchText, page, limit).then(data => {
      setLimit(data.meta.itemsPerPage);
      setPageCount(data.meta.totalPages);
      setRepositories(data.items);
    });
  }, [limit, page, searchText]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params = (location.state as any)?.['params'];
    if (params) {
      setPage(params.page);
      setLimit(params.limit);
    }
  }, [location.state]);

  const [repositories, setRepositories] = React.useState<Repository[]>([]);

  const submit = async (form: unknown) => {
    const data = form as Record<string, unknown>;
    setSearchText({ desc: data.desc, type: data.type });
  };

  const _reset = () => {
    setSearchText({});
    reset();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePageClick = (event: { selected: any }) => {
    const newOffset = event.selected + 1;
    setPage(newOffset);
  };

  return (
    <div className="md:h-[85vh] mt-28 md:mt-0 px-4 overflow-hidden overflow-y-auto">
      <div className="flex flex-col items-center md:flex-row md:justify-between my-1">
        <strong className="text-xl">Repositório de Arquivos</strong>
        <div className="flex space-x-2">
          <form
            onSubmit={handleSubmit(submit)}
            className="relative text-gray-600 flex flex-col md:flex-row md:justify-end space-x-1"
          >
            <div className="flex flex-col md:flex-row">
              <select
                id="type"
                className="form-input px-3 py-1 pr-10 md:w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Tipo"
                {...register('type', { required: false })}
              >
                <option value="">Tipo ...</option>
                <option value="Image">Imagem</option>
                <option value="Document">Documento</option>
                <option value="Audio">Áudio</option>
                <option value="Video">Vídeo</option>
              </select>
              <input
                className="border-2 border-gray-300 bg-white h-10 px-1 md:px-5 rounded-lg text-sm focus:outline-none"
                type="desc"
                {...register('desc', { required: false })}
                placeholder="Busca descrição"
              />
            </div>
            <div className="flex justify-between md:justify-start">
              <button type="reset" className="" onClick={_reset}>
                <XIcon className="w-6 h-6 stroke-current" />
              </button>
              <button type="submit" className="">
                <SearchIcon className="w-6 h-6 stroke-current" />
              </button>
            </div>
          </form>
        </div>
        <Button
          title="Inserir"
          onClick={() => navigate(ROUTES_MAP.repositoryNew)}
        />
      </div>
      {/* <TableRepository
        data={repositories}
        location={location}
        page={page}
        limit={limit}
      /> */}
      <ListRepository
        data={repositories}
        location={location}
        page={page}
        limit={limit}
      />

      <AppPaginate
        onPageChange={handlePageClick}
        pageCount={pageCount}
        page={page}
      />
    </div>
  );
};

export default CadRepository;
