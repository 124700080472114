import { PrinterIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import DateRangeInput from '../../../components/dateRange';
import Button from '../../../components/layout/button';
import SelectFilter from '../../../components/selectFilter';
import { useAuth } from '../../../hooks/auth.hook';
import { getSatisfaction } from '../../../utils/functions';
import { User } from '../../cad/user/user.model';
import { list as listUsers } from '../../cad/user/user.service';
import { Chat } from '../../chat/chat.model';
import { search } from '../../chat/chat.service';
import { generateAttendancePdf } from '../relat.service';
import TableRelatAttendeance from './tableRelatAttendance';

import SlidingPanel from 'react-sliding-side-panel';
import 'react-sliding-side-panel/lib/index.css';
import ListMessages from '../../chat/listMessages';
import useMedia from 'use-media';
import CardRelatAttendeance from './cardRelatAttendance';
import PageLoading from '../../../components/pageLoading';

export default function RelatAttendence(): JSX.Element {
  const { getJwt } = useAuth();
  const isSmall = useMedia({ maxWidth: 480 });
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [chats, setChats] = useState<Chat[]>([]);
  const [filterUsers, setFilterUsers] = useState<string[]>([]);
  const [filterSatisfactions, setFilterSatisfactions] = useState<string[]>([]);

  const [openPanel, setOpenPanel] = useState(false);

  const [selected, setSelected] = useState<Chat>({} as Chat);
  const ref = useRef<HTMLDivElement>(null);

  const [dateRange, setDateRange] = useState<{
    start: string;
    end: string;
  }>();

  useEffect(() => {
    listUsers().then(setUsers);
  }, []);

  useEffect(() => {
    if (!dateRange) return;
    const start = moment(dateRange.start).startOf('day');
    const end = moment(dateRange.end).endOf('day');
    setLoading(true);
    if (start && end) {
      search({
        period: [start, end],
      })
        .then(data => {
          // setLoading(false);
          setChats(
            data
              .filter(chat =>
                chat.user.id && filterUsers.length > 0
                  ? filterUsers.includes(chat.user.id)
                  : true,
              )
              .filter(chat =>
                chat.satisfaction && filterSatisfactions.length > 0
                  ? filterSatisfactions.includes(chat.satisfaction.toString())
                  : true,
              ),
          );
        })
        .finally(() => setLoading(false));
    }
  }, [dateRange, filterSatisfactions, filterUsers]);

  const printer = (): void => {
    const period =
      dateRange?.start && dateRange?.end
        ? [dateRange.start, dateRange.end]
        : null;
    const filters = {};
    if (filterUsers.length > 0) {
      Object.assign(filters, {
        Usuários: filterUsers.map(
          u => users.find(user => user.id === u)?.user || 'N/D',
        ),
      });
    }
    if (filterSatisfactions.length > 0) {
      Object.assign(filters, {
        Satisfação: filterSatisfactions.map(s => getSatisfaction(parseInt(s))),
      });
    }
    const user = getJwt()?.user || 'N/D';
    generateAttendancePdf(chats, period, filters, user);
  };

  return (
    <div className="w-full px-2">
      <div className="flex justify-between items-center my-1">
        <h1 className="text-lg font-bold text-gray-300">
          Relatório de Atendimentos
        </h1>
        {chats.length > 0 && (
          <Button
            title="Imprimir"
            icon={<PrinterIcon className="w-4 h-4" />}
            onClick={printer}
          />
        )}
      </div>

      <div className="flex flex-col md:flex-row md:space-x-2 justify-between">
        <DateRangeInput
          action={(startDate, endDate) => {
            console.log(startDate, endDate);
            if (startDate && endDate) {
              setDateRange({ start: startDate, end: endDate });
            }
          }}
        />
        <div className="flex flex-col md:flex-row md:space-x-1 md:items-center space-y-1 md:space-y-0">
          <strong>Filtros:</strong>
          <SelectFilter
            filters={users.map(u => ({
              display: u.user.toLocaleUpperCase(),
              value: u.id,
            }))}
            placeholder={'Todos Atendentes'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action={(
              filters: {
                display: string;
                value: string;
              }[],
            ) =>
              setFilterUsers(
                filters.reduce<string[]>((acc, cur) => {
                  if (cur) {
                    acc.push(cur as unknown as string);
                  }
                  return acc;
                }, []),
              )
            }
          />
          <SelectFilter
            filters={[
              { display: 'Nada Satisfeito', value: '1' },
              { display: 'Satisfeito', value: '2' },
              { display: 'Muito Satisfeito', value: '3' },
            ]}
            placeholder={'Todos os Níveis de Satisfação'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            action={(
              filters: {
                display: string;
                value: string;
              }[],
            ) =>
              setFilterSatisfactions(
                filters.reduce<string[]>((acc, cur) => {
                  if (cur) {
                    acc.push(cur as unknown as string);
                  }
                  return acc;
                }, []),
              )
            }
          />
        </div>
      </div>

      <div className="md:h-[70vh] overflow-hidden overflow-y-auto my-2">
        {loading ? (
          <PageLoading height="w-24" width="h-24" />
        ) : (
          <>
            {isSmall ? (
              <CardRelatAttendeance
                data={chats}
                rowClick={chat => {
                  setSelected(chat);
                  setOpenPanel(true);
                }}
              />
            ) : (
              <TableRelatAttendeance
                data={chats}
                rowClick={chat => {
                  setSelected(chat);
                  setOpenPanel(true);
                }}
              />
            )}
          </>
        )}
      </div>

      <SlidingPanel type={'right'} isOpen={openPanel} size={isSmall ? 100 : 30}>
        <div className="bg-white h-full">
          <div className="p-2 bg-primary1 text-default-white">
            <button onClick={() => setOpenPanel(false)}>Fechar</button>
          </div>
          <div className="p-2">Protocolo: {selected?.protocol}</div>
          <span className="flex items-center space-x-1 p-2">
            {selected?.photo ? (
              <a
                href={selected?.photo}
                target="_blank"
                rel="noreferrer"
                className="flex items-center"
              >
                <div className="aspect-1 w-12 rounded-full bg-gray-700 ring ring-gray-700 ring-offset-base-100 ring-offset-2 flex flex-col justify-center items-center">
                  <img className="rounded-full" src={selected?.photo} />
                </div>
              </a>
            ) : (
              <div className="aspect-1 w-12 rounded-full bg-gray-700 ring ring-gray-700 ring-offset-base-100 ring-offset-2 flex flex-col justify-center items-center">
                <span className="text-white font-bold text-2xl">
                  {selected?.chatName
                    ? selected?.chatName?.substring(0, 1)
                    : selected?.phone?.substring(0, 1)}
                </span>
              </div>
            )}
            <strong className="text-xl">
              {selected.chatName
                ? `${selected.chatName} - ${selected.phone}`
                : selected.phone}
            </strong>
          </span>

          <ListMessages
            ref={ref}
            height="74vh"
            messages={selected?.messages?.sort((a, b) => {
              return a.createdAt > b.createdAt ? 1 : -1;
            })}
            sending={false}
          />
        </div>
      </SlidingPanel>
    </div>
  );
}
