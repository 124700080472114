import App from './pages/App';
import { AuthProvider } from './hooks/auth.hook';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { read } from './pages/config/config.service';
import { LOCAL_STORAGE_JWT } from './app.settings';

const InitialOrApp = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [_config, setConfig] = useState<Record<string, any>>({});
  const token = localStorage.getItem(LOCAL_STORAGE_JWT);
  useEffect(() => {
    if (token) {
      read().then(setConfig);
    }
  }, [token]);

  return (
    <AuthProvider>
      {_config && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {' '}
            {`Chatbox - ${_config.nickname || _config.name || ''}`}
          </title>

          <style type="text/css">{`
            :root {
              --primary1: ${_config.primary1 || '#fed100'};
              --primary2: ${_config.primary2 || '#e2a700'};
              --primary3: ${_config.primary3 || '#e06a00'};
              --primary4: ${_config.primary4 || '#aa5507'};
              --primary5: ${_config.primary5 || '#8c4504'};
              --primary6: ${_config.primary6 || '#2b1501'};
              --negative: ${_config.negative || '#6B7280'};
              --default-white: ${_config.defaultWhite || '#ffffff'};
            }
          `}</style>
        </Helmet>
      )}
      <App />
    </AuthProvider>
  );
};

export default InitialOrApp;
