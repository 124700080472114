import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Comunication } from '../../app.models';
import Button from '../../components/layout/button';
import { useSocket } from '../../hooks/socket.context';
import { getFormatedDateTime } from '../../utils/datetimeHandler';

interface ModalComunicationProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  comunication: Comunication;
}

export default function ModalComunication(props: ModalComunicationProps) {
  const { toggleComunicationStatus, toggleComunicationArchived } = useSocket();
  function closeModal() {
    props.setIsOpen(false);
  }

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={() => null}
      open={props.isOpen}
    >
      {props.comunication.id && (
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="h3"
              className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
            >
              <span className="text-lg font-bold leading-6 font-display">
                {getFormatedDateTime(props.comunication.createdAt.toString())} -{' '}
                {props.comunication.emitter.name}
              </span>
              <button onClick={closeModal} className="hover:text-gray-400">
                <XIcon className="w-5 h-5" />
              </button>
            </Dialog.Title>
            <div className="flex flex-col font-display py-4 px-6 space-y-2">
              <div className="min-h-[20vh] p-1 rounded-xl bg-slate-50">
                {props.comunication.message}
              </div>
              <div className="flex space-y-1 flex-col md:flex-row md:justify-between md:space-y-0">
                <Button
                  title="Fechar"
                  type="button"
                  onClick={closeModal}
                  colors="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
                />
                <Button
                  title="Marcar Não Lida"
                  type="button"
                  colors="bg-gray-500 text-white hover:bg-gray-400 hover:text-gray-100"
                  onClick={() => {
                    toggleComunicationStatus(props.comunication.id);
                    closeModal();
                  }}
                />
                <Button
                  title="Arquivar"
                  type="button"
                  colors="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                  onClick={() => {
                    toggleComunicationArchived(props.comunication.id);
                    closeModal();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
}
