import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Chat, Message } from '../chat/chat.model';
import { useSocket } from '../../hooks/socket.context';
import {
  CheckCircleIcon,
  ClockIcon,
  DocumentAddIcon,
  ExternalLinkIcon,
  MusicNoteIcon,
  PaperAirplaneIcon,
  PaperClipIcon,
  PhotographIcon,
  StatusOfflineIcon,
  SwitchHorizontalIcon,
} from '@heroicons/react/outline';
import moment from 'moment';
import ListMessages from './listMessages';
import { feed, search } from './chat.service';
import ModalConfirmationFinish from '../../components/modalConfirmation';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { classNames } from '../../utils/functions';
import { Controller, useForm } from 'react-hook-form';
import { list as listPredefined } from '../cad/predefinedMessage/predefinedMessage.service';
import { PredefinedMessage } from '../cad/predefinedMessage/predefinedMessage.model';
import EmojiPicker from '../../components/emojiPicker';
import ModalSelectRepository from '../cad/repository/modalSelectRepository';
import ModalChatTransfer from './modalChatTransfer';
import { transpileText } from '../../app.service';
import PageLoading from '../../components/pageLoading';
import { useAuth } from '../../hooks/auth.hook';
import ROUTES_MAP from '../../app.map.routes';
import { Repository } from '../cad/repository/repository.model';
import ModalAudioRecording from '../../components/modalAudioRecording';
import ModalSendImage from '../../components/SendImage/modalSendImage';
import useMedia from 'use-media';
import SendTemporaryRepository from '../../components/SendTemporaryRepository';
import SlidingPanel from 'react-sliding-side-panel';

interface LocationState {
  id: string | undefined;
}
interface MessageState {
  message: string;
}

const NotChat = (): JSX.Element => {
  return (
    <div className="mt-28 md:mt-0">
      <div className="h-screen flex flex-col justify-center items-center">
        <h1 className="text-3xl text-gray-400 font-bold">
          Chat não disponível!
        </h1>
      </div>
    </div>
  );
};

const AppChat: React.FC = () => {
  const isSmall = useMedia({ maxWidth: 480 });
  const location = useLocation();
  const navigate = useNavigate();
  const {
    chats,
    sendMessage,
    transferChat,
    finishChat,
    sendImage,
    sendDocument,
    sendAudio,
    sendVideo,
    instances,
  } = useSocket();
  const { getJwt } = useAuth();
  const [chat, setChat] = useState<Chat | undefined>();
  const [currentUserId, setCurrentUserId] = useState<string>('');

  const [predefineds, setPredefineds] = useState<PredefinedMessage[]>([]);
  const [seletedPredefined, setSelectedPredefined] = useState<string>();
  const [modalRepositoryIsOpen, setModalRepositoryIsOpen] = useState(false);
  const [modalChatTranferIsOpen, setModalChatTranferIsOpen] = useState(false);
  const [modalConfirmFinishIsOpen, setModalConfirmFinishIsOpen] =
    useState(false);
  const [modalAudioRecordingIsOpen, setModalAudioRecordingIsOpen] =
    useState(false);

  const [modalSendImageIsOpen, setModalSendImageIsOpen] = useState(false);
  const [modalSendTemporaryIsOpen, setModalSendTemporaryIsOpen] =
    useState(false);

  // side panel historic
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [loadingSidePanel, setLoadingSidePanel] = useState(false);
  const sidePanelData = useRef({
    number: '',
    messages: [] as Message[],
  });
  const openSidePanel = () => {
    setShowSidePanel(true);
    setLoadingSidePanel(true);
    search({ phone: chat?.phone })
      .then(chats => {
        sidePanelData.current.number = chat?.phone || 'N/D';
        const messages = chats.reduce((acc: Message[], chat) => {
          return [...acc, ...chat.messages];
        }, []);
        sidePanelData.current.messages = messages;
      })
      .finally(() => {
        setLoadingSidePanel(false);
      });
  };

  const [connected, setConnected] = useState(false);

  useEffect(() => {
    setConnected(false);
    instances.forEach(instance => {
      if (instance.connected) {
        setConnected(true);
      }
    });
  }, [instances]);

  const { control, handleSubmit, getValues, setValue } =
    useForm<MessageState>();

  const getUserName = () => {
    const jwt = getJwt();
    if (jwt) {
      return jwt.name;
    }
    return '';
  };

  const sendRep = (data: Repository) => {
    if (!chat) return;
    const message = {} as Message;
    message.type = data.type;
    message.fromMe = true;
    message.chat = chat?.id;
    switch (data.type) {
      case 'Document':
        message.documentUrl = data.path;
        message.fileName = data.desc;
        message.mimeType = data.mimeType;
        sendDocument(message);
        break;
      case 'Audio':
        message.audioUrl = data.path;
        sendAudio(message);
        break;
      case 'Video':
        message.videoUrl = data.path;
        sendVideo(message);
        break;
      case 'Image':
        message.imageUrl = data.path;
        message.thumbnailUrl = data.path;
        message.caption = data.desc;
        sendImage(message);
        break;
      default:
        return;
    }
  };

  const sendVoz = (data: string) => {
    if (!chat || !data) return;
    const message = {} as Message;
    message.type = 'Audio';
    message.fromMe = true;
    message.chat = chat?.id;
    message.audioUrl = data;
    sendAudio(message);
  };

  const sendImageBase64 = (base64: string) => {
    if (!chat || !base64) return;
    const message = {} as Message;
    message.type = 'Image';
    message.fromMe = true;
    message.chat = chat?.id;
    message.imageUrl = base64;
    sendImage(message);
  };

  const send = (data: MessageState) => {
    if (!chat) return;
    const message = {} as Message;
    message.type = 'Text';
    message.message = `*${getUserName()}* \n ${data.message}`;
    message.fromMe = true;
    message.chat = chat?.id;
    sendMessage(message);
    setValue('message', '');
  };

  const ref = useRef<HTMLDivElement>(null);
  const refMessage = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const id = (location.state as LocationState)?.id;
    const filter = chats.find(chat => chat.id === id);
    setChat(filter);
    feed(ref);
    // setValue('message', '');

    setCurrentUserId(getJwt()?.id || '');

    listPredefined().then(setPredefineds);
  }, [chats, getJwt, location.state, setValue]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addEmoji = (emoji: any) => {
    const text = getValues('message');
    const textLength = text?.length || 0;
    const selectionStart = refMessage.current?.selectionStart || textLength;
    const text1 = text.slice(0, selectionStart);
    const text2 = text.slice(selectionStart, textLength);
    const newText = text1 + emoji.emoji + text2;
    setValue('message', newText);
  };

  const addPredefinedMessage = async (): Promise<void> => {
    const text = getValues('message');
    const predefinedText = predefineds.find((predefined: PredefinedMessage) => {
      return predefined.id === seletedPredefined;
    });
    if (predefinedText) {
      const newText = text
        ? text + '\n' + (await transpileText(predefinedText.message))
        : await transpileText(predefinedText.message);
      setValue('message', newText);
    }
  };

  const transfer = (department: string, user: string | null): void => {
    const currentChat = chat;
    if (!currentChat) return;
    transferChat(getUserName(), currentChat.id, department, user);
    new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
      navigate(ROUTES_MAP.chats);
    });
  };

  const closeChat = async () => {
    const userName = getJwt()?.name;

    if (!chat || !userName) return;
    finishChat(userName, chat.id);

    await new Promise(t => setTimeout(t, 1000));
    navigate(ROUTES_MAP.chats);
  };

  if (chat && !chat.user) {
    return <NotChat />;
  }

  if (chat && chat.user.id !== currentUserId) {
    return <NotChat />;
  }

  if (!chat) {
    return <PageLoading />;
  }

  if (!connected) {
    // if (connected) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <StatusOfflineIcon className="h-60 w-60 text-red-500 animate-pulse" />
      </div>
    );
  }

  if (isSmall) {
    return (
      <div className="mt-28 md:mt-0 p-4">
        <div className="flex items-center space-x-3">
          <span className="">
            {chat.photo ? (
              <a href={chat.photo} target="_blank" rel="noreferrer">
                <div className="aspect-1 w-12 rounded-full bg-gray-700 ring ring-gray-700 ring-offset-base-100 ring-offset-2 flex flex-col justify-center items-center">
                  <img className="rounded-full" src={chat.photo} />
                </div>
              </a>
            ) : (
              <div className="aspect-1 w-12 rounded-full bg-gray-700 ring ring-gray-700 ring-offset-base-100 ring-offset-2 flex flex-col justify-center items-center">
                <span className="text-white font-bold text-2xl">
                  {chat.chatName
                    ? chat.chatName.substring(0, 1)
                    : chat.phone.substring(0, 1)}
                </span>
              </div>
            )}
          </span>
          <div className="flex flex-col text-slate-500">
            <strong className="text-sm">
              {chat.chatName ? `${chat.phone} - ${chat.chatName}` : chat.phone}
            </strong>
            <strong className="text-xs">
              Departamento: {chat?.department?.desc || 'N/D'}
            </strong>
          </div>
          <div className="flex items-center space-x-1">
            <div className="flex flex-col items-center">
              <div className="flex items-center space-x-1 text-slate-500">
                <ClockIcon className="w-6 h-6" />
                <span className="font-bold text-xs">
                  {moment(chat.createdAt).format('HH:mm')}
                </span>
              </div>
              <div className="text-gray-500 text-[10px] text-right">
                {moment(chat.createdAt).format('DD/MM/YYYY')}
              </div>
            </div>
          </div>
        </div>
        <div>
          <ListMessages
            ref={ref}
            height="40vh"
            messages={chat.messages.sort((a, b) => {
              return a.createdAt > b.createdAt ? 1 : -1;
            })}
            sending={false}
          />
        </div>
        <form className="space-y-1" onSubmit={handleSubmit(send)}>
          <div className="text-[10px] text-gray-300">Mensagem de texto</div>
          <div className="flex flex-col">
            <div className="w-full">
              <Controller
                name="message"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <ReactTextareaAutosize
                    {...field}
                    ref={refMessage}
                    minRows={3}
                    maxRows={3}
                    className="form-input px-2 py-2 w-full h-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                  />
                )}
              />
              <button className="underline text-xs" onClick={openSidePanel}>
                Ver histórico de mensagens
              </button>
            </div>
            <div className="flex justify-between">
              <button
                className="bg-green-500 text-white px-2 py-1 rounded-md mx-1 hover:bg-green-400"
                title="Enviar"
                type="submit"
              >
                <PaperAirplaneIcon className="w-4 h-4 rotate-90 mx-auto" />
              </button>
              <button
                className="bg-gray-500 text-white px-3 py-1 rounded-md mx-1 hover:bg-gray-400"
                title="Enviar Documento"
                type="button"
                onClick={() => setModalSendTemporaryIsOpen(true)}
              >
                <DocumentAddIcon className="w-6 h-6 mx-auto" />
              </button>
              <button
                className="bg-primary2 text-white px-2 py-1 rounded-md mx-1 hover:bg-primary1"
                title="Enviar Imagem"
                type="button"
                onClick={() => setModalSendImageIsOpen(true)}
              >
                <PhotographIcon className="w-4 h-4 mx-auto" />
              </button>
              <button
                className="bg-blue-500 text-white px-2 py-1 rounded-md mx-1 hover:bg-blue-400"
                title="Gravar voz"
                type="button"
                onClick={() => setModalAudioRecordingIsOpen(true)}
              >
                <MusicNoteIcon className="w-4 h-4 mx-auto" />
              </button>
              <button
                className="bg-primary2 text-white px-2 py-1 rounded-md mx-1 hover:bg-primary1"
                title="Repositório"
                type="button"
                onClick={() => setModalRepositoryIsOpen(true)}
              >
                <PaperClipIcon className="w-4 h-4 mx-auto" />
              </button>
              <button
                className="bg-primary2 text-white px-2 py-1 rounded-md mx-1 hover:bg-primary1"
                title="Transferir"
                type="button"
                onClick={() => setModalChatTranferIsOpen(true)}
              >
                <SwitchHorizontalIcon className="w-4 h-4 mx-auto" />
              </button>
              <button
                className="bg-red-500 text-white px-2 py-1 rounded-md mx-1 hover:bg-red-400"
                title="Finalizar"
                type="button"
                onClick={() => setModalConfirmFinishIsOpen(true)}
              >
                <CheckCircleIcon className="w-4 h-4 mx-auto" />
              </button>
            </div>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="department"
              className="block text-[10px] font-medium text-gray-700"
            >
              Mensagens Rápidas
            </label>
            <div className="mt-1 relative shadow-sm flex">
              <select
                id="predefined"
                className="form-input px-2 py-1 w-full rounded-tl-md rounded-bl-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder=""
                value={seletedPredefined}
                onChange={e => setSelectedPredefined(e.target.value)}
              >
                <option value="">Selecione ...</option>
                {predefineds.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.desc}
                  </option>
                ))}
              </select>
              <button
                className={classNames(
                  'bg-negative text-white',
                  'px-2 py-1 rounded-tr-md rounded-br-md',
                )}
                onClick={addPredefinedMessage}
              >
                <ExternalLinkIcon className="w-6 h-6" />
              </button>
            </div>
          </div>

          {/* <EmojiPicker
            onEmojiClick={e => addEmoji(e)}
            width="100%"
            heigth="30vh"
          /> */}
        </form>
        <ModalSelectRepository
          isOpen={modalRepositoryIsOpen}
          setIsOpen={setModalRepositoryIsOpen}
          action={sendRep}
        />
        <ModalChatTransfer
          isOpen={modalChatTranferIsOpen}
          setIsOpen={setModalChatTranferIsOpen}
          action={transfer}
          currentChat={chat}
        />
        <ModalConfirmationFinish
          isOpen={modalConfirmFinishIsOpen}
          setIsOpen={setModalConfirmFinishIsOpen}
          title={'Finalizar Atendiemnto'}
          question={'Após finalizar não é possível mais reabrir o atendimento!'}
          answer={closeChat}
        />
        <ModalAudioRecording
          isOpen={modalAudioRecordingIsOpen}
          setIsOpen={setModalAudioRecordingIsOpen}
          action={sendVoz}
        />
        <ModalSendImage
          isOpen={modalSendImageIsOpen}
          setIsOpen={setModalSendImageIsOpen}
          title={'Enviar Image'}
          info={'PNG, JPG'}
          aspect={1.1}
          postAction={sendImageBase64}
        />
        <SendTemporaryRepository
          isOpen={modalSendTemporaryIsOpen}
          setIsOpen={setModalSendTemporaryIsOpen}
          title={'Enviar Documento'}
          info={'TXT, PDF, DOC, XLS, XLSX, PPT, PPTX'}
          postAction={sendRep}
        />
        <SlidingPanel
          type={'right'}
          isOpen={showSidePanel}
          size={isSmall ? 100 : 30}
        >
          <div className="bg-white h-full">
            <div className="p-2 bg-primary1 text-default-white">
              <button onClick={() => setShowSidePanel(false)}>Fechar</button>
            </div>
            <div className="flex justify-between px-2">
              <div>Número:</div>
              <div>{sidePanelData.current.number}</div>
            </div>

            {loadingSidePanel ? (
              <PageLoading height="w-24" width="h-24" />
            ) : (
              <>
                {sidePanelData.current.messages.length > 0 ? (
                  <ListMessages
                    ref={ref}
                    height="82vh"
                    messages={sidePanelData.current.messages?.sort((a, b) => {
                      return a.createdAt > b.createdAt ? 1 : -1;
                    })}
                    sending={false}
                  />
                ) : (
                  <div className="p-2">Nenhuma mensagem</div>
                )}
              </>
            )}
          </div>
        </SlidingPanel>
      </div>
    );
  }

  return (
    <div className="mt-28 md:mt-0 p-4">
      <div className="flex justify-between">
        <div className="flex items-center space-x-3">
          <span className="">
            {chat.photo ? (
              <a href={chat.photo} target="_blank" rel="noreferrer">
                <div className="aspect-1 w-12 rounded-full bg-gray-700 ring ring-gray-700 ring-offset-base-100 ring-offset-2 flex flex-col justify-center items-center">
                  <img className="rounded-full" src={chat.photo} />
                </div>
              </a>
            ) : (
              <div className="aspect-1 w-12 rounded-full bg-gray-700 ring ring-gray-700 ring-offset-base-100 ring-offset-2 flex flex-col justify-center items-center">
                <span className="text-white font-bold text-2xl">
                  {chat.chatName
                    ? chat.chatName.substring(0, 1)
                    : chat.phone.substring(0, 1)}
                </span>
              </div>
            )}
          </span>
          <div className="flex flex-col text-slate-500">
            <strong className="text-xl">
              {chat.chatName ? `${chat.chatName} - ${chat.phone}` : chat.phone}
            </strong>
            <strong className="text-xs">
              Departamento: {chat?.department?.desc || 'N/D'}
            </strong>
          </div>
        </div>
        <div className="flex items-center space-x-1">
          <div className="flex flex-col">
            <div className="flex items-center space-x-1 text-slate-500">
              <ClockIcon className="w-8 h-8" />
              <span className="font-bold text-lg">
                {moment(chat.createdAt).format('HH:mm')}
              </span>
            </div>
            <div className="text-gray-500 text-[10px] text-right">
              {moment(chat.createdAt).format('LL')}
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-4">
        <div className="w-1/3 flex flex-col pr-1">
          <div className="space-y-3">
            <div className="flex flex-col">
              <label
                htmlFor="department"
                className="block text-sm font-medium text-gray-700"
              >
                Mensagens Rápidas
              </label>
              <div className="mt-1 relative shadow-sm flex">
                <select
                  id="predefined"
                  className="form-input px-3 py-2 w-full rounded-tl-md rounded-bl-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Departamento"
                  value={seletedPredefined}
                  onChange={e => setSelectedPredefined(e.target.value)}
                >
                  <option value="">Selecione ...</option>
                  {predefineds.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.desc}
                    </option>
                  ))}
                </select>
                <button
                  className={classNames(
                    'bg-negative text-white',
                    'px-3 py-2 rounded-tr-md rounded-br-md',
                  )}
                  onClick={addPredefinedMessage}
                >
                  <ExternalLinkIcon className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
          <form className="space-y-1" onSubmit={handleSubmit(send)}>
            <div className="text-[10px] text-gray-300">Mensagem de texto</div>
            <div className="flex">
              <div className="w-[80%]">
                <Controller
                  name="message"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <ReactTextareaAutosize
                      {...field}
                      ref={refMessage}
                      minRows={9}
                      maxRows={9}
                      className="form-input px-3 py-2 w-full h-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                  )}
                />
                <button className="underline text-xs" onClick={openSidePanel}>
                  Ver histórico de mensagens
                </button>
              </div>
              <div className="w-[20%] flex flex-col space-y-1">
                <button
                  className="bg-green-500 text-white px-3 py-1 rounded-md mx-1 hover:bg-green-400"
                  title="Enviar Mensagem"
                  type="submit"
                >
                  <PaperAirplaneIcon className="w-6 h-6 rotate-90 mx-auto" />
                </button>
                <button
                  className="bg-gray-500 text-white px-3 py-1 rounded-md mx-1 hover:bg-gray-400"
                  title="Enviar Documento"
                  type="button"
                  onClick={() => setModalSendTemporaryIsOpen(true)}
                >
                  <DocumentAddIcon className="w-6 h-6 mx-auto" />
                </button>
                <button
                  className="bg-primary2 text-white px-3 py-1 rounded-md mx-1 hover:bg-primary1"
                  title="Enviar Imagem"
                  type="button"
                  onClick={() => setModalSendImageIsOpen(true)}
                >
                  <PhotographIcon className="w-6 h-6 mx-auto" />
                </button>
                <button
                  className="bg-blue-500 text-white px-3 py-1 rounded-md mx-1 hover:bg-blue-400"
                  title="Gravar voz"
                  type="button"
                  onClick={() => setModalAudioRecordingIsOpen(true)}
                >
                  <MusicNoteIcon className="w-6 h-6 mx-auto" />
                </button>
                <button
                  className="bg-primary2 text-white px-3 py-1 rounded-md mx-1 hover:bg-primary1"
                  title="Repositório"
                  type="button"
                  onClick={() => setModalRepositoryIsOpen(true)}
                >
                  <PaperClipIcon className="w-6 h-6 mx-auto" />
                </button>
                <button
                  className="bg-primary2 text-white px-3 py-1 rounded-md mx-1 hover:bg-primary1"
                  title="Transferir"
                  type="button"
                  onClick={() => setModalChatTranferIsOpen(true)}
                >
                  <SwitchHorizontalIcon className="w-6 h-6 mx-auto" />
                </button>
                <button
                  className="bg-red-500 text-white px-3 py-1 rounded-md mx-1 hover:bg-red-400"
                  title="Finalizar"
                  type="button"
                  onClick={() => setModalConfirmFinishIsOpen(true)}
                >
                  <CheckCircleIcon className="w-6 h-6 mx-auto" />
                </button>
              </div>
            </div>

            <EmojiPicker
              onEmojiClick={e => addEmoji(e)}
              width="100%"
              heigth="27vh"
            />
          </form>
        </div>
        <div className="w-2/3">
          <ListMessages
            ref={ref}
            height="83vh"
            messages={chat.messages.sort((a, b) => {
              return a.createdAt > b.createdAt ? 1 : -1;
            })}
            sending={false}
          />
        </div>
      </div>
      <ModalSelectRepository
        isOpen={modalRepositoryIsOpen}
        setIsOpen={setModalRepositoryIsOpen}
        action={sendRep}
      />
      <ModalChatTransfer
        isOpen={modalChatTranferIsOpen}
        setIsOpen={setModalChatTranferIsOpen}
        action={transfer}
        currentChat={chat}
      />
      <ModalConfirmationFinish
        isOpen={modalConfirmFinishIsOpen}
        setIsOpen={setModalConfirmFinishIsOpen}
        title={'Finalizar Atendiemnto'}
        question={'Após finalizar não é possível mais reabrir o atendimento!'}
        answer={closeChat}
      />
      <ModalAudioRecording
        isOpen={modalAudioRecordingIsOpen}
        setIsOpen={setModalAudioRecordingIsOpen}
        action={sendVoz}
      />
      <ModalSendImage
        isOpen={modalSendImageIsOpen}
        setIsOpen={setModalSendImageIsOpen}
        title={'Enviar Image'}
        info={'PNG, JPG'}
        aspect={1.1}
        postAction={sendImageBase64}
      />
      <SendTemporaryRepository
        isOpen={modalSendTemporaryIsOpen}
        setIsOpen={setModalSendTemporaryIsOpen}
        title={'Enviar Documento'}
        info={'TXT, PDF, DOC, XLS, XLSX, PPT, PPTX'}
        postAction={sendRep}
      />
      <SlidingPanel
        type={'right'}
        isOpen={showSidePanel}
        size={isSmall ? 100 : 30}
      >
        <div className="bg-white h-full">
          <div className="p-2 bg-primary1 text-default-white">
            <button onClick={() => setShowSidePanel(false)}>Fechar</button>
          </div>
          <div className="flex justify-between px-2">
            <div>Número:</div>
            <div>{sidePanelData.current.number}</div>
          </div>

          {loadingSidePanel ? (
            <PageLoading height="w-24" width="h-24" />
          ) : (
            <>
              {sidePanelData.current.messages.length > 0 ? (
                <ListMessages
                  ref={ref}
                  height="82vh"
                  messages={sidePanelData.current.messages?.sort((a, b) => {
                    return a.createdAt > b.createdAt ? 1 : -1;
                  })}
                  sending={false}
                />
              ) : (
                <div className="p-2">Nenhuma mensagem</div>
              )}
            </>
          )}
        </div>
      </SlidingPanel>
    </div>
  );
};

export default AppChat;
