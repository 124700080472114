import React, { useEffect, useState } from 'react';
import useMedia from 'use-media';
import { getRepositoryType } from '.';
import { classNames } from '../../../utils/functions';
import { Repository } from './repository.model';

type Props = {
  data: Repository[];
  page?: number;
  limit?: number;
  selected: (repository: Repository) => void;
};

const TableRepository: React.FC<Props> = (props: Props) => {
  const isSmall = useMedia({ maxWidth: 480 });
  const [selected, setSelected] = useState<Repository | undefined>();

  useEffect(() => {
    if (selected) {
      props.selected(selected);
    }
  }, [props, selected]);

  return (
    <div className="">
      <table className="table-auto w-full">
        <thead className="sticky top-0 text-xs font-semibold uppercase text-gray-400 bg-gray-50">
          <tr className="">
            <th className="p-2 whitespace-nowrap">
              <div className="font-semibold text-left"></div>
            </th>
            <th className="p-2 whitespace-nowrap">
              <div className="font-semibold text-left">Descrição</div>
            </th>
            {!isSmall && (
              <th className="p-2 whitespace-nowrap">
                <div className="font-semibold text-left">Tipo</div>
              </th>
            )}
            {!isSmall && (
              <th className="p-2 whitespace-nowrap">
                <div className="font-semibold text-left">Usúario</div>
              </th>
            )}
          </tr>
        </thead>
        <tbody className="text-sm divide-y divide-gray-100">
          {props.data.map(data => (
            <tr
              key={data.id}
              className={classNames(
                selected?.id === data.id ? 'bg-primary2' : 'hover:bg-gray-200',
                'cursor-pointer',
              )}
              onClick={() => setSelected(data)}
            >
              <td className="p-2 whitespace-nowrap">
                <div className="text-left">
                  {getRepositoryType(data.type, data.path).icon}
                </div>
              </td>
              <td className="p-2 whitespace-nowrap">
                <div className="text-left">{data.desc}</div>
              </td>
              {!isSmall && (
                <td className="p-2 whitespace-nowrap">
                  <div className="text-left">
                    {getRepositoryType(data.type, data.path).type}
                  </div>
                </td>
              )}
              {!isSmall && (
                <td className="p-2 whitespace-nowrap">
                  <div className="text-left">{data.user?.user || 'N/D'}</div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableRepository;
