import { Tab } from '@headlessui/react';
import React, { useEffect } from 'react';
import useMedia from 'use-media';
import { classNames } from '../../utils/functions';
import { AutoMessages } from './autoMessages';
import { CompanyData } from './companyData';
import { readApiVersion, readAppVersion } from './config.service';
import { ConfigOpeningHours } from './configOpeningHours';
import { Parear } from './parear';
// import { ThemeData } from './themeData';

const AppConfig: React.FC = () => {
  const isSmall = useMedia({ maxWidth: 480 });
  const [apiVersion, setApiVersion] = React.useState(
    {} as Record<string, string>,
  );
  const [appVersion, setAppVersion] = React.useState(
    {} as Record<string, string>,
  );

  useEffect(() => {
    readApiVersion().then(setApiVersion);
    readAppVersion().then(setAppVersion);
  }, []);

  return (
    <>
      <div className="mt-28 md:mt-0 px-2">
        <Tab.Group>
          <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl">
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium text-negative rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-white hover:bg-white/[0.12] hover:text-white',
                )
              }
            >
              {isSmall ? 'EMP' : 'Empresa'}
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium text-negative rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-white hover:bg-white/[0.12] hover:text-white',
                )
              }
            >
              {isSmall ? 'MEN' : 'Mensagens Automáticas'}
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium text-negative rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-white hover:bg-white/[0.12] hover:text-white',
                )
              }
            >
              {isSmall ? 'HOR' : 'Horários de Funcionamento'}
            </Tab>
            {/* <Tab
            className={({ selected }) =>
              classNames(
                'w-full py-2.5 text-sm leading-5 font-medium text-negative rounded-lg',
                'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
                selected
                  ? 'bg-white shadow'
                  : 'text-white hover:bg-white/[0.12] hover:text-white',
              )
            }
          >
            Tema/Cor
          </Tab> */}
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium text-negative rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-white hover:bg-white/[0.12] hover:text-white',
                )
              }
            >
              {isSmall ? 'PAR' : 'Pareamento'}
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            {/************ dados da empresa *************/}
            <Tab.Panel
              className={classNames(
                'bg-gray-50 shadow drop-shadow rounded-xl p-3',
                'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
                'flex flex-col space-y-2',
                'md:h-[87vh] overflow-hidden overflow-y-auto',
              )}
            >
              <CompanyData />
            </Tab.Panel>

            {/************ mensagens automáticas *************/}
            <Tab.Panel
              className={classNames(
                'bg-gray-50 shadow drop-shadow rounded-xl p-3',
                'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
                'md:h-[87vh] overflow-hidden overflow-y-auto',
              )}
            >
              <AutoMessages />
            </Tab.Panel>

            {/************ horario de funcionamentos *************/}
            <Tab.Panel
              className={classNames(
                'bg-gray-50 shadow drop-shadow rounded-xl p-3',
                'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
                'flex flex-col space-y-2',
                'h-[87vh] overflow-hidden overflow-y-auto',
              )}
            >
              <ConfigOpeningHours />
            </Tab.Panel>

            {/************ theme *************/}
            {/* <Tab.Panel
            className={classNames(
              'bg-gray-50 shadow drop-shadow rounded-xl p-3',
              'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
              'flex flex-col space-y-2',
              'h-[87vh] overflow-hidden overflow-y-auto',
            )}
          >
            <ThemeData />
          </Tab.Panel> */}

            {/************ pareamento *************/}
            <Tab.Panel
              className={classNames(
                'bg-gray-50 shadow drop-shadow rounded-xl p-3',
                'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
                'flex flex-col space-y-2',
                'h-[87vh] overflow-hidden overflow-y-auto',
              )}
            >
              <Parear />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      <p className="flex space-x-2 text-sm px-2 text-gray-400">
        {`Api Versão: ${apiVersion.version} - App Versão: ${appVersion.version}`}
      </p>
    </>
  );
};

export default AppConfig;
