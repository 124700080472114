import { InformationCircleIcon } from '@heroicons/react/outline';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { showErrors } from '../utils/functions';
import { getRating, setRating } from './chat/chat.service';
// import logoFULL from '../assets/svg/logo-full-original.svg';

const Rating: React.FC = () => {
  const params = useParams<{ chatId: string }>();
  const [disabled, setDisabled] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [chat, setChat] = useState<Record<string, any> | undefined>();
  const [satisfaction, setSatisfaction] = useState(0);
  const [obs, setObs] = useState('');
  const [info, setInfo] = useState(['', '']);

  const submit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!chat) return;
    setInfo(['', '']);
    if (satisfaction === 0) {
      setInfo(['Avalie o nível de satisfação', '']);
      return;
    }
    if (satisfaction === 1 && obs.length === 0) {
      setInfo(['', 'Justifique a sua avaliação']);
      return;
    }
    try {
      setDisabled(true);
      chat.evaluated = true;
      chat.satisfaction = satisfaction;
      if (obs) chat.obs = obs;
      await setRating(chat);
      setDisabled(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showErrors(error);
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (params.chatId) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getRating(params.chatId).then((data: Record<string, any>) => {
        setChat(data);
      });
    }
  }, [params.chatId]);

  if (!chat) return <span>nulo</span>;

  if (chat.evaluated)
    return (
      <div className="p-2">
        <div className="mx-auto max-w-md flex flex-col">
          <h1 className="text-center text-2xl font-bold my-8">
            Avaliação de Atendimento
          </h1>
          <h1 className="text-center text-red-400 text-2xl font-bold my-8">
            Esse ATENDIMENTO foi avaliado!
          </h1>
        </div>
      </div>
    );

  if (!chat.evaluated)
    return (
      <div className="h-full bg-transparency-header bg-no-repeat font-display p-2">
        <div className="mx-auto max-w-md flex flex-col">
          <h1 className="text-center text-2xl font-bold my-8">
            Avaliação de Atendimento
          </h1>
          <div className="flex items-center justify-between my-3">
            <strong>Protocolo</strong>
            <span>{chat?.protocol}</span>
          </div>
          <div className="flex flex-col my-3">
            <strong>Qual o nível de satisfação do seu atendimento?</strong>
            <div className={`flex space-x-2`}>
              <label
                htmlFor="rg3"
                className={`flex flex-col items-center justify-center cursor-pointer p-2 ${
                  satisfaction === 3 ? 'bg-green-200' : ''
                }`}
              >
                <span className={`text-5xl md:text-7xl my-3`}> 😁 </span>
                <strong className="text-xs text-center md:text-lg h-12">
                  Muito Satisfeito
                </strong>
                <input
                  className="my-3 hidden"
                  type="radio"
                  id="rg3"
                  name="rg"
                  value="3"
                  onChange={e => setSatisfaction(parseInt(e.target.value))}
                />
              </label>
              <label
                htmlFor="rg2"
                className={`flex flex-col items-center justify-center cursor-pointer p-2 ${
                  satisfaction === 2 ? 'bg-yellow-200' : ''
                }`}
              >
                <span className={`text-5xl md:text-7xl my-3`}> 🤨 </span>
                <strong className="text-xs text-center md:text-lg h-12">
                  Satisfeito
                </strong>
                <input
                  className="my-3 hidden"
                  type="radio"
                  id="rg2"
                  name="rg"
                  value="2"
                  onChange={e => setSatisfaction(parseInt(e.target.value))}
                />
              </label>
              <label
                htmlFor="rg1"
                className={`flex flex-col items-center justify-center cursor-pointer p-2 ${
                  satisfaction === 1 ? 'bg-red-200' : ''
                }`}
              >
                <span className={`text-5xl md:text-7xl my-3`}> 😡 </span>
                <strong className="text-xs text-center md:text-lg h-12">
                  Nada Satisfeito
                </strong>
                <input
                  className="my-3 hidden"
                  type="radio"
                  id="rg1"
                  name="rg"
                  value="1"
                  onChange={e => setSatisfaction(parseInt(e.target.value))}
                />
              </label>
            </div>
          </div>
          {info[0] && (
            <div className="bg-yellow-100 text-yellow-900 flex space-x-8 px-4 py-1">
              <InformationCircleIcon className="h-6 w-6" />
              {info[0]}
            </div>
          )}
          {satisfaction === 1 && (
            <div className="flex flex-col my-3">
              <strong>Qual o motivo?</strong>
              <textarea
                className="bg-red-200 rounded-box p-2"
                value={obs}
                rows={6}
                onChange={e => setObs(e.target.value)}
              />
              {info[1] && (
                <div className="bg-yellow-100 text-yellow-900 flex space-x-8 px-4 py-1 my-3">
                  <InformationCircleIcon className="h-6 w-6 mr-6" />
                  {info[1]}
                </div>
              )}
            </div>
          )}

          <div className="my-3">
            <button
              className="w-full rounded-box bg-green-500 hover:bg-green-400 text-green-900 hover:text-green-700 font-bold py-1 disabled:bg-gray-600 disabled:text-white"
              onClick={submit}
              disabled={disabled}
            >
              Enviar Avaliação
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <div className="p-2">
      <div className="mx-auto max-w-md flex flex-col">
        <h1 className="text-center text-2xl font-bold my-8">
          Avaliação de Atendimento
        </h1>
        <h1 className="text-center text-red-400 text-2xl font-bold my-8">
          Atendimento não localizado
        </h1>
      </div>
    </div>
  );
};

export default Rating;
