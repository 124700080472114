import { Dialog } from '@headlessui/react';
import {
  PlayIcon,
  StopIcon,
  ThumbDownIcon,
  ThumbUpIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import Button from './layout/button';
import { ReactMic } from '@cleandersonlobo/react-mic';
import { blobToBase64 } from '../utils/functions';

interface ModalAudioRecordingProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  action: (data: string) => void;
}

export default function ModalAudioRecording(props: ModalAudioRecordingProps) {
  //record
  const [recording, setRecording] = useState(false);
  const startRecording = () => {
    setRecording(true);
  };
  const stopRecording = () => {
    setRecording(false);
  };

  const [audioBase64, setAudioBase64] = useState<string>();
  const [audioBase64Sending, setAudioBase64Sending] = useState<string>();

  useEffect(() => {
    setAudioBase64(undefined);
    setRecording(false);
    return () => {
      setAudioBase64(undefined);
      setRecording(false);
    };
  }, []);

  function closeModal() {
    props.setIsOpen(false);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setAudio = (data: any) => {
    const blob = data.blob;
    blobToBase64(blob).then(base64 => {
      if (!base64) return;
      // const _base64 = (base64 as string).replace(
      //   /^data:audio\/webm;codecs=opus;base64,/,
      //   'data:audio/mpeg;codecs=opus;base64,',
      // );
      // const _base64Sending = (base64 as string).replace(
      //   /^data:audio\/webm;codecs=opus;base64,/,
      //   ',',
      // );
      setAudioBase64(String(base64));
      setAudioBase64Sending(String(base64));
    });
  };

  const confirm = async () => {
    if (audioBase64Sending) {
      // const data = await sendAudioBase64(audioBase64Sending);
      props.action(audioBase64Sending);
      closeModal();
    }
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={closeModal}
      open={props.isOpen}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
          >
            <span className="text-lg font-bold leading-6 font-display">
              Gravar voz
            </span>
            <button onClick={closeModal} className="hover:text-gray-400">
              <XIcon className="w-5 h-5" />
            </button>
          </Dialog.Title>
          <div className="flex flex-col font-display py-4 px-6 space-y-5">
            <div className="flex justify-between items-center">
              {!recording && (
                <button
                  className="bg-green-500 text-white px-3 py-1 rounded-md mx-1 hover:bg-green-400"
                  onClick={startRecording}
                  type="button"
                >
                  <PlayIcon className="w-9 h-9" />
                </button>
              )}
              {recording && (
                <button
                  className="bg-red-500 text-white px-3 py-1 rounded-md mx-1 hover:bg-red-400"
                  onClick={stopRecording}
                  type="button"
                >
                  <StopIcon className="w-9 h-9" />
                </button>
              )}
              {/* {audio && <audio controls src={audio.blobURL} className="h-12" />} */}
              {audioBase64 && (
                <audio controls src={audioBase64} className="h-12" />
              )}
            </div>
            <ReactMic
              record={recording}
              className="w-full"
              onStop={setAudio}
              strokeColor="#000000"
              backgroundColor="#bdbdbd"
              mimeType="audio/mp3"
            />
            <div className="flex justify-between">
              <Button
                title="Cancelar"
                type="button"
                icon={<ThumbDownIcon className="w-4 h-4" />}
                onClick={closeModal}
                colors="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
              />
              <Button
                title="Enviar Aúdio"
                type="button"
                icon={<ThumbUpIcon className="w-4 h-4" />}
                onClick={confirm}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
