import React from 'react';
import { Location, useNavigate } from 'react-router-dom';
import { getRepositoryType } from '.';
import ROUTES_MAP from '../../../app.map.routes';
import { classNames } from '../../../utils/functions';
import { Repository } from './repository.model';

type Props = {
  data: Repository[];
  location: Location;
  page?: number;
  limit?: number;
};

const ListRepository: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();

  return (
    <div className="my-3">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-7 gap-2">
        {props.data.map(data => (
          <button
            key={data.id}
            onClick={() =>
              navigate(ROUTES_MAP.repositoryEdit, {
                state: {
                  id: data.id,
                  params: { page: props.page, limit: props.limit },
                },
              })
            }
            className={classNames(
              'w-full rounded-xl',
              'ring-1 ring-negative hover:ring-2 focus:ring-2',
              'p-1',
              'group',
              'flex flex-col space-y-1 justify-between',
            )}
          >
            {getRepositoryType(data.type, data.path, '130').icon}
            <div className="w-full group-hover:font-bold text-negative text-xs line-clamp-2 text-center">
              {data.desc}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ListRepository;
