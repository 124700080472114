/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import CropedImage from './index';

interface ModalSendImageProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  info?: string;
  aspect: number;
  postAction: (data: any) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function ModalSendImage(props: ModalSendImageProps) {
  function closeModal() {
    props.setIsOpen(false);
  }

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={closeModal}
      open={props.isOpen}
    >
      <div className="min-h-screen px-4 text-center">
        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
          >
            <span className="text-lg font-bold leading-6 font-display">
              {props.title}
            </span>
            <button onClick={closeModal} className="hover:text-gray-400">
              <XIcon className="w-5 h-5" />
            </button>
          </Dialog.Title>
          <div className="flex flex-col font-display py-4 px-6">
            <div className="mt-1 flex flex-col">
              <CropedImage
                aspect={props.aspect}
                info={props.info}
                postSendAction={data => {
                  props.postAction(data);
                  closeModal();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
