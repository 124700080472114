/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { ApiResponse } from '../../../app.models';

import api from '../../../axios';
import { User } from './user.model';

export async function list(): Promise<User[]> {
  try {
    const url = '/users/all';
    const { data } = await api.get<ApiResponse<User[]>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function findById(id: string): Promise<User | null> {
  try {
    const url = '/users/' + id;
    const { data } = await api.get<ApiResponse<User>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function insert(user: User): Promise<User> {
  try {
    delete user.id;
    const url = '/users';
    const { data } = await api.post<ApiResponse<User>>(url, user);
    toast.success('Usuário cadastrado com sucesso!');
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function update(user: User): Promise<User> {
  try {
    delete user.password;
    const url = '/users';
    const { data } = await api.put<User>(url, user);
    toast.success('Usuário atualizado com sucesso!');
    return data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function remove(userId: string): Promise<boolean> {
  try {
    const url = '/users/' + userId;
    const { data } = await api.delete<any>(url);
    if (data.affected > 0) {
      toast.success('Usuário removido com sucesso!');
      return true;
    }
    return false;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

function showErrors(errors: string | string[]): void {
  if (Array.isArray(errors)) {
    errors.map((error: string) => toast.error(error));
  } else {
    toast.error(errors);
  }
}
