/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog } from '@headlessui/react';
import { ThumbDownIcon, ThumbUpIcon, XIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { insert } from '../../pages/cad/repository/repository.service';
import Button from '../layout/button';

interface ModalSendImageProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  info?: string;
  postAction: (data: any) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function SendTemporaryRepository(props: ModalSendImageProps) {
  function closeModal() {
    setFile(null);
    props.setIsOpen(false);
  }

  const [file, setFile] = useState<File | null>(null);

  const confirm = async () => {
    if (!file) {
      toast.warn('Selecione um arquivo');
      return;
    }

    const formData = new FormData();
    formData.append('desc', file.name);
    formData.append('type', 'Document');
    formData.append('file', file);
    formData.append('expiration', '60');
    const rep = await insert(formData);
    props.postAction(rep);
    closeModal();
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={closeModal}
      open={props.isOpen}
    >
      <div className="min-h-screen px-4 text-center">
        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
          >
            <span className="text-lg font-bold leading-6 font-display">
              {props.title}
            </span>
            <button onClick={closeModal} className="hover:text-gray-400">
              <XIcon className="w-5 h-5" />
            </button>
          </Dialog.Title>
          <div className="p-4">
            <label className="block text-sm font-medium text-gray-700">
              Dcumento Temporário
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                {!file && (
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                <div className="flex flex-col md:flex-row text-sm text-gray-600">
                  {file ? (
                    <>
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span>{file.name}</span>
                      </label>
                    </>
                  ) : (
                    <>
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span>Clique aqui</span>
                        <input
                          id="file-upload"
                          type="file"
                          multiple={false}
                          onChange={e =>
                            e.target.files && setFile(e.target.files[0])
                          }
                          className="sr-only"
                        />
                      </label>
                      <p className="pl-1">ou arraste e solte</p>
                    </>
                  )}
                </div>
                <p className="text-xs text-gray-500">{props.info}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-between px-4 py-2">
            <Button
              title="Cancelar"
              type="button"
              icon={<ThumbDownIcon className="w-4 h-4" />}
              onClick={closeModal}
              colors="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
            />
            <Button
              title="Enviar documento"
              type="button"
              icon={<ThumbUpIcon className="w-4 h-4" />}
              onClick={confirm}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}
