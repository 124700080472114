import {
  DotsHorizontalIcon,
  EyeIcon,
  EyeOffIcon,
} from '@heroicons/react/outline';
import moment from 'moment';
import React from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { Message } from '../chat/chat.model';
import { classNames } from '../../utils/functions';

interface ListMessagesProps {
  messages: Message[];
  sending: boolean;
  height: string;
}

const getMessageStatus = (status: string) => {
  switch (status) {
    case 'SENDER':
      return <EyeOffIcon className="w-6 h-6 text-gray-400" />;
    case 'RECEIVED':
      return <EyeOffIcon className="w-6 h-6 text-yellow-400" />;
    case 'READ':
      return <EyeIcon className="w-6 h-6 text-green-400" />;
  }
};

// eslint-disable-next-line react/display-name
const ListMessages = React.forwardRef<HTMLDivElement, ListMessagesProps>(
  (props: ListMessagesProps, ref) => {
    if (!ref) return null;
    return (
      <div
        ref={ref}
        style={{ height: props.height }}
        className={classNames(
          `overflow-hidden overflow-y-auto`,
          'shadow-sm p-2 m-1 ring-gray-800 rounded-md bg-gray-50',
        )}
      >
        {props.messages.map((message: Message) => {
          if (message.type === 'Text') {
            return (
              <div
                key={message.id}
                className={`flex flex-col ${
                  message.fromMe ? 'items-end' : 'items-start'
                }`}
              >
                <div className="flex justify-end text-xs">
                  {message.fromMe && getMessageStatus(message.status)}{' '}
                  {moment(message.createdAt).format('DD/MM/YYYY HH:mm')}
                </div>
                {message?.message && (
                  <ReactTextareaAutosize
                    value={message.message}
                    style={{ resize: 'none' }}
                    readOnly={true}
                    className={`grow-0 my-2 max-w-sm px-2 py-2 overflow-hidden w-2/3 shadow-lg border-2 border-gray-300 border-solid ${
                      message.fromMe
                        ? 'text-green-900 bg-green-100 rounded-tl-2xl rounded-tr-2xl rounded-br-2xl'
                        : 'text-blue-900 bg-blue-100 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl'
                    }`}
                  />
                )}
              </div>
            );
          }

          if (message.type === 'Audio') {
            return (
              <div
                key={message.id}
                className={`flex flex-col ${
                  message.fromMe ? 'items-end' : 'items-start'
                }`}
              >
                <div className="flex justify-end text-xs">
                  {message.fromMe && getMessageStatus(message.status)}{' '}
                  {moment(message.createdAt).format('DD/MM/YYYY HH:mm')}
                </div>
                <span
                  className={`grow-0 my-2 max-w-xs px-2 py-2 rounded-full ${
                    message.fromMe
                      ? 'text-green-900 bg-green-100'
                      : 'text-blue-900 bg-blue-100'
                  }`}
                >
                  <audio controls>
                    <source src={message?.audioUrl} type={message?.mimeType} />
                  </audio>
                </span>
              </div>
            );
          }

          if (message.type === 'Video') {
            return (
              <div
                key={message.id}
                className={`flex flex-col ${
                  message.fromMe ? 'items-end' : 'items-start'
                }`}
              >
                <div className="flex justify-end text-xs">
                  {message.fromMe && getMessageStatus(message.status)}{' '}
                  {moment(message.createdAt).format('DD/MM/YYYY HH:mm')}
                </div>
                <span
                  className={`grow-0 my-2 max-w-xs px-2 py-2 rounded-full ${
                    message.fromMe
                      ? 'text-green-900 bg-green-100'
                      : 'text-blue-900 bg-blue-100'
                  }`}
                >
                  <video controls>
                    <source src={message?.videoUrl} type={message?.mimeType} />
                  </video>
                </span>
              </div>
            );
          }

          if (message.type === 'Image') {
            return (
              <div
                key={message.id}
                className={`flex flex-col ${
                  message.fromMe ? 'items-end' : 'items-start'
                }`}
              >
                <div className="flex justify-end text-xs">
                  {message.fromMe && getMessageStatus(message.status)}{' '}
                  {moment(message.createdAt).format('DD/MM/YYYY HH:mm')}
                </div>
                <span
                  className={`grow-0 my-2 max-w-xs px-2 py-2 ${
                    message.fromMe
                      ? 'text-green-900 bg-green-100'
                      : 'text-blue-900 bg-blue-100'
                  }`}
                >
                  <div className="flex flex-col">
                    <a
                      href={message?.imageUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="w-32"
                        src={message?.imageUrl}
                        alt="not found"
                      />
                    </a>
                    {message?.caption && (
                      <div className="w-32">{message.caption}</div>
                    )}
                  </div>
                </span>
              </div>
            );
          }

          if (message.type === 'Document' && message?.documentUrl) {
            return (
              <div
                key={message.id}
                className={`flex flex-col ${
                  message.fromMe ? 'items-end' : 'items-start'
                }`}
              >
                <div className="flex justify-end text-xs">
                  {message.fromMe && getMessageStatus(message.status)}{' '}
                  {moment(message.createdAt).format('DD/MM/YYYY HH:mm')}
                </div>
                <span
                  className={`grow-0 my-2 max-w-xs px-2 py-2 ${
                    message.fromMe
                      ? 'text-green-900 bg-green-100'
                      : 'text-blue-900 bg-blue-100'
                  }`}
                >
                  <div className="flex flex-col">
                    <span className="font-bold">Documento:</span>
                    <a
                      className="hover:underline"
                      href={message.documentUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {message.title && `${message.title} - `}{' '}
                      {message.fileName}
                    </a>
                  </div>
                </span>
              </div>
            );
          }

          return null;
        })}
        {props.sending && (
          <div className={`flex flex-col items-end`}>
            <span
              className={`grow-0 my-2 max-w-xs px-2 py-2 rounded-full text-green-900 bg-green-100`}
            >
              <DotsHorizontalIcon className="w-8 h-8 animate-pulse" />
            </span>
          </div>
        )}
      </div>
    );
  },
);

export default ListMessages;
