import { StatusOfflineIcon, StatusOnlineIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useSocket } from '../../hooks/socket.context';

export default function MenuLeftPair() {
  const { instances } = useSocket();

  const [connected, setConnected] = useState(false);

  useEffect(() => {
    setConnected(false);
    instances.forEach(instance => {
      if (instance.connected) {
        setConnected(true);
      }
    });
  }, [instances]);

  return (
    <div className="px-2">
      <div className="flex justify-center">
        {!connected && (
          <StatusOfflineIcon className="h-12 w-12 text-red-500 animate-pulse" />
        )}
      </div>
    </div>
  );
}
