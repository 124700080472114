/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { ApiResponse } from './app.models';
import { LOCAL_STORAGE_PREFIX } from './app.settings';

import api from './axios';
import { User } from './pages/cad/user/user.model';
import { showErrors } from './utils/functions';

export async function updateProfile(data: User): Promise<User> {
  try {
    const response = await api.put<ApiResponse<User>>('/users', data);
    toast.success('Perfil atualizado com sucesso!');

    return response.data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error);
    }
    throw new Error(error);
  }
}
export async function updatePassword(data: {
  newPassword: string;
  oldPassword: string;
}): Promise<any> {
  try {
    const response = await api.post<ApiResponse<any>>(
      '/auth/update-password',
      data,
    );
    if (response.data.data.message) {
      toast.info(response.data.data.message);
      return;
    }
    toast.success('Senha atualizada com sucesso!');
    return response.data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error);
    }
    throw new Error(error);
  }
}

export async function readTags(): Promise<Record<string, string>[]> {
  try {
    const url = '/config/tags';
    const { data } = await api.get<ApiResponse<Record<string, string>[]>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function transpileText(text: string): Promise<string> {
  try {
    const url = '/config/tags-transpile';
    const { data } = await api.post<ApiResponse<string>>(url, { text });
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export function setFormLocalStorage(
  formIdentification: string,
  value: string,
): void {
  localStorage.setItem(LOCAL_STORAGE_PREFIX + formIdentification, value);
}

export function getFormLocalStorage(formIdentification: string): string | null {
  return localStorage.getItem(LOCAL_STORAGE_PREFIX + formIdentification);
}
