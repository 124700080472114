/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import api from '../../axios';
import { showErrors } from '../../utils/functions';

// upload *****************
const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return <File>theBlob;
};

export async function cropedUpload(
  url: string,
  image: Blob,
  desc = 'upload',
  onUploadProgress: (progressEvent: any) => void,
  afterUpload: () => void,
): Promise<any> {
  const formData = new FormData();
  const imgUp = blobToFile(image, 'file');
  formData.append('file', imgUp);
  desc && formData.append('desc', desc);
  try {
    await api.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
    toast.success('Arquivo enviado com sucesso!');
    afterUpload();
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
  }
}
