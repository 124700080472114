import {
  MailIcon,
  MailOpenIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/outline';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Comunication } from '../../app.models';
import { updateProfile } from '../../app.service';
import CropedImage from '../../components/CropedImage';
import Button from '../../components/layout/button';
import LazyLoadingImage from '../../components/lazyLadingImage';
import { useAuth } from '../../hooks/auth.hook';
import { useSocket } from '../../hooks/socket.context';
import { getFormatedDateTime } from '../../utils/datetimeHandler';
import { classNames } from '../../utils/functions';
import { Department } from '../cad/department/department.model';
import { User } from '../cad/user/user.model';
import ModalComunication from './modalComunication';
import ModalComunicationArchived from './modalComunicationArchived';
import ModalComunicationSend from './modalComunicationSend';
import ModalUpdatePassword from './modalUpdatePassword';

const MyProfile: React.FC = () => {
  const { myProfile, signOut, getJwt } = useAuth();
  const { comunications, toggleComunicationStatus } = useSocket();

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useForm<User | any>();

  const [departments, setDepartments] = useState<Department[]>([]);
  const [user, setUser] = useState<User>();
  const [modalUpdatePasswordIsOpen, setModalUpdatePasswordIsOpen] =
    useState<boolean>(false);

  const [loadingOpenModal, setLoadingOpenModal] = useState({
    id: '',
    loading: false,
  });
  const [showModalComunication, setShowModalComunication] =
    useState<boolean>(false);
  const [showModalComunicationSend, setShowModalComunicationSend] =
    useState<boolean>(false);
  const [showModalComunicationArchived, setShowModalComunicationArchived] =
    useState<boolean>(false);
  const modalComucationData = useRef({} as Comunication);

  useEffect(() => {
    myProfile().then(profile => {
      setUser(profile);
      const entries = Object.entries(profile);
      entries.forEach(([key, value]) => {
        if (!key) return;
        switch (key) {
          case 'departments':
            setDepartments(value);
            break;

          default:
            setValue(key, value);
        }
      });
    });
  }, [myProfile, setValue]);

  const submit = (data: unknown) => {
    const user = data as User;
    console.log('🚀 ~ file: index.tsx ~ line 50 ~ submit ~ user', user);
    updateProfile(user);
  };

  const openComunication = (comunication: Comunication) => {
    setLoadingOpenModal({
      id: comunication.id,
      loading: true,
    });
    if (comunication.status === 'closed')
      toggleComunicationStatus(comunication.id);
    new Promise(t => setTimeout(t, 1000)).then(() => {
      modalComucationData.current = comunication;
      setShowModalComunication(true);
      setLoadingOpenModal({
        id: comunication.id,
        loading: false,
      });
    });
  };

  const openComunicationSend = () => {
    setShowModalComunicationSend(true);
  };

  return (
    <div className="mt-28 md:mt-0 px-4">
      <div className="flex justify-between py-1">
        <div className="text-lg font-bold px-2">Meu Perfil</div>
        <Button title="Sair do Sistema" type="button" onClick={signOut} />
      </div>
      <div className={`flex flex-col md:flex-row justify-between`}>
        {/* minha imagem */}
        <div className={`md:w-1/3 p-3 relative`}>
          <h1 className="text-lg font-bold">Minha Imagem</h1>
          <div className="avatar mt-5 flex flex-col">
            {user && (
              <LazyLoadingImage
                alt="avatar"
                src={`${process.env.REACT_APP_API_URL + user?.avatar}`}
                height={150}
                width={150}
                classNames={['w-full flex justify-center']}
              />
            )}
            <CropedImage
              pathUrl={`/users/upload/${getValues('id')}`}
              aspect={1}
              containerHeight={150}
            />
          </div>
          <h1 className="text-lg font-bold">Meus Departamentos</h1>
          <div className="flex flex-col">
            {departments.map(dep => (
              <div
                key={dep.id}
                className="flex justify-between my-1 border-b-[1px] borderr-negative border-solid py-1"
              >
                <div className="font-medium text-gray-800">{dep.desc}</div>
              </div>
            ))}
          </div>
        </div>
        {/* meus dados */}
        <div className={`md:w-1/3 p-3`}>
          <h1 className="text-lg font-bold">Meus Dados</h1>
          <form onSubmit={handleSubmit(submit)} className="mb-3 w-full">
            <input type="hidden" {...register('id')} />
            <input type="hidden" {...register('role')} />
            <div className="flex flex-col max-w-xl">
              <label className="block text-sm font-medium text-gray-700">
                <span className="">Nome</span>
              </label>
              <input
                type="text"
                placeholder="Nome Completo"
                className="px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                {...register('name', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.name && 'Campo requerido'}
              </span>
            </div>
            <div className="flex flex-col max-w-xl">
              <label className="block text-sm font-medium text-gray-700">
                <span className="">Usuário</span>
              </label>
              <input
                type="text"
                readOnly={true}
                placeholder="Nome para o login"
                className="px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                {...register('user', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.user && 'Campo requerido'}
              </span>
            </div>
            <div className="flex flex-col max-w-xl">
              <label className="block text-sm font-medium text-gray-700">
                <span className="">Email</span>
              </label>
              <input
                type="text"
                placeholder="Email principal"
                className="px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                {...register('email', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.email && 'Campo requerido'}
              </span>
            </div>
            <div className="flex flex-col max-w-xl">
              <label className="block text-sm font-medium text-gray-700">
                <span className="">CEL</span>
              </label>
              <input
                type="text"
                placeholder="Celular apenas números"
                className="px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                {...register('cel', { required: true })}
              />
              <span className="text-xs text-red-500">
                {errors.cel && 'Campo requerido'}
              </span>
            </div>

            <div className="flex justify-between mt-3 max-w-xl">
              <Button
                title="Alterar Senha"
                type="button"
                onClick={() => setModalUpdatePasswordIsOpen(true)}
              />
              <Button title="Salvar" type="submit" />
            </div>
          </form>
        </div>
        {/* meus recados */}
        <div className={`md:w-1/3 p-3`}>
          <h1 className="flex justify-between">
            <span className="text-lg font-bold">Meus Recados</span>
            <Button
              title="Enviar Recado"
              icon={<PaperAirplaneIcon className="w-6" />}
              colors={classNames('bg-green-700 text-green-100')}
              onClick={openComunicationSend}
            />
          </h1>
          <div className="flex justify-start">
            <button
              className="underline text-gray-500 hover:text-gray-900"
              onClick={() => setShowModalComunicationArchived(true)}
            >
              Recados Arquivados
            </button>
          </div>
          <div className="flex flex-col md:h-[78vh] overflow-hidden overflow-y-auto">
            {comunications
              .filter(c => c.receiver.id === getJwt()?.id)
              .map(com => (
                <div
                  key={com.id}
                  className="flex justify-between my-1 border-b-[1px] borderr-negative border-solid py-1"
                >
                  <div className="flex items-center space-x-1">
                    <div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                      {com.emitter.avatar ? (
                        <img
                          className="rounded-full"
                          src={`${
                            process.env.REACT_APP_API_URL + com.emitter.avatar
                          }`}
                          width="40"
                          height="40"
                          alt="Alex Shatov"
                        />
                      ) : (
                        <div className="w-10 h-10 bg-slate-300 flex justify-center items-center rounded-full">
                          <span className="font-bold text-[2.5em] text-slate-700">
                            {com.emitter.name.substring(0, 1).toUpperCase()}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="font-medium text-gray-800">
                      {com.emitter.name}
                    </div>
                    <div className="font-medium text-gray-400 text-xs ">
                      ({getFormatedDateTime(com.createdAt.toString())})
                    </div>
                  </div>
                  <Button
                    icon={
                      loadingOpenModal.id === com.id &&
                      loadingOpenModal.loading ? (
                        <svg
                          className={classNames(
                            'h-6 w-6',
                            'animate-spin text-negative',
                          )}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : com.status === 'closed' ? (
                        <MailIcon className="w-6" />
                      ) : (
                        <MailOpenIcon className="w-6" />
                      )
                    }
                    colors={classNames(
                      com.status === 'closed'
                        ? 'bg-gray-700 text-gray-100'
                        : 'bg-gray-100 text-gray-700',
                    )}
                    onClick={() => openComunication(com)}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <ModalUpdatePassword
        isOpen={modalUpdatePasswordIsOpen}
        setIsOpen={setModalUpdatePasswordIsOpen}
      />
      <ModalComunication
        isOpen={showModalComunication}
        setIsOpen={setShowModalComunication}
        comunication={modalComucationData.current}
      />
      <ModalComunicationSend
        isOpen={showModalComunicationSend}
        setIsOpen={setShowModalComunicationSend}
      />
      <ModalComunicationArchived
        isOpen={showModalComunicationArchived}
        setIsOpen={setShowModalComunicationArchived}
      />
    </div>
  );
};

export default MyProfile;
