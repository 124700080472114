import { XIcon } from '@heroicons/react/outline';

interface BadgeProps {
  id: number;
  label: string;
  deleteAction?: (key: number) => void;
}

export default function Badge(props: BadgeProps) {
  return (
    <span
      key={props.id}
      className="text-[9px] p-[3px] bg-gray-300 mx-1 align-middle rounded-lg px-1"
    >
      <span>{props.label}</span>{' '}
      {props.deleteAction && (
        <button
          className="align-middle py-[1.1px] hover:bg-gray-400 hover:rounded-full"
          type="button"
          onClick={() => props.deleteAction && props.deleteAction(props.id)}
        >
          <XIcon className="w-3 h-3" />
        </button>
      )}
    </span>
  );
}
