import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { Chat } from '../chat/chat.model';
import Button from '../../components/layout/button';
import { Department } from '../cad/department/department.model';
import { list as listDepartments } from '../cad/department/department.service';
import { User } from '../cad/user/user.model';

interface ModalChatTransferProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  action: (department: string, user: string | null) => void;
  currentChat: Chat;
}

export default function ModalChatTransfer(props: ModalChatTransferProps) {
  function closeModal() {
    props.setIsOpen(false);
  }

  const [departments, setDepartments] = useState<Department[]>([]);
  const [seletedDep, setSelectedDep] = useState<string>();
  const [users, setUsers] = useState<User[]>([]);
  const [seletedUser, setSelectedUser] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    listDepartments().then(setDepartments);
  }, [props.currentChat?.department?.id]);

  useEffect(() => {
    const dep = departments.find(d => d.id === seletedDep);
    const users = dep?.users || [];
    setUsers(users.filter(d => d.id !== props.currentChat?.user?.id));
  }, [departments, props.currentChat?.user?.id, seletedDep]);

  useEffect(() => {
    setSelectedDep(undefined);
    setSelectedUser(undefined);
  }, []);

  const confirm = () => {
    if (seletedDep) {
      const dep = seletedDep;
      const user = seletedUser || null;
      props.action(dep, user);
      closeModal();
    }
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={closeModal}
      open={props.isOpen}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
          >
            <span className="text-lg font-bold leading-6 font-display">
              Transferência de Atendimento
            </span>
            <button onClick={closeModal} className="hover:text-gray-400">
              <XIcon className="w-5 h-5" />
            </button>
          </Dialog.Title>
          <div className="flex flex-col font-display py-4 px-6 space-y-2">
            <div className="flex flex-col max-w-xl">
              <label
                htmlFor="department"
                className="block text-sm font-medium text-gray-700"
              >
                Transferir para outro departamento
              </label>
              <div className="mt-1 relative shadow-sm flex">
                <select
                  id="department"
                  className="form-input px-3 py-2 w-full rounded-tl-md rounded-bl-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Departamento"
                  value={seletedDep}
                  onChange={e => setSelectedDep(e.target.value)}
                >
                  <option value="">Selecione ...</option>
                  {departments.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.desc}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col max-w-xl">
              <label
                htmlFor="department"
                className="block text-sm font-medium text-gray-700"
              >
                Transferir para outro usuário
              </label>
              <div className="mt-1 relative shadow-sm flex">
                <select
                  id="user"
                  className="form-input px-3 py-2 w-full rounded-tl-md rounded-bl-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Departamento"
                  value={seletedUser}
                  onChange={e => setSelectedUser(e.target.value)}
                >
                  <option value="">Selecione ...</option>
                  {users.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex justify-between">
              <Button
                title="Cancelar"
                type="button"
                onClick={closeModal}
                colors="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
              />
              <Button
                title="Enviar"
                type="button"
                colors="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                onClick={confirm}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
