import { PencilIcon } from '@heroicons/react/outline';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTES_MAP from '../../../app.map.routes';
import Button from '../../../components/layout/button';
import { Department } from './department.model';

type Props = {
  data: Department[];
};

const TableDepartment: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  return (
    <div className="">
      <table className="table-auto w-full">
        <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
          <tr className="">
            <th className="p-2 whitespace-nowrap">
              <div className="font-semibold text-left">Descrição</div>
            </th>

            <th className="text-right"></th>
          </tr>
        </thead>
        <tbody className="text-sm divide-y divide-gray-100">
          {props.data.map(data => (
            <tr
              key={data.id}
              style={{
                backgroundColor: data.color,
                color: data.fontColor,
                fontWeight: 'bold',
              }}
            >
              <td className={`p-2 whitespace-nowrap`}>
                <div>{data.desc.toUpperCase()}</div>
              </td>

              <td className="p-2 whitespace-nowrap">
                <div className="flex justify-end">
                  <Button
                    icon={<PencilIcon className="w-4" />}
                    onClick={() =>
                      navigate(ROUTES_MAP.cadDepartmentEdit, {
                        state: { id: data.id },
                      })
                    }
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableDepartment;
