import React from 'react';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useForm } from 'react-hook-form';
import { useAuth } from '../hooks/auth.hook';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// import logoFULL from '../assets/svg/logo-full-original.svg';

const Initial: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const { signIn, isLoggedIn } = useAuth();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const from: any = (location.state as any)?.from?.pathname || '/';

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = async (data: unknown) => {
    try {
      setDisabled(true);
      const { user, password } = data as { user: string; password: string };
      await signIn(user, password).then(() => {
        navigate(from, { replace: true });
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.message);
      }
      toast.error(error.message);
    } finally {
      setDisabled(false);
    }
  };

  if (isLoggedIn) {
    return <Navigate to={from} replace />;
  }

  // console.log(error);

  return (
    <div className="h-full bg-transparency-header bg-no-repeat font-display">
      <div className="flex justify-center">
        {/* <ReactSVG className="w-64 mt-10" src={logoFULL} /> */}
      </div>

      <h1 className="mt-10 text-center font-extrabold text-4xl mb-4">
        Acesso ao Sistema
      </h1>
      <div className="w-full max-w-xs mx-auto my-auto">
        <form className="px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit(submit)}>
          <div className="mb-4">
            <label
              className={`
              flex flex-col
              text-primary
              text-sm
              mt-5
            `}
            >
              Usuário
              <input
                type="text"
                {...register('user', { required: true })}
                placeholder="Digite seu usuário"
                className={`
                  p-2
                  rounded
                  bg-gray-200
                `}
              />
            </label>
          </div>
          <span className="text-xs text-red-500">
            {errors.user && '* Campo requerido'}
          </span>

          <div className="mb-4">
            <label
              className={`
              flex flex-col
              text-primary
              text-sm
              mt-5
            `}
            >
              Senha
              <input
                type="password"
                {...register('password', { required: true })}
                placeholder="Digite sua senha"
                className={`
                  p-2
                  rounded
                  bg-gray-200
                `}
              />
            </label>
          </div>
          <span className="text-xs text-red-500">
            {errors.password && '* Campo requerido'}
          </span>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              color="DEFAULT"
              className={`
                w-full
                ${disabled ? 'bg-gray-400' : 'bg-primary'}
                py-1 px-3
                my-3 mx-auto
                rounded-full
                text-white
                bg-primary1
                hover:bg-primary2
              `}
              disabled={disabled}
            >
              {disabled ? 'Aguarde...' : 'Entrar'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Initial;
