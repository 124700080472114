import { Tab } from '@headlessui/react';
import { PlusIcon, ReplyIcon, TrashIcon } from '@heroicons/react/outline';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import useMedia from 'use-media';
import ROUTES_MAP from '../../../app.map.routes';
import Button from '../../../components/layout/button';
import ModalConfirmation from '../../../components/modalConfirmation';
import { classNames, getPhoneFormat, rawPhone } from '../../../utils/functions';
import getMask from '../../../utils/masks';
import { User } from '../user/user.model';
import { list } from '../user/user.service';
import { Department, DepartmentTopic } from './department.model';
import {
  addTopic,
  addUser,
  delTopic,
  delUser,
  findById,
  insert,
  remove,
  update,
} from './department.service';

interface LocationState {
  id: string | undefined;
}

const FormDepartment: React.FC = () => {
  const isSmall = useMedia({ maxWidth: 480 });
  const location = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const {
    fields,
    append: appendField,
    remove: removeField,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'phones', // unique name for your Field Array
  });

  const fieldId = watch('id');

  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [depUsers, setDepUsers] = useState<User[]>([]);
  const [user, setUser] = useState<string>('');
  const [topicDesc, setTopicDesc] = useState<string>('');
  const [depTopics, setDepTopics] = useState<DepartmentTopic[]>([]);
  const [render, setRender] = useState<number>(0);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (data: any) => {
    const department = data as Department;
    department.contactsNotification = data.phones.reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (acc: string[], { phone }: any) => {
        acc.push(rawPhone(phone));
        return acc;
      },
      [],
    );
    if (!department.id) {
      delete department.id;
      await insert(department);
      navigate(ROUTES_MAP.cadDepartment);
    } else {
      const ret = await update(department);
      loadForm(ret);
    }
  };

  const openModal = () => setModalConfirmIsOpen(true);

  const del = async () => {
    const departmentId = getValues('id');
    if (departmentId) {
      await remove(departmentId);
      navigate(ROUTES_MAP.cadDepartment);
    }
  };

  const loadForm = useCallback(
    (data: Department) => {
      const entries = Object.entries(data);
      entries.forEach(entry => {
        const [key, value] = entry;
        switch (key) {
          case 'contactsNotification':
            removeField();
            (value as string[]).forEach(phone => {
              appendField({ phone: getPhoneFormat(phone) });
            });
            break;

          default: {
            setValue(key, value);
          }
        }
      });
    },
    [appendField, removeField, setValue],
  );

  const insertUserDepartment = async () => {
    const userId = user;
    if (!userId) {
      toast.info('Selecione um usuário');
    }
    const departmentId = getValues('id');
    if (departmentId && userId) {
      const res = await addUser(departmentId, userId);
      loadForm(res);
      setDepUsers(res.users);
      setUser('');
    }
  };

  const insertTopicDepartment = () => {
    if (!topicDesc) {
      toast.info('Informe a descrição do tópico');
    }
    const departmentId = getValues('id');
    if (departmentId) {
      addTopic(departmentId, topicDesc).then(res => {
        console.log(
          '🚀 ~ file: formDepartment.tsx ~ line 108 ~ addTopic ~ res',
          res,
        );
        loadForm(res);
        setDepTopics(res.topics);
        setTopicDesc('');
        setRender(render + 1);
      });
    }
  };

  const removeUserDepartment = async (userId: string) => {
    const departmentId = getValues('id');
    if (departmentId && userId) {
      const res = await delUser(departmentId, userId);
      loadForm(res);
      setDepUsers(res.users);
    }
  };

  const removeTopicDepartment = (topicId: string) => {
    const departmentId = getValues('id');
    if (departmentId && topicId) {
      delTopic(departmentId, topicId).then(res => {
        loadForm(res);
        setDepTopics(res.topics);
        setRender(render + 1);
      });
    }
  };

  useEffect(() => {
    async function load() {
      const { id } = (location.state as LocationState) || { id: undefined };
      if (id) {
        const department = await findById(id);
        if (department) {
          loadForm(department);
          setDepUsers(department.users);
          setDepTopics(department.topics);
        }
      }
      list().then(setUsers);
    }
    load();
  }, [loadForm, location.state, setValue]);

  useEffect(() => {
    if (fields && fields.length === 0) {
      appendField({ phone: '' });
    }
  }, [appendField, fields]);

  return (
    <div className="md:h-[85vh] px-4 overflow-hidden overflow-y-auto">
      {isSmall && (
        <strong className="text-xl">
          {fieldId ? 'Alterar Departamento' : 'Inserir Departamento'}
        </strong>
      )}
      <div className="mx-auto max-w-2xl mt-4">
        <form onSubmit={handleSubmit<Department>(onSubmit)}>
          <div className="flex justify-between my-1">
            <div className="flex space-x-1">
              <Button
                icon={<ReplyIcon className="w-4" />}
                onClick={() => navigate(ROUTES_MAP.cadDepartment)}
              />
              {!isSmall && (
                <strong className="text-xl">
                  {fieldId ? 'Alterar Departamento' : 'Inserir Departamento'}
                </strong>
              )}
            </div>
            <div className="flex space-x-1">
              {fieldId && (
                <Button
                  title="Excluir"
                  colors="bg-red-500 text-white"
                  type="button"
                  onClick={openModal}
                />
              )}
              <Button title="Salvar" type="submit" />
            </div>
          </div>
          {getValues('id') && (
            <p className="text-xs text-gray-500">{getValues('desc')}</p>
          )}
          <Tab.Group>
            <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl">
              <Tab
                className={({ selected }) =>
                  classNames(
                    'w-full py-2.5 text-sm leading-5 font-medium text-negative rounded-lg',
                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
                    selected
                      ? 'bg-white shadow'
                      : 'text-white hover:bg-white/[0.12] hover:text-white',
                  )
                }
              >
                {isSmall ? 'Dados' : 'Dados'}
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'w-full py-2.5 text-sm leading-5 font-medium text-negative rounded-lg',
                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
                    selected
                      ? 'bg-white shadow'
                      : 'text-white hover:bg-white/[0.12] hover:text-white',
                  )
                }
              >
                {isSmall ? 'Notif.' : 'Notificação de Espera'}
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'w-full py-2.5 text-sm leading-5 font-medium text-negative rounded-lg',
                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
                    selected
                      ? 'bg-white shadow'
                      : 'text-white hover:bg-white/[0.12] hover:text-white',
                  )
                }
              >
                {isSmall ? 'Topic.' : 'Tópicos/Assuntos'}
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'w-full py-2.5 text-sm leading-5 font-medium text-negative rounded-lg',
                    'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-primary1 ring-white ring-opacity-60',
                    selected
                      ? 'bg-white shadow'
                      : 'text-white hover:bg-white/[0.12] hover:text-white',
                  )
                }
              >
                {isSmall ? 'Usuários' : 'Usuários'}
              </Tab>
            </Tab.List>
            <Tab.Panels className="mt-2">
              {/* dados basicos */}
              <Tab.Panel
                className={classNames(
                  'h-[63vh] overflow-hidden overflow-y-auto',
                  'flex flex-col space-y-2',
                )}
              >
                <input type="hidden" {...register('id')} />
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="desc"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Descrição
                  </label>
                  <div className="mt-1 relative shadow-sm">
                    <input
                      type="text"
                      id="desc"
                      className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                      placeholder="Descrição"
                      {...register('desc', { required: true })}
                    />
                  </div>
                  <span className="text-xs text-red-500">
                    {errors.desc && '* Campo requerido'}
                  </span>
                </div>
                <div className="flex space-x-4">
                  <div className="flex flex-col w-full">
                    <label
                      htmlFor="color"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Cor de Destaque
                    </label>
                    <div className="mt-1 relative shadow-sm">
                      <input
                        type="color"
                        id="color"
                        className="form-input px-1 h-12 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Cor de Destaque"
                        {...register('color', { required: true })}
                      />
                    </div>
                    <span className="text-xs text-red-500">
                      {errors.color && '* Campo requerido'}
                    </span>
                  </div>
                  <div className="flex flex-col w-full">
                    <label
                      htmlFor="fontColor"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Cor da Letra
                    </label>
                    <div className="mt-1 relative shadow-sm">
                      <input
                        type="color"
                        id="fontColor"
                        className="form-input px-1 h-12 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                        {...register('fontColor', { required: true })}
                      />
                    </div>
                    <span className="text-xs text-red-500">
                      {errors.fontColor && '* Campo requerido'}
                    </span>
                  </div>
                </div>
              </Tab.Panel>
              {/* notificação de espera */}
              <Tab.Panel
                className={classNames(
                  'h-[63vh] overflow-hidden overflow-y-auto',
                  'flex flex-col space-y-2',
                )}
              >
                <div className="flex space-x-2 justify-between">
                  <div className="w-full my-2 max-w-xs flex items-center space-x-2">
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        id="toggle"
                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                        {...register('sendNotification')}
                        defaultChecked={false}
                      />
                      <label
                        htmlFor="toggle"
                        className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                      ></label>
                    </div>
                    <label
                      htmlFor="contactNotification"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Ativar notificação de espera
                    </label>
                  </div>
                  <div className="flex flex-col w-full">
                    <label
                      htmlFor="timeNotification"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Tempo de Espera (em minutos)
                    </label>
                    <div className="mt-1 relative shadow-sm">
                      <input
                        type="number"
                        id="timeNotification"
                        className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder="Tempo de Espera"
                        {...register('timeNotification', {
                          required: false,
                          min: 0,
                        })}
                        defaultValue={0}
                      />
                    </div>
                    <span className="text-xs text-red-500">
                      {errors.timeNotification && '* Campo requerido'}
                    </span>
                  </div>
                </div>
                <p>Telefones que serão notificados</p>
                <div className="flex flex-col space-y-2">
                  {fields.map((field, index) => (
                    <div key={field.id} className="flex items-center space-x-2">
                      {/* <input {...register(`phones.${index}.phone`)} /> */}
                      <Controller
                        name={`phones.${index}.phone`}
                        control={control}
                        render={({ field }) => (
                          <MaskedInput
                            mask={getMask('celular')}
                            type="text"
                            placeholder={'(99) 99999-9999'}
                            className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                            {...field}
                          />
                        )}
                      />
                      <Button
                        icon={<PlusIcon className="w-4 h-4" />}
                        onClick={() => appendField({ phone: '' })}
                        type="button"
                      />
                      <Button
                        icon={<TrashIcon className="w-4 h-4" />}
                        onClick={() => removeField(index)}
                        type="button"
                      />
                    </div>
                  ))}
                </div>
              </Tab.Panel>
              {/* topicos */}
              <Tab.Panel>
                <h1 className="text-md font-bold">
                  {getValues('desc')} - Gerenciar Tópicos
                </h1>
                <div className="bg-gray-100 flex justify-between items-center p-2 space-x-3">
                  <input
                    type="text"
                    id="topicDesc"
                    className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                    placeholder="Descrição"
                    value={topicDesc}
                    onChange={e => setTopicDesc(e.target.value)}
                  />
                  <Button
                    icon={<PlusIcon className="w-6 h-6" />}
                    onClick={insertTopicDepartment}
                    type="button"
                  />
                </div>
                <div className="flex flex-col">
                  {depTopics &&
                    depTopics.map(topic => (
                      <div
                        key={topic.id}
                        className="flex justify-between my-1 border-b-[1px] borderr-negative border-solid py-1"
                      >
                        <div className="flex">
                          <div className="font-medium text-gray-800">
                            {topic.desc}
                          </div>
                        </div>
                        <Button
                          icon={<TrashIcon className="w-4" />}
                          onClick={() =>
                            topic.id && removeTopicDepartment(topic.id)
                          }
                        />
                      </div>
                    ))}
                </div>
              </Tab.Panel>
              {/* usuarios */}
              <Tab.Panel
                className={classNames(
                  'h-[63vh] overflow-hidden overflow-y-auto',
                )}
              >
                <div className="flex flex-col mt-3">
                  <h1 className="text-md font-bold">
                    {getValues('desc')} - Gerenciar Usuários
                  </h1>
                  <div className="bg-gray-100 flex justify-between items-center p-2">
                    <div className="flex flex-col max-w-xl">
                      <div className="mt-1 relative shadow-sm">
                        <select
                          id="users"
                          className="form-input px-3 py-2 pr-10 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                          placeholder="Usuários"
                          value={user}
                          onChange={e => setUser(e.target.value)}
                        >
                          <option value="">Selecione ...</option>
                          {users.map(item => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <span className="text-xs text-red-500">
                        {errors.department && '* Campo requerido'}
                      </span>
                    </div>
                    <Button
                      icon={<PlusIcon className="w-6 h-6" />}
                      onClick={insertUserDepartment}
                      type="button"
                    />
                  </div>
                  <div className="flex flex-col">
                    {depUsers.map(user => (
                      <div
                        key={user.id}
                        className="flex justify-between my-1 border-b-[1px] borderr-negative border-solid py-1"
                      >
                        <div className="flex items-center">
                          <div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                            {user.avatar ? (
                              <img
                                className="rounded-full"
                                src={`${
                                  process.env.REACT_APP_API_URL + user.avatar
                                }`}
                                width="40"
                                height="40"
                                alt="Alex Shatov"
                              />
                            ) : (
                              <div className="w-10 h-10 bg-slate-300 flex justify-center items-center rounded-full">
                                <span className="font-bold text-[2.5em] text-slate-700">
                                  {user.user.substring(0, 1).toUpperCase()}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="font-medium text-gray-800">
                            {user.name}
                          </div>
                        </div>
                        <Button
                          icon={<TrashIcon className="w-4" />}
                          onClick={() =>
                            user.id && removeUserDepartment(user.id)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </form>
      </div>
      <ModalConfirmation
        isOpen={modalConfirmIsOpen}
        setIsOpen={setModalConfirmIsOpen}
        title={'Exclusão'}
        question={'Excluir o departamento: ' + getValues('desc') + '?'}
        answer={del}
      />
    </div>
  );
};

export default FormDepartment;
