import { Dialog } from '@headlessui/react';
import { FilterIcon, XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../../components/layout/button';
import AppPaginate from '../../../components/paginate';
import { classNames } from '../../../utils/functions';
import { Repository } from './repository.model';
import { search } from './repository.service';
import TableRepository from './tableRepository';

interface ModalSelectRepositoryProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  action: (selected: Repository) => void;
}

export default function ModalSelectRepository(
  props: ModalSelectRepositoryProps,
) {
  function closeModal() {
    props.setIsOpen(false);
  }

  const confirm = () => {
    if (!selected) return;
    props.action(selected);
    closeModal();
  };

  const { register, handleSubmit, reset } = useForm();
  const [searchText, setSearchText] = useState<Record<string, unknown>>({});
  const [pageCount, setPageCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(0);
  const [repositories, setRepositories] = useState<Repository[]>([]);
  const [selected, setSelected] = useState<Repository | null>(null);

  useEffect(() => {
    search(searchText, page, limit).then(data => {
      setLimit(data.meta.itemsPerPage);
      setPageCount(data.meta.totalPages);
      setRepositories(data.items);
    });
  }, [limit, page, searchText]);

  const filter = async (form: unknown) => {
    const data = form as Record<string, unknown>;
    setSearchText({ desc: data.desc, type: data.type });
  };

  const _reset = () => {
    setSearchText({});
    reset();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePageClick = (event: { selected: any }) => {
    const newOffset = event.selected + 1;
    setPage(newOffset);
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={closeModal}
      open={props.isOpen}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
          >
            <span className="text-lg font-bold leading-6 font-display">
              Selecionar do Repositório
            </span>
            <button onClick={closeModal} className="hover:text-gray-400">
              <XIcon className="w-5 h-5" />
            </button>
          </Dialog.Title>
          <div className="flex flex-col font-display py-4 px-6 space-y-1">
            <form
              onSubmit={handleSubmit(filter)}
              className="relative text-gray-600 flex flex-col space-y-1 md:space-y-0 md:flex-row md:justify-end md:space-x-1"
            >
              <select
                id="type"
                className="form-input px-3 py-1 md:pr-10 md:w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Tipo"
                {...register('type', { required: false })}
              >
                <option value="">Tipo ...</option>
                <option value="Image">Imagem</option>
                <option value="Document">Documento</option>
                <option value="Audio">Áudio</option>
                <option value="Video">Vídeo</option>
              </select>
              <input
                className="border-2 border-gray-300 bg-white h-10 px-5 rounded-lg text-sm focus:outline-none"
                type="desc"
                {...register('desc', { required: false })}
                placeholder="Busca descrição"
              />
              <div className="flex justify-between md:space-x-2">
                <button
                  type="reset"
                  className={classNames(
                    'bg-gray-200 md:bg-transparent',
                    'text-gray-700 md:text-gray-900',
                    'rounded-lg hover:bg-gray-100',
                    'px-2 py-1',
                  )}
                  onClick={_reset}
                >
                  <XIcon className="w-6 h-6 stroke-current" />
                </button>
                <button
                  type="submit"
                  className={classNames(
                    'bg-gray-200 md:bg-transparent',
                    'text-gray-700 md:text-gray-900',
                    'rounded-lg hover:bg-gray-100',
                    'px-2 py-1',
                  )}
                >
                  <FilterIcon className="w-6 h-6 stroke-current" />
                </button>
              </div>
            </form>
            <div className="h-[45vh] overflow-hidden overflow-y-auto p-1">
              <TableRepository
                data={repositories}
                page={page}
                limit={limit}
                selected={setSelected}
              />
            </div>
            <AppPaginate
              onPageChange={handlePageClick}
              pageCount={pageCount}
              page={page}
            />
            <div className="flex justify-between">
              <Button
                title="Cancelar"
                type="button"
                onClick={closeModal}
                colors="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
              />
              <Button
                title="Enviar"
                type="button"
                onClick={confirm}
                colors="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
