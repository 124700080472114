import { Switch } from '@headlessui/react';

interface MenuSwitchProps {
  checked: boolean;
  onChange: (value: boolean) => void;
}

export default function MenuSwitch(props: MenuSwitchProps) {
  return (
    <Switch
      checked={props.checked}
      onChange={props.onChange}
      className={`${props.checked ? 'bg-white' : 'bg-white'}
          mx-1 relative inline-flex flex-shrink-0 h-[18px] w-[54px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">Exibe o Menu Completo</span>
      <span
        aria-hidden="true"
        className={`${props.checked ? 'translate-x-[36px]' : 'translate-x-0'}
      pointer-events-none inline-block h-[14px] w-[14px] rounded-full bg-gray-500 shadow-lg transform ring-0 transition ease-in-out duration-200`}
      />
    </Switch>
  );
}
