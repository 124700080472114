/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { ApiResponse, Auth, Token } from '../app.models';
import { LOCAL_STORAGE_JWT, LOCAL_STORAGE_RJWT } from '../app.settings';
import api from '../axios';
import { User } from '../pages/cad/user/user.model';

interface IContext {
  isLoggedIn: boolean;
  signIn: (user: string, password: string) => Promise<void>;
  signOut: () => void;
  getJwt: () => Token | undefined;
  myProfile: () => Promise<User>;
  role: string;
}

interface IProvider {
  children: React.ReactNode;
}

const AuthContext = React.createContext<IContext>({} as IContext);

const AuthProvider: React.FC<IProvider> = ({ children }: IProvider) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_JWT);
    if (!token) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, []);

  const signIn = async (user: string, password: string): Promise<void> => {
    const url = '/auth/login';
    try {
      const ret = await api.post<Auth>(url, {
        user,
        password,
      });
      localStorage.setItem(LOCAL_STORAGE_JWT, ret.data.access_token);
      localStorage.setItem(LOCAL_STORAGE_RJWT, ret.data.refresh_token);
      setIsLoggedIn(true);
    } catch (error: any) {
      if (error.response) {
        console.log(error.response.data);
        throw new Error(error.response.data.message);
      } else {
        console.log(error.message);
        throw new Error(error.message);
      }
    }
  };

  const signOut = async () => {
    const url = '/auth/logout';
    await api.post(url).then(() => {
      localStorage.removeItem(LOCAL_STORAGE_JWT);
      localStorage.removeItem(LOCAL_STORAGE_RJWT);
      setIsLoggedIn(false);
    });
  };

  const getJwt = (): Token | undefined => {
    const token = localStorage.getItem(LOCAL_STORAGE_JWT);
    if (!token) return;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  };

  const myProfile = async (): Promise<User> => {
    try {
      const url = '/auth/profile';
      const resp = await api.get<ApiResponse<User>>(url);
      return resp.data.data;
    } catch (error) {
      throw new Error('Usuário não autenticado');
    }
  };

  const role = getJwt()?.role || 'user';

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        signIn,
        signOut,
        getJwt,
        myProfile,
        role,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): IContext => {
  const context = React.useContext(AuthContext);
  return context;
};

export { useAuth, AuthProvider };
