import { Dialog } from '@headlessui/react';
import { MailIcon, MailOpenIcon, XIcon } from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { Comunication } from '../../app.models';
import Button from '../../components/layout/button';
import { useAuth } from '../../hooks/auth.hook';
import { useSocket } from '../../hooks/socket.context';
import { getFormatedDateTime } from '../../utils/datetimeHandler';
import { classNames } from '../../utils/functions';
import ModalComunication from './modalComunication';

interface ModalComunicationArchivedProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export default function ModalComunicationArchived(
  props: ModalComunicationArchivedProps,
) {
  const { socket } = useSocket();
  const { getJwt } = useAuth();
  const [comunications, setComunications] = useState<Comunication[]>([]);
  const [loadingOpenModal, setLoadingOpenModal] = useState({
    id: '',
    loading: false,
  });
  const [showModalComunication, setShowModalComunication] =
    useState<boolean>(false);
  const modalComucationData = useRef({} as Comunication);

  useEffect(() => {
    socket.emit('comunication:archiveds', { receiverId: getJwt()?.id });
    socket.on('comunication:archiveds', (data: Comunication[]) => {
      setComunications(data);
    });
  }, [comunications, getJwt, socket]);

  function closeModal() {
    props.setIsOpen(false);
  }

  const openComunication = (comunication: Comunication) => {
    setLoadingOpenModal({
      id: comunication.id,
      loading: true,
    });

    new Promise(t => setTimeout(t, 1000)).then(() => {
      modalComucationData.current = comunication;
      setShowModalComunication(true);
      setLoadingOpenModal({
        id: comunication.id,
        loading: false,
      });
    });
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={() => null}
      open={props.isOpen}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
          >
            <span className="text-lg font-bold leading-6 font-display">
              Comunicações Arquivadas
            </span>
            <button onClick={closeModal} className="hover:text-gray-400">
              <XIcon className="w-5 h-5" />
            </button>
          </Dialog.Title>
          <div className="flex flex-col font-display py-4 px-6 space-y-2">
            <div className="flex flex-col md:h-[38vh] overflow-hidden overflow-y-auto">
              {comunications.map(com => (
                <div
                  key={com.id}
                  className="flex justify-between my-1 border-b-[1px] borderr-negative border-solid py-1"
                >
                  <div className="flex flex-col space-y-1 md:flex-row md:items-center md:space-x-1">
                    <div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                      {com.emitter.avatar ? (
                        <img
                          className="rounded-full"
                          src={`${
                            process.env.REACT_APP_API_URL + com.emitter.avatar
                          }`}
                          width="40"
                          height="40"
                          alt="Alex Shatov"
                        />
                      ) : (
                        <div className="w-10 h-10 bg-slate-300 flex justify-center items-center rounded-full">
                          <span className="font-bold text-[2.5em] text-slate-700">
                            {com.emitter.name.substring(0, 1).toUpperCase()}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="font-medium text-gray-800">
                      {com.emitter.name}
                    </div>
                    <div className="font-medium text-gray-400 text-xs ">
                      ({getFormatedDateTime(com.createdAt.toString())})
                    </div>
                  </div>
                  <Button
                    icon={
                      loadingOpenModal.id === com.id &&
                      loadingOpenModal.loading ? (
                        <svg
                          className={classNames(
                            'h-6 w-6',
                            'animate-spin text-negative',
                          )}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : com.status === 'closed' ? (
                        <MailIcon className="w-6" />
                      ) : (
                        <MailOpenIcon className="w-6" />
                      )
                    }
                    colors={classNames(
                      com.status === 'closed'
                        ? 'bg-gray-700 text-gray-100'
                        : 'bg-gray-100 text-gray-700',
                    )}
                    onClick={() => openComunication(com)}
                  />
                </div>
              ))}
            </div>
            <div className="flex justify-between">
              <Button
                title="Fechar"
                type="button"
                onClick={closeModal}
                colors="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
              />
            </div>
          </div>
        </div>
      </div>
      <ModalComunication
        isOpen={showModalComunication}
        setIsOpen={setShowModalComunication}
        comunication={modalComucationData.current}
      />
    </Dialog>
  );
}
