import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { Chat } from './chat.model';
import Button from '../../components/layout/button';
import { Department } from '../cad/department/department.model';
import { useAuth } from '../../hooks/auth.hook';
import { create } from './chat.service';
import MaskedInput from 'react-text-mask';
import getMask from '../../utils/masks';
import { search } from './chat.service';
import { toast } from 'react-toastify';
import { rawPhone } from '../../utils/functions';

interface ModalChatCreateProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  action: (chat: Chat) => void;
}

export default function ModalChatCreate(props: ModalChatCreateProps) {
  function closeModal() {
    props.setIsOpen(false);
  }

  const { myProfile } = useAuth();

  const [departments, setDepartments] = useState<Department[]>([]);
  const [seletedDep, setSelectedDep] = useState<string>();
  const [phone, setPhone] = useState<string>();

  useEffect(() => {
    async function init() {
      const profile = await myProfile();
      const deps = profile.departments;
      setDepartments(deps);
    }
    init();
  }, [myProfile]);

  useEffect(() => {
    setSelectedDep(undefined);
    setPhone(undefined);
  }, []);

  const confirm = async () => {
    if (!phone || !seletedDep) return;
    closeModal();
    const _phone = rawPhone(phone);

    // 88997891166
    const formatedChat = '55' + _phone;

    const chats = await search({ phone: formatedChat, status: 'opened' });
    if (chats.length > 0) {
      toast.info('Já existe um atendimento aberto para esse número');
      return;
    }

    const chat = await create(formatedChat, seletedDep);
    if (!chat.id) return;
    props.action(chat);
    props.setIsOpen(false);
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={closeModal}
      open={props.isOpen}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
          >
            <span className="text-lg font-bold leading-6 font-display">
              Iniciar Atendimento
            </span>
            <button onClick={closeModal} className="hover:text-gray-400">
              <XIcon className="w-5 h-5" />
            </button>
          </Dialog.Title>
          <div className="flex flex-col font-display py-4 px-6 space-y-2">
            <div className="flex flex-col max-w-xl">
              <label
                htmlFor="department"
                className="block text-sm font-medium text-gray-700"
              >
                Departamento
              </label>
              <div className="mt-1 relative shadow-sm flex">
                <select
                  id="department"
                  className="form-input px-3 py-2 w-full rounded-tl-md rounded-bl-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Departamento"
                  value={seletedDep}
                  onChange={e => setSelectedDep(e.target.value)}
                >
                  <option value="">Selecione ...</option>
                  {departments.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.desc}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col max-w-xl">
              <label
                htmlFor="department"
                className="block text-sm font-medium text-gray-700"
              >
                Número de telefone
              </label>
              <div className="mt-1 relative shadow-sm flex">
                <MaskedInput
                  mask={getMask('celular')}
                  type="text"
                  placeholder={'(99) 99999-9999'}
                  className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                  value={phone}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="flex justify-between">
              <Button
                title="Cancelar"
                type="button"
                onClick={closeModal}
                colors="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
              />
              <Button
                title="Iniciar"
                type="button"
                colors="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                onClick={confirm}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
