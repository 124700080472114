/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

function blobToBase64(blob: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

type CropedImageProps = {
  info?: string;
  aspect: number;
  postSendAction: (data: any) => void;
};
const CropedImage: React.FC<CropedImageProps> = ({
  aspect,
  postSendAction,
  info,
}: CropedImageProps) => {
  //crop
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(0.5);
  const [image, setImage] = useState<string>();
  const [progress, setProgress] = useState<number>();
  const [cropedImage, setCropedImage] = useState<Blob>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onImageChange = (event: any) => {
    // console.log(event.target.files);
    if (event.target.files && event.target.files[0]) {
      setImage(self.URL.createObjectURL(event.target.files[0]));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCropComplete = async (_: any, croppedAreaPixels: any) => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
    setCropedImage(croppedImage);
  };

  const onUpload = async () => {
    setProgress(0);
    if (cropedImage) {
      blobToBase64(cropedImage).then(img => {
        setProgress(100);
        postSendAction(img);
      });
    }
  };

  // console.log('progress ===>', progress);
  return (
    <>
      {!image && (
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Imagem
          </label>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex flex-col md:flex-row text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <span>Clique aqui</span>
                  <input
                    id="file-upload"
                    type="file"
                    onChange={onImageChange}
                    className="sr-only"
                  />
                </label>
                <p className="pl-1">ou arraste e solte</p>
              </div>
              <p className="text-xs text-gray-500">{info}</p>
            </div>
          </div>
        </div>
      )}
      {image && (
        <div>
          <div className="h-64 w-full relative">
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              // onMediaLoaded={(mediaSize) => {
              //   // Adapt zoom based on media size to fit max height
              //   setZoom(450 / mediaSize.naturalHeight)
              // }}
            />
          </div>
          <div className="z-50">
            <div className="flex items-center space-x-3 my-2">
              <input
                type="range"
                max="3"
                value={zoom}
                step={0.1}
                className="w-1/2"
                aria-labelledby="Zoom"
                onChange={e => setZoom(Number(e.target.value))}
              />
              <div className="w-1/2 bg-gray-200 rounded-full h-4 dark:bg-gray-700">
                {progress && (
                  <div
                    className={classNames(
                      'bg-blue-600 h-4 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full',
                    )}
                    style={{ width: progress + '%' }}
                  >
                    {progress + '%'}
                  </div>
                )}
              </div>
              {/* <progress className="" value={progress} max="100"></progress> */}
            </div>
            <div className="flex space-x-2 justify-between">
              <button
                className="px-4 py-1 cursor-pointer font-bold bg-red-500 hover:bg-red-300 ring-red-900 hover:ring-red-500 ring-1 text-red-900 hover:text-red-500 rounded-full w-full"
                onClick={() => {
                  setImage(undefined);
                }}
              >
                Cancelar
              </button>

              <button
                color="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                onClick={onUpload}
                className="px-4 py-1 cursor-pointer font-bold bg-yellow-500 hover:bg-yellow-300 ring-yellow-900 hover:ring-yellow-500 ring-1 text-yellow-900 hover:text-yellow-500 rounded-full w-full"
              >
                Enviar Imagem
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CropedImage;
