import {
  UserIcon,
  FlagIcon,
  PencilAltIcon,
  BanIcon,
} from '@heroicons/react/outline';
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import ROUTES_MAP from '../../app.map.routes';
import { classNames } from '../../utils/functions';

const SUB_MENUS = [
  {
    key: 1,
    label: 'Usuários',
    info: 'Cadastro de Usuários',
    path: ROUTES_MAP.cadUser,
    icon: UserIcon,
  },
  {
    key: 2,
    label: 'Departamentos',
    info: 'Cadastro de Departamentos',
    path: ROUTES_MAP.cadDepartment,
    icon: FlagIcon,
  },
  {
    key: 3,
    label: 'Rápidas',
    info: 'Cadastro de Mensagens Rápidas',
    path: ROUTES_MAP.cadPredefinedMessage,
    icon: PencilAltIcon,
  },
  {
    key: 4,
    label: 'Banidos',
    info: 'Cadastro de Números Banidos',
    path: ROUTES_MAP.cadBanlist,
    icon: BanIcon,
  },
];

const CadMenu: React.FC = () => {
  return (
    <div className="mt-28 md:mt-0 flex flex-col p-1 md:px-4">
      <div className="grid grid-cols-2 md:grid md:grid-cols-4 md:gap-2">
        {SUB_MENUS.map(({ key, label, info, path, icon }) => (
          <NavLink
            key={key}
            className={({ isActive }) =>
              classNames(
                'md:w-full md:flex p-2 space-x-2 m-1 shadow-md rounded-lg cursor-pointer hover:bg-gray-200 border-[1px]',
                isActive
                  ? 'bg-gray-200 text-black border-white'
                  : 'bg-gray-50 text-gray-400 border-0',
              )
            }
            to={path}
          >
            {React.createElement(icon, {
              className:
                'hidden md:flex md:w-12 md:h-12 inline-block md:p-2 rounded-full border-2 border-current',
            })}
            <div className="flex flex-col">
              <h3 className="text-xs md:text-xs">{info}</h3>
              <h3 className="text-sm md:text-lg font-bold">{label}</h3>
            </div>
          </NavLink>
        ))}
      </div>
      <Outlet />
    </div>
  );
};

export default CadMenu;
