import CadSvg from '../../assets/svg/cad-index.svg';

const AppCad = () => {
  return (
    <div className="max-w-3xl mx-auto">
      <img src={CadSvg} className="w-full md:w-[35vw]" />
    </div>
  );
};

export default AppCad;
