/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { ApiResponse, SearchResult } from '../../../app.models';

import api from '../../../axios';
import { Repository } from './repository.model';

export async function list(): Promise<Repository[]> {
  try {
    const url = '/repository/all';
    const { data } = await api.get<ApiResponse<Repository[]>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function search(
  field: Record<string, any>,
  page?: number,
  limit?: number,
): Promise<SearchResult<Repository>> {
  try {
    const url = `/repository/find?page=${page}&limit=${limit}`;
    const { data } = await api.post<ApiResponse<Repository[]>>(url, field);
    return data.data as unknown as SearchResult<Repository>;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function findById(id: string): Promise<Repository | null> {
  try {
    const url = '/repository/' + id;
    const { data } = await api.get<ApiResponse<Repository>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function insert(repository: FormData): Promise<Repository> {
  try {
    const url = '/repository';
    const { data } = await api.post<ApiResponse<Repository>>(url, repository);
    toast.success('Arquivo cadastrado com sucesso!');
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function update(repository: Repository): Promise<Repository> {
  try {
    const url = '/repository';
    const { data } = await api.put<Repository>(url, repository);
    toast.success('Arquivo atualizado com sucesso!');
    return data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function remove(repositoryId: string): Promise<boolean> {
  try {
    const url = '/repository/' + repositoryId;
    const { data } = await api.delete<any>(url);
    if (data) {
      toast.success('Arquivo removido com sucesso!');
      return true;
    }
    return false;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

function showErrors(errors: string | string[]): void {
  if (Array.isArray(errors)) {
    errors.map((error: string) => toast.error(error));
  } else {
    toast.error(errors);
  }
}
