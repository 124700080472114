/* eslint-disable @typescript-eslint/no-explicit-any */

import { Dialog } from '@headlessui/react';
import {
  StatusOfflineIcon,
  StatusOnlineIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import Button from '../../components/layout/button';
import { useSocket } from '../../hooks/socket.context';

export function Parear(): JSX.Element {
  const { socket, instances } = useSocket();
  const [isOpen, setIsOpen] = useState(false);
  const [qrcode, setQrcode] = useState<string>();
  const [inAction, setInAction] = useState(false);

  useEffect(() => {
    socket.on('instance:qrcode', (payload: string) => {
      const { value } = JSON.parse(payload);
      setQrcode(value);
    });
    socket.on('instance:all', () => {
      setQrcode(undefined);
      setIsOpen(false);
    });
  }, [socket]);

  const reflesh = () => {
    instances.forEach(instance => {
      socket.emit('instance:status', instance.id);
    });
  };

  const connect = async (instanceId: string) => {
    setInAction(true);
    socket.emit('instance:qrcode', instanceId);
    setQrcode(undefined);
    setIsOpen(true);
    await new Promise(t => setTimeout(t, 1000));
    setInAction(false);
  };

  const disconnect = async (instanceId: string) => {
    setInAction(true);
    socket.emit('instance:disconnect', instanceId);
    await new Promise(t => setTimeout(t, 2000)).then(() => {
      reflesh();
    });
    setInAction(false);
  };

  return (
    <>
      <div className="flex justify-between my-1">
        <strong className="text-xl font-bold">Pareamento</strong>
        <Button title="ATUALIZAR" type="button" onClick={reflesh} />
      </div>
      <div className="flex md:grid md:grid-cols-2 md:gap-4">
        {instances.map(instance => {
          return (
            <div
              key={instance.id}
              className="flex flex-col items-center md:max-w-xl bg-gray-200 rounded-md shadow-sm text-gray-400"
            >
              <h1 className="text-sm md:text-xl">
                Número: {instance.phone || 'N/D'}
              </h1>
              <div className="w-full">
                {instance.connected ? (
                  <div className="flex flex-col items-center">
                    <StatusOnlineIcon className="w-full md:h-96 md:w-96 text-green-500 animate-pulse" />
                    <div className="my-2 flex justify-center">
                      <Button
                        title="Desconectar"
                        type="button"
                        onClick={() => disconnect(instance.id)}
                        disabled={inAction}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="">
                    <StatusOfflineIcon className="w-full md:h-96 md:w-96" />
                    <div className="my-2 flex justify-center">
                      <Button
                        title="PAREAR"
                        type="button"
                        onClick={() => connect(instance.id)}
                        disabled={inAction}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => setIsOpen(false)}
        open={isOpen}
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="inline-block p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="h3"
              className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
            >
              <span className="text-lg font-bold leading-6 font-display">
                Parear Número
              </span>
              <button
                onClick={() => setIsOpen(false)}
                className="hover:text-gray-400"
              >
                <XIcon className="w-5 h-5" />
              </button>
            </Dialog.Title>
            <div className="flex flex-col font-display py-4 px-6">
              <div className="h-96 w-96 flex justify-center">
                {qrcode ? (
                  <img
                    src={qrcode}
                    alt="qrcode"
                    className="aspect-1 min-w-full"
                  />
                ) : (
                  <div className="flex justify-center items-center flex-col">
                    <svg
                      className="-ml-1 mr-3 h-32 w-32 animate-spin text-negative"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
