/* eslint-disable @typescript-eslint/no-explicit-any */
import Picker from 'emoji-picker-react';

type Props = {
  onEmojiClick: (emojiObject: any) => void;
  width: string;
  heigth: string;
};

const EmojiPicker = ({ onEmojiClick, heigth, width }: Props) => {
  const _onEmojiClick: any = (event: any, emojiObject: any) => {
    onEmojiClick(emojiObject);
  };

  return (
    <div>
      <Picker
        pickerStyle={{ width: width, height: heigth }}
        onEmojiClick={_onEmojiClick}
        disableSearchBar={true}
        disableSkinTonePicker={true}
      />
    </div>
  );
};
export default EmojiPicker;
