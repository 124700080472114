import moment from 'moment';
import React from 'react';
import { getPhoneFormat, secondsToMinutes } from '../../../utils/functions';
import { Chat } from '../../chat/chat.model';

type Props = {
  data: Chat[];
  rowClick?: (chat: Chat) => void;
};

const TableRelatAttendeance: React.FC<Props> = (props: Props) => {
  const satisfaction = (arg: number | string): string => {
    const value = parseInt(arg as string, 10);
    switch (value) {
      case 1:
        return 'Nada Satisfeito';
      case 2:
        return 'Satisfeito';
      case 3:
        return 'Muito Satisfeito';
      default:
        return 'N/D';
    }
  };
  return (
    <table className="table-auto w-full mt-3">
      <thead className="sticky top-0 text-xs font-semibold uppercase text-gray-400 bg-gray-50">
        <tr className="">
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Data</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Protocolo</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Satisfação</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Celular</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Atendente</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Espera</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Tempo</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Avaliado</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Obs</div>
          </th>
        </tr>
      </thead>
      <tbody className="text-sm divide-y divide-gray-100">
        {props.data.map(data => (
          <tr
            key={data.id}
            className="cursor-pointer hover:bg-gray-100"
            onClick={() => props.rowClick && props.rowClick(data)}
          >
            <td className="p-2 whitespace-nowrap">
              <div className="text-left">
                {moment(data.createdAt).format('DD/MM/YYYY')}
              </div>
            </td>
            <td className="p-2 whitespace-nowrap">
              <div className="text-left">{data.protocol}</div>
            </td>
            <td className="p-2 whitespace-nowrap">
              <div className="text-left">{satisfaction(data.satisfaction)}</div>
            </td>
            <td className="p-2 whitespace-nowrap">
              <div className="text-left">{getPhoneFormat(data.phone)}</div>
            </td>
            <td className="p-2 whitespace-nowrap">
              <div className="text-left">{data.user.user}</div>
            </td>

            {/* espera */}
            <td className="p-2 whitespace-nowrap">
              <div className="text-left">
                {secondsToMinutes(
                  moment(data.attendedAt).diff(
                    moment(data.openedAt),
                    'seconds',
                  ),
                )}
              </div>
            </td>

            {/* tempo */}
            <td className="p-2 whitespace-nowrap">
              <div className="text-left">
                {secondsToMinutes(
                  moment(data.finishedAt).diff(
                    moment(data.attendedAt),
                    'seconds',
                  ),
                )}
              </div>
            </td>
            <td className="p-2 whitespace-nowrap">
              <div className="text-left">{data.evaluated ? 'Sim' : 'Não'}</div>
            </td>
            <td className="p-2 whitespace-nowrap">
              <div className="text-left">{data.obs ? 'Sim' : 'Não'}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableRelatAttendeance;
