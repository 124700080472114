import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES_MAP from '../../../app.map.routes';
import Button from '../../../components/layout/button';
import ModalConfirmation from '../../../components/modalConfirmation';
import { User } from './user.model';
import { findById, insert, remove, update } from './user.service';

interface LocationState {
  id: string | undefined;
}

const FormUser: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const fieldId = watch('id');

  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState<boolean>(false);

  const onSubmit = async (data: unknown) => {
    const user = data as User;
    if (!user.id) {
      delete user.id;
      await insert(user);
      navigate(ROUTES_MAP.cadUser);
    } else {
      const ret = await update(user);
      loadForm(ret);
    }
  };

  const openModal = () => setModalConfirmIsOpen(true);

  const del = async () => {
    const userId = getValues('id');
    if (userId) {
      await remove(userId);
      navigate(ROUTES_MAP.cadUser);
    }
  };

  const loadForm = useCallback(
    (data: User) => {
      const entries = Object.entries(data);
      entries.forEach(entry => {
        switch (entry[0]) {
          case 'department': {
            if (typeof entry[1] === 'object') {
              setValue(entry[0], entry[1].id);
            } else {
              setValue(entry[0], entry[1]);
            }
            break;
          }

          default: {
            setValue(entry[0], entry[1]);
          }
        }
      });
    },
    [setValue],
  );

  useEffect(() => {
    async function load() {
      const { id } = (location.state as LocationState) || { id: undefined };
      if (id) {
        const user = await findById(id);
        if (user) {
          loadForm(user);
        }
      }
    }
    load();
  }, [loadForm, location.state, setValue]);

  return (
    <div className="h-[85vh] px-4 overflow-hidden overflow-y-auto">
      <form
        onSubmit={handleSubmit<User>(onSubmit)}
        className="mx-auto max-w-xl mt-4"
      >
        <div className="flex justify-between my-1">
          <strong className="text-xl">
            {fieldId ? 'Alterar Usuário' : 'Inserir Usuário'}
          </strong>
          <div className="flex space-x-1">
            {fieldId && (
              <Button
                title="Excluir"
                colors="bg-red-500 text-white"
                type="button"
                onClick={openModal}
              />
            )}
            <Button title="Salvar" type="submit" />
          </div>
        </div>
        <input type="hidden" {...register('id')} />
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Nome Completo
          </label>
          <div className="mt-1 relative shadow-sm">
            <input
              type="text"
              id="name"
              className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="Nome Completo"
              {...register('name', { required: true })}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.name && '* Campo requerido'}
          </span>
        </div>
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="user"
            className="block text-sm font-medium text-gray-700"
          >
            User Name
          </label>
          <div className="mt-1 relative shadow-sm">
            <input
              type="text"
              id="user"
              className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="User Name"
              {...register('user', { required: true })}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.user && '* Campo requerido'}
          </span>
        </div>
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <div className="mt-1 relative shadow-sm">
            <input
              type="email"
              id="email"
              className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="Email"
              {...register('email', { required: true })}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.email && '* Campo requerido'}
          </span>
        </div>

        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="cel"
            className="block text-sm font-medium text-gray-700"
          >
            Celular
          </label>
          <div className="mt-1 relative shadow-sm">
            <input
              type="text"
              id="cel"
              className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="Celular"
              {...register('cel', { required: true })}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.cel && '* Campo requerido'}
          </span>
        </div>

        {!fieldId && (
          <div className="flex flex-col max-w-xl">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Senha Inicial
            </label>
            <div className="mt-1 relative shadow-sm">
              <input
                type="text"
                id="password"
                className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Senha Inicial"
                {...register('password', { required: true })}
              />
            </div>
            <span className="text-xs text-red-500">
              {errors.password && '* Campo requerido'}
            </span>
          </div>
        )}

        {/* <div className="flex flex-col max-w-xl">
          <label
            htmlFor="department"
            className="block text-sm font-medium text-gray-700"
          >
            Departamento
          </label>
          <div className="mt-1 relative shadow-sm">
            <select
              id="department"
              className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="Departamento"
              {...register('department', { required: true })}
            >
              <option value="">Selecione ...</option>
              {departments.map(item => (
                <option key={item.id} value={item.id}>
                  {item.desc}
                </option>
              ))}
            </select>
          </div>
          <span className="text-xs text-red-500">
            {errors.department && '* Campo requerido'}
          </span>
        </div> */}

        <div className="flex justify-around space-x-2 rounded-xl bg-gray-200 p-2 max-x-xl my-3">
          <div>
            <input
              type="radio"
              {...register('role', { required: true })}
              value="user"
              id="1"
              className="peer hidden"
            />
            <label
              htmlFor="1"
              className="block cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-primary1 peer-checked:font-bold peer-checked:text-white"
            >
              USUÁRIO
            </label>
          </div>

          <div>
            <input
              type="radio"
              {...register('role', { required: true })}
              value="admin"
              id="2"
              className="peer hidden"
            />
            <label
              htmlFor="2"
              className="block cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-primary1 peer-checked:font-bold peer-checked:text-white"
            >
              ADMINISTRADOR
            </label>
          </div>
        </div>
        <span className="text-xs text-red-500">
          {errors.role && '* Campo requerido'}
        </span>
        <div className="my-2 max-w-xs">
          <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input
              type="checkbox"
              id="toggle"
              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
              {...register('isActive')}
              defaultChecked={true}
            />
            <label
              htmlFor="toggle"
              className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
          <label htmlFor="toggle" className="text-xs text-gray-700">
            Usuário Ativo
          </label>
        </div>
      </form>
      <ModalConfirmation
        isOpen={modalConfirmIsOpen}
        setIsOpen={setModalConfirmIsOpen}
        title={'Exclusão'}
        question={'Excluir o usuário: ' + getValues('user') + '?'}
        answer={del}
      />
    </div>
  );
};

export default FormUser;
