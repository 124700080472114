import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES_MAP from '../../../app.map.routes';
import Button from '../../../components/layout/button';
import ModalConfirmation from '../../../components/modalConfirmation';
import { Repository } from './repository.model';
import { findById, insert, remove, update } from './repository.service';
import { ReplyIcon } from '@heroicons/react/outline';
import { list as listUsers } from '../user/user.service';
import { User } from '../user/user.model';
import { getRepositoryType } from '.';
import { useAuth } from '../../../hooks/auth.hook';
import useMedia from 'use-media';

interface LocationState {
  id: string | undefined;
}

const FormRepository: React.FC = () => {
  const isSmall = useMedia({ maxWidth: 480 });
  const { myProfile } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const [users, setUsers] = useState<User[]>([]);
  const [img, setImg] = useState({ type: '', path: '' });
  const [upd, setUpd] = useState({ user: '', type: '' });

  const fieldId = watch('id', '');

  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (data: any) => {
    const user = await myProfile();
    if (!data.id) {
      delete data.id;
      const formData = new FormData();
      formData.append('desc', data.desc);
      formData.append('type', data.type);
      user.id && formData.append('user', user.id);
      formData.append('file', data.file[0]);

      await insert(formData);
      navigate(ROUTES_MAP.repository);
    } else {
      data.user = upd.user;
      data.type = upd.type;
      const ret = await update(data);
      loadForm(ret);
    }
  };

  const openModal = () => setModalConfirmIsOpen(true);

  const del = async () => {
    const repositoryId = getValues('id');
    if (repositoryId) {
      await remove(repositoryId);
      navigate(ROUTES_MAP.repository);
    }
  };

  const loadForm = useCallback(
    (data: Repository) => {
      setImg({ type: data.type, path: data.path });
      setUpd({ type: data.type, user: data.path });
      const entries = Object.entries(data);
      entries.forEach(entry => {
        switch (entry[0]) {
          case 'user':
            setValue(entry[0], entry[1].id);
            break;

          default: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue(entry[0], entry[1]);
          }
        }
      });
    },
    [setValue],
  );

  const back = () => {
    navigate(ROUTES_MAP.repository, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state: { params: (location.state as any)?.['params'] },
    });
  };

  useEffect(() => {
    async function load() {
      listUsers().then(setUsers);
      const { id } = (location.state as LocationState) || { id: undefined };
      if (id) {
        const repository = await findById(id);
        if (repository) {
          loadForm(repository);
        }
      }
    }
    load();
  }, [loadForm, location.state, setValue]);

  return (
    <div className="md:h-[95vh] mt-28 md:mt-0 px-4 overflow-hidden overflow-y-auto">
      {isSmall && (
        <strong className="text-xl">
          {fieldId ? 'Alterar Repositório' : 'Inserir Repositório'}
        </strong>
      )}
      <form
        onSubmit={handleSubmit<Repository>(onSubmit)}
        className="mx-auto max-w-xl mt-4"
      >
        <div className="flex justify-between my-1">
          <div className="flex space-x-1">
            <Button icon={<ReplyIcon className="w-4" />} onClick={back} />
            {!isSmall && (
              <strong className="text-xl">
                {fieldId ? 'Alterar Repositório' : 'Inserir Repositório'}
              </strong>
            )}
          </div>
          <div className="flex space-x-1">
            {fieldId && (
              <Button
                title="Excluir"
                colors="bg-red-500 text-white"
                type="button"
                onClick={openModal}
              />
            )}
            <Button title="Salvar" type="submit" />
          </div>
        </div>
        <input type="hidden" {...register('id')} />
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="desc"
            className="block text-sm font-medium text-gray-700"
          >
            Descrição
          </label>
          <div className="mt-1 relative shadow-sm">
            <input
              type="text"
              id="desc"
              className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="Descrição"
              {...register('desc', { required: true })}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.desc && '* Campo requerido'}
          </span>
        </div>
        {fieldId ? (
          <>
            <div className="flex flex-col max-w-xl">
              <label
                htmlFor="type"
                className="block text-sm font-medium text-gray-700"
              >
                Tipo de Arquivo
              </label>
              <div className="mt-1 relative shadow-sm flex flex-col md:flex-row md:justify-between">
                <label htmlFor="image">
                  <input
                    type="radio"
                    id="image"
                    value="Image"
                    {...register('type', { required: true, disabled: true })}
                    className="mr-2"
                  />
                  <strong>Imagem</strong>
                </label>
                <label htmlFor="document">
                  <input
                    type="radio"
                    id="document"
                    value="Document"
                    {...register('type', { required: true, disabled: true })}
                    className="mr-2"
                  />
                  <strong>Documento</strong>
                </label>
                <label htmlFor="video">
                  <input
                    type="radio"
                    id="video"
                    value="Video"
                    {...register('type', { required: true, disabled: true })}
                    className="mr-2"
                  />
                  <strong>Vídeo</strong>
                </label>
                <label htmlFor="audio">
                  <input
                    type="radio"
                    id="audio"
                    value="Audio"
                    {...register('type', { required: true, disabled: true })}
                    className="mr-2"
                  />
                  <strong>Áudio</strong>
                </label>
              </div>
              <span className="text-xs text-red-500">
                {errors.type && '* Campo requerido'}
              </span>
            </div>
            <div className="flex flex-col max-w-xl">
              <label
                htmlFor="user"
                className="block text-sm font-medium text-gray-700"
              >
                Usuário
              </label>
              <div className="mt-1 relative shadow-sm">
                <select
                  id="user"
                  className="form-input px-3 py-2 pr-10 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Usuários"
                  {...register('user', { required: false, disabled: true })}
                >
                  <option value="">Selecione ...</option>
                  {users.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <span className="text-xs text-red-500">
                {errors.user && '* Campo requerido'}
              </span>
            </div>
            <div className="flex justify-center mt-3">
              <a href={getValues('path')} target="_blank" rel="noreferrer">
                {getRepositoryType(img.type, img.path, '200').icon}
              </a>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col max-w-xl">
              <label
                htmlFor="type"
                className="block text-sm font-medium text-gray-700"
              >
                Tipo de Arquivo
              </label>
              <div className="mt-1 relative shadow-sm flex flex-col md:flex-row md:justify-between">
                <label htmlFor="image">
                  <input
                    type="radio"
                    id="image"
                    value="image"
                    {...register('type', { required: true })}
                    className="mr-2"
                  />
                  <strong>Imagem</strong>
                </label>
                <label htmlFor="document">
                  <input
                    type="radio"
                    id="document"
                    value="document"
                    {...register('type', { required: true })}
                    className="mr-2"
                  />
                  <strong>Documento</strong>
                </label>
                <label htmlFor="video">
                  <input
                    type="radio"
                    id="video"
                    value="video"
                    {...register('type', { required: true })}
                    className="mr-2"
                  />
                  <strong>Vídeo</strong>
                </label>
                <label htmlFor="audio">
                  <input
                    type="radio"
                    id="audio"
                    value="audio"
                    {...register('type', { required: true })}
                    className="mr-2"
                  />
                  <strong>Áudio</strong>
                </label>
              </div>
              <span className="text-xs text-red-500">
                {errors.type && '* Campo requerido'}
              </span>
            </div>
            <div className="flex flex-col max-w-xl">
              <label
                htmlFor="file"
                className="block text-sm font-medium text-gray-700"
              >
                Upload de Arquivo
              </label>
              <div className="mt-1 relative shadow-sm">
                <input
                  type="file"
                  id="file"
                  className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Descrição"
                  {...register('file', { required: true })}
                />
              </div>
              <span className="text-xs text-red-500">
                {errors.file && '* Campo requerido'}
              </span>
            </div>
          </>
        )}
      </form>
      <ModalConfirmation
        isOpen={modalConfirmIsOpen}
        setIsOpen={setModalConfirmIsOpen}
        title={'Exclusão'}
        question={'Excluir arquivo: ' + getValues('desc') + '?'}
        answer={del}
      />
    </div>
  );
};

export default FormRepository;
