import {
  ChatAlt2Icon,
  ClockIcon,
  EyeIcon,
  StatusOfflineIcon,
} from '@heroicons/react/outline';
import { Chat } from '../chat/chat.model';
import { useSocket } from '../../hooks/socket.context';
import { classNames } from '../../utils/functions';
import moment from 'moment';
import ModalPreview from '../chat/modalPreview';
import { useEffect, useState } from 'react';
import useMedia from 'use-media';

export default function AppMonitor(): JSX.Element {
  const isSmall = useMedia({ maxWidth: 480 });
  const { chats, instances } = useSocket();
  const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState<Chat>({} as Chat);
  const [connected, setConnected] = useState(false);
  useEffect(() => {
    setConnected(false);
    instances.forEach(instance => {
      if (instance.connected) {
        setConnected(true);
      }
    });
  }, [instances]);

  const previewChat = (chat: Chat) => {
    setSelectedChat(chat);
    setModalPreviewOpen(true);
  };

  useEffect(() => {
    setSelectedChat(
      state => chats.find(chat => chat.id === state.id) || ({} as Chat),
    );
  }, [chats]);

  if (!connected) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <StatusOfflineIcon className="h-60 w-60 text-red-500 animate-pulse" />
      </div>
    );
  }

  if (isSmall)
    return (
      <div className="mt-28 md:mt-0 p-1 md:p-4">
        {/* mobile */}
        <div className="text-center">Atendimentos</div>

        <div className="flex flex-col space-y-2 mt-2 cursor-pointer">
          {chats.map((chat: Chat) => {
            return (
              <button
                key={chat.id}
                className={classNames(
                  'w-[90vw] mx-auto',
                  'px-2 rounded-lg py-2',
                  chat.user
                    ? 'bg-green-200 text-green-800'
                    : chat.department?.id
                    ? 'bg-yellow-200 text-yellow-800'
                    : 'bg-gray-200 text-gray-800',
                  'text-xs',
                )}
                onClick={() => previewChat(chat)}
              >
                <div className="flex items-center space-x-3">
                  <span className="">
                    {chat.photo ? (
                      <div className="aspect-1 w-10 rounded-full bg-gray-700 ring ring-gray-700 ring-offset-base-100 ring-offset-2 flex flex-col justify-center items-center">
                        <img className="rounded-full" src={chat.photo} />
                      </div>
                    ) : (
                      <div className="aspect-1 w-10 rounded-full bg-gray-700 ring ring-gray-700 ring-offset-base-100 ring-offset-2 flex flex-col justify-center items-center">
                        <span className="text-white font-bold text-2xl">
                          {chat.chatName
                            ? chat.chatName.substring(0, 1)
                            : chat.phone.substring(0, 1)}
                        </span>
                      </div>
                    )}
                  </span>
                  <div className="flex flex-col items-start text-slate-500">
                    <div className="line-clamp-1">
                      <strong>
                        {chat.chatName
                          ? `${chat.phone} - ${chat.chatName}`
                          : chat.phone}
                      </strong>
                    </div>
                    <div className="line-clamp-1">
                      <strong>
                        {chat?.department?.desc || 'Pré-atendimento'}
                      </strong>
                    </div>
                    <div className="line-clamp-1">
                      <strong>Tópico: {chat.topic || 'N/D'}</strong>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <span>
                    Usuário: {chat.user?.user?.toUpperCase() || 'N/D'}
                  </span>
                  <span>Tempo de espera:</span>
                </div>
                <div className="flex justify-between">
                  {/* data/hora */}
                  <span className="col-span-3 flex flex-col justify-center font-bold">
                    <span className="flex justify-start space-x-1">
                      <ClockIcon className="w-4 h-4" />{' '}
                      {chat?.createdAt &&
                        moment(chat.createdAt).format('DD/MM/YYYY HH:mm')}
                    </span>
                  </span>

                  {/* aviso de interação */}
                  <span className="flex flex-col justify-center items-center">
                    {chat.messages[chat.messages.length - 1]?.fromMe ? (
                      <ChatAlt2Icon className="w-6 h-6 text-green-500" />
                    ) : (
                      <ChatAlt2Icon className="w-6 h-6 text-yellow-500 animate-bounce" />
                    )}
                  </span>

                  {/* espera */}
                  <span className="col-span-2 flex items-center space-x-1">
                    <svg
                      className="animate-spin mr-1 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <strong>
                      {moment().diff(
                        moment(
                          chat.messages[chat.messages.length - 1]?.createdAt,
                        ),
                        'minutes',
                      )}
                      m
                    </strong>
                  </span>
                </div>
              </button>
            );
          })}
        </div>

        <ModalPreview
          title={selectedChat.chatName || selectedChat.phone}
          isOpen={modalPreviewOpen}
          setIsOpen={setModalPreviewOpen}
          messages={selectedChat.messages}
          onClose={() => {
            setSelectedChat({} as Chat);
          }}
          notDepartment={selectedChat.department?.id ? false : true}
        />
      </div>
    );

  return (
    <div className="mt-28 md:mt-0 p-1 md:p-4">
      <div
        className={classNames(
          'grid grid-cols-24 gap-2 content-center',
          'my-1 py-2 px-1 rounded-lg',
          'text-gray-300',
          'text-xs',
        )}
      >
        {/* ordeanção */}
        <span className="">Ord.</span>

        {/* nome */}
        <span className="col-span-3">Nome</span>

        {/* numero */}
        <span className="col-span-5">Número</span>

        {/* departamento */}
        <span className="col-span-4">Departamento</span>

        {/* data/hora */}
        <span className="col-span-3">Data/Hora</span>

        {/* aviso de interação */}
        <span className=""></span>

        {/* espera */}
        <div className="col-span-2">Espera</div>

        {/* usuario */}
        <span className="col-span-2">Usuário</span>

        {/* controles */}
        <span className="col-span-3 text-center">Contoles</span>
      </div>
      {chats.map((chat: Chat, index: number) => {
        return (
          <div
            key={chat.id}
            className={classNames(
              'grid grid-cols-24 gap-2',
              'my-1 px-1 rounded-lg py-2',
              chat.user
                ? 'bg-green-200 text-green-800'
                : chat.department?.id
                ? 'bg-yellow-200 text-yellow-800'
                : 'bg-gray-200 text-gray-800',
              'text-xs',
            )}
          >
            {/* ordeanção */}
            <span className="text-sm flex flex-col justify-center font-bold">
              <span className="flex justify-start space-x-1">
                # {index + 1}
              </span>
            </span>

            {/* nome */}
            <span className="col-span-3 flex flex-col justify-center font-bold">
              <span className="flex justify-start space-x-1">
                <p className="truncate">{chat.chatName || 'N/D'}</p>
              </span>
            </span>

            {/* numero */}
            <span className="col-span-5 flex flex-col justify-center font-bold">
              <span className="flex justify-start space-x-1">
                <strong>{chat.phone || 'N/D'}</strong>
              </span>
            </span>

            {/* departamento */}
            <span
              style={{
                backgroundColor: chat?.department?.color || '#c5c5c5',
                color: chat?.department?.fontColor || '#505050',
              }}
              className="col-span-4 flex flex-col justify-center items-center font-bold rounded-lg"
            >
              {chat.department?.desc || 'PRÉ-ATENDIMENTO'}
            </span>

            {/* data/hora */}
            <span className="col-span-3 flex flex-col justify-center font-bold">
              <span className="flex justify-start space-x-1">
                <ClockIcon className="w-4 h-4" />{' '}
                {moment(chat.createdAt).format('DD/MM/YYYY HH:mm')}
              </span>
            </span>

            {/* aviso de interação */}
            <span className="flex flex-col justify-center items-center">
              {chat.messages[chat.messages.length - 1].fromMe ? (
                <ChatAlt2Icon className="w-6 h-6 text-green-500" />
              ) : (
                <ChatAlt2Icon className="w-6 h-6 text-yellow-500 animate-bounce" />
              )}
            </span>

            {/* espera */}
            <span className="col-span-2 flex items-center space-x-1">
              <svg
                className="animate-spin mr-1 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <strong>
                {moment().diff(
                  moment(chat.messages[chat.messages.length - 1].createdAt),
                  'minutes',
                )}
                m
              </strong>
            </span>

            {/* usuario */}
            <span className="col-span-2 flex flex-col justify-center">
              <span>{chat.user?.user?.toUpperCase() || 'N/D'}</span>
            </span>

            {/* controles */}
            <span className="col-span-3 flex flex-col justify-center">
              <div className="flex justify-evenly">
                <button
                  onClick={() => previewChat(chat)}
                  className="font-normal hover:scale-125"
                  title="Abrir chat"
                >
                  <EyeIcon className="w-6 h-6" />
                </button>
              </div>
            </span>
          </div>
        );
      })}
      <ModalPreview
        title={selectedChat.chatName || selectedChat.phone}
        isOpen={modalPreviewOpen}
        setIsOpen={setModalPreviewOpen}
        messages={selectedChat.messages}
        onClose={() => {
          setSelectedChat({} as Chat);
        }}
        notDepartment={selectedChat.department?.id ? false : true}
      />
    </div>
  );
}
