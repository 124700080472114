import { SearchIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES_MAP from '../../../app.map.routes';
import Button from '../../../components/layout/button';
import { Banlist } from './banlist.model';
import { search } from './banlist.service';
import TableBanlist from './tableBanlist';
import AppPaginate from '../../../components/paginate';

const CadBanlist: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit } = useForm();

  const [pageCount, setPageCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchText, setSearchText] = useState<Record<string, any>>({});

  useEffect(() => {
    search(searchText, page, limit).then(data => {
      setLimit(data.meta.itemsPerPage);
      setPageCount(data.meta.totalPages);
      setBanlists(data.items);
    });
  }, [limit, page, searchText]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params = (location.state as any)?.['params'];
    if (params) {
      setPage(params.page);
      setLimit(params.limit);
    }
  }, [location.state]);

  const [banlists, setBanlists] = React.useState<Banlist[]>([]);

  const submit = async (form: unknown) => {
    const search = (form as { search: string }).search;
    setSearchText({ desc: search });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePageClick = (event: { selected: any }) => {
    const newOffset = event.selected + 1;
    setPage(newOffset);
  };

  return (
    <div className="md:h-[85vh] px-4 overflow-hidden overflow-y-auto">
      <div className="flex flex-col md:flex-row md:justify-between my-1">
        <strong className="text-xl">Números Banidos</strong>
        <div className="flex space-x-2">
          <form
            onSubmit={handleSubmit(submit)}
            className="relative text-gray-600"
          >
            <input
              className="w-full border-2 border-gray-300 bg-white h-10 px-2 md:px-5 pr-6 md:pr-16 rounded-lg text-sm focus:outline-none"
              type="search"
              {...register('search', { required: false })}
              placeholder="Busca numero banido"
            />
            <button type="submit" className="absolute right-0 top-0 mt-2 mr-4">
              <SearchIcon className="w-6 h-6 stroke-current" />
            </button>
          </form>
          <Button
            title="Inserir"
            onClick={() => navigate(ROUTES_MAP.cadBanlistNew)}
          />
        </div>
      </div>
      <TableBanlist
        data={banlists}
        location={location}
        page={page}
        limit={limit}
      />
      <AppPaginate
        onPageChange={handlePageClick}
        pageCount={pageCount}
        page={page}
      />
    </div>
  );
};

export default CadBanlist;
