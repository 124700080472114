import * as HeroIcons from '@heroicons/react/outline';
import { createElement } from 'react';
import ROUTES_MAP from '../../app.map.routes';

export interface IMenu {
  title: string;
  link: string;
  icon: JSX.Element;
  subMenu: IMenu[];
  onClick?: () => void;
  info: string | number | null;
}

export const MENU_ITEMS: IMenu[] = [
  {
    title: 'Dashboard',
    link: ROUTES_MAP.dashboard,
    icon: createElement(HeroIcons.ViewGridIcon, { className: 'h-6 w-6' }),
    subMenu: [],
    info: null,
  },
  {
    title: 'Cadastros',
    link: ROUTES_MAP.cad,
    icon: createElement(HeroIcons.FolderIcon, { className: 'h-6 w-6' }),
    subMenu: [],
    info: null,
  },
  {
    title: 'Atendimento',
    link: ROUTES_MAP.chats,
    icon: createElement(HeroIcons.ChatIcon, { className: 'h-6 w-6' }),
    subMenu: [],
    info: null,
  },
  {
    title: 'Repositório',
    link: ROUTES_MAP.repository,
    icon: createElement(HeroIcons.FolderAddIcon, { className: 'h-6 w-6' }),
    subMenu: [],
    info: null,
  },
  {
    title: 'Monitor',
    link: ROUTES_MAP.monitor,
    icon: createElement(HeroIcons.DesktopComputerIcon, {
      className: 'h-6 w-6',
    }),
    subMenu: [],
    info: null,
  },
  {
    title: 'Relatórios',
    link: ROUTES_MAP.relat,
    icon: createElement(HeroIcons.PrinterIcon, {
      className: 'h-6 w-6',
    }),
    subMenu: [],
    info: null,
  },
  {
    title: 'Meu Perfil',
    link: ROUTES_MAP.myProfile,
    icon: createElement(HeroIcons.UserIcon, {
      className: 'h-6 w-6',
    }),
    subMenu: [],
    info: 'comunication',
  },
  {
    title: 'Configurações',
    link: ROUTES_MAP.config,
    icon: createElement(HeroIcons.CogIcon, {
      className: 'h-6 w-6',
    }),
    subMenu: [],
    info: null,
  },
];
