const ROUTES_MAP = {
  dashboard: '/',

  // atendimento
  chats: '/atendimento',
  chatHandler: '/atendimento/chat',

  monitor: '/monitor',

  campaing: '/campanha',

  // relat
  relat: '/relatorios',
  relatAttendance: '/relatorios/atendimento',
  relatProtocol: '/relatorios/protocolo',

  login: '/login',
  rating: '/avaliar/:chatId',
  unauthorized: '/acesso/nao-autorizado',

  config: '/config',

  myProfile: '/my-profile',

  cad: '/cadastros',
  // usuarios
  cadUser: '/cadastros/usuarios',
  cadUserNew: '/cadastros/usuarios/novo',
  cadUserEdit: '/cadastros/usuarios/editar',
  // departamentos
  cadDepartment: '/cadastros/departamentos',
  cadDepartmentNew: '/cadastros/departamento',
  cadDepartmentEdit: '/cadastros/departamento/editar',
  // Mensagens pre-definidas
  cadPredefinedMessage: '/cadastros/pre-definidas',
  cadPredefinedMessageNew: '/cadastros/pre-definida',
  cadPredefinedMessageEdit: '/cadastros/pre-definida/editar',
  // Mensagens pre-definidas
  cadBanlist: '/cadastros/banidos',
  cadBanlistNew: '/cadastros/banido',
  cadBanlistEdit: '/cadastros/banido/editar',

  // repository
  repository: '/repositorio',
  repositoryNew: '/repositorio/novo',
  repositoryEdit: '/repositorio/editar',
};

export default ROUTES_MAP;
