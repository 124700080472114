import React, { useEffect, useState } from 'react';
import { Chat } from '../chat/chat.model';
import { useAuth } from '../../hooks/auth.hook';
import { useSocket } from '../../hooks/socket.context';
import { Department } from '../cad/department/department.model';
import AppChatList from './chatList';
import { StatusOfflineIcon } from '@heroicons/react/outline';

const AppChats: React.FC = () => {
  const { myProfile } = useAuth();
  const { chats, instances } = useSocket();

  const [_chats, setChats] = React.useState<Chat[]>([]);
  const [_departments, setDepartments] = React.useState<string[]>([]);
  const [connected, setConnected] = useState(false);
  useEffect(() => {
    setConnected(false);
    instances.forEach(instance => {
      if (instance.connected) {
        setConnected(true);
      }
    });
  }, [instances]);

  useEffect(() => {
    myProfile().then(profile => {
      const departments: string[] = profile.departments.reduce(
        (acc: string[], curr: Department) => {
          if (curr.id) acc.push(curr.id);
          return acc;
        },
        [],
      );
      setDepartments(departments);
    });
  }, [myProfile]);

  useEffect(() => {
    const filterChats = chats.filter(chat => {
      if (chat.department === null) {
        return true;
      } else if (
        chat.department?.id &&
        _departments.includes(chat.department.id)
      ) {
        return true;
      } else {
        return false;
      }
    });
    setChats(filterChats);
  }, [_departments, chats]);

  if (!connected) {
    // if (connected) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <StatusOfflineIcon className="h-60 w-60 text-red-500 animate-pulse" />
      </div>
    );
  }

  return (
    <div className="mt-28 md:mt-0 p-1 md:p-4">
      <AppChatList chats={_chats} />
    </div>
  );
};

export default AppChats;
