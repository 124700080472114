import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES_MAP from '../../../app.map.routes';
import Button from '../../../components/layout/button';
import ModalConfirmation from '../../../components/modalConfirmation';
import { Banlist } from './banlist.model';
import { findById, insert, remove, update } from './banlist.service';
import { ReplyIcon } from '@heroicons/react/outline';
import useMedia from 'use-media';

interface LocationState {
  id: string | undefined;
}

const FormBanlist: React.FC = () => {
  const isSmall = useMedia({ maxWidth: 480 });
  const location = useLocation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<Banlist>();

  const fieldId = watch('id');

  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState<boolean>(false);

  const onSubmit = async (data: unknown) => {
    const banlist = data as Banlist;
    if (!banlist.id) {
      delete banlist.id;
      await insert(banlist);
      navigate(ROUTES_MAP.cadBanlist);
    } else {
      const ret = await update(banlist);
      loadForm(ret);
    }
  };

  const openModal = () => setModalConfirmIsOpen(true);

  const del = async () => {
    const banlistId = getValues('id');
    if (banlistId) {
      await remove(banlistId);
      navigate(ROUTES_MAP.cadBanlist);
    }
  };

  const loadForm = useCallback(
    (data: Banlist) => {
      const entries = Object.entries(data);
      entries.forEach(entry => {
        switch (entry[0]) {
          default: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue<any>(entry[0], entry[1]);
          }
        }
      });
    },
    [setValue],
  );

  const back = () => {
    navigate(ROUTES_MAP.cadBanlist, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state: { params: (location.state as any)?.['params'] },
    });
  };

  useEffect(() => {
    async function load() {
      const { id } = (location.state as LocationState) || { id: undefined };
      if (id) {
        const banlist = await findById(id);
        if (banlist) {
          loadForm(banlist);
        }
      }
    }
    load();
  }, [loadForm, location.state, setValue]);

  return (
    <div className="md:h-[85vh] px-4 overflow-hidden overflow-y-auto">
      {isSmall && (
        <strong className="text-xl">
          {fieldId ? 'Alterar Número Banido' : 'Inserir Número Banido'}
        </strong>
      )}
      <form
        onSubmit={handleSubmit<Banlist>(onSubmit)}
        className="mx-auto max-w-xl mt-4"
      >
        <div className="flex justify-between my-1">
          <div className="flex space-x-1">
            <Button icon={<ReplyIcon className="w-4" />} onClick={back} />
            {!isSmall && (
              <strong className="text-xl">
                {fieldId ? 'Alterar Número Banido' : 'Inserir Número Banido'}
              </strong>
            )}
          </div>
          <div className="flex space-x-1">
            {fieldId && (
              <Button
                title="Excluir"
                colors="bg-red-500 text-white"
                type="button"
                onClick={openModal}
              />
            )}
            <Button title="Salvar" type="submit" />
          </div>
        </div>
        <input type="hidden" {...register('id')} />
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700"
          >
            Número
          </label>
          <div className="mt-1 relative shadow-sm">
            <input
              type="text"
              id="phone"
              className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="Número"
              {...register('phone', { required: true })}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.phone && '* Campo requerido'}
          </span>
        </div>
      </form>
      <ModalConfirmation
        isOpen={modalConfirmIsOpen}
        setIsOpen={setModalConfirmIsOpen}
        title={'Exclusão'}
        question={'Excluir número: ' + getValues('phone') + '?'}
        answer={del}
      />
    </div>
  );
};

export default FormBanlist;
