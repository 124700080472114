import { BanIcon, CheckCircleIcon, PencilIcon } from '@heroicons/react/outline';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useMedia from 'use-media';
import ROUTES_MAP from '../../../app.map.routes';
import Button from '../../../components/layout/button';
import { User } from './user.model';

type Props = {
  data: User[];
};

const TableUser: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const isSmall = useMedia({ maxWidth: 480 });
  return (
    <div className="">
      <table className="table-auto w-full">
        <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
          <tr className="">
            <th className="p-2 whitespace-nowrap">
              <div className="font-semibold text-left">Nome</div>
            </th>
            {!isSmall && (
              <th className="p-2 whitespace-nowrap">
                <div className="font-semibold text-left">Usuário</div>
              </th>
            )}
            {!isSmall && (
              <th className="p-2 whitespace-nowrap">
                <div className="font-semibold text-left">Pefil</div>
              </th>
            )}
            {!isSmall && (
              <th className="text-center">
                <div className="font-semibold text-left">Ativo</div>
              </th>
            )}
            <th className="text-right"></th>
          </tr>
        </thead>
        <tbody className="text-sm divide-y divide-gray-100">
          {props.data.map(data => (
            <tr key={data.id}>
              <td className="p-2 whitespace-nowrap">
                <div className="flex items-center">
                  <div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                    {data.avatar ? (
                      <img
                        className="rounded-full"
                        src={`${process.env.REACT_APP_API_URL + data.avatar}`}
                        width="40"
                        height="40"
                        alt="Alex Shatov"
                      />
                    ) : (
                      <div className="w-10 h-10 bg-slate-300 flex justify-center items-center rounded-full">
                        <span className="font-bold text-[2.5em] text-slate-700">
                          {data.user.substring(0, 1).toUpperCase()}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="font-medium text-gray-800">{data.name}</div>
                </div>
              </td>
              {!isSmall && (
                <td className="p-2 whitespace-nowrap">
                  <div className="text-left">{data.user.toUpperCase()}</div>
                </td>
              )}
              {!isSmall && (
                <td className="p-2 whitespace-nowrap">
                  <div className="text-left">{data.role}</div>
                </td>
              )}
              {!isSmall && (
                <td className="p-2 whitespace-nowrap">
                  <div className="flex justify-start">
                    {data.isActive ? (
                      <CheckCircleIcon className="w-4 text-green-500" />
                    ) : (
                      <BanIcon className="w-4 text-red-500" />
                    )}
                  </div>
                </td>
              )}
              <td className="p-2 whitespace-nowrap">
                <div className="flex justify-end">
                  <Button
                    icon={<PencilIcon className="w-4" />}
                    onClick={() =>
                      navigate(ROUTES_MAP.cadUserEdit, {
                        state: { id: data.id },
                      })
                    }
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableUser;
