/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../components/layout/button';
import { read, write } from './config.service';

export function CompanyData(): JSX.Element {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange', criteriaMode: 'all' });

  useEffect(() => {
    read().then(data => {
      const entries = Object.entries(data);
      entries.forEach(entry => {
        switch (entry[0]) {
          default: {
            setValue(entry[0], entry[1]);
          }
        }
      });
    });
  }, [setValue]);

  const onSubmit = async (data: unknown) => {
    console.log('🚀 ~ file: companyData.tsx ~ line 32 ~ onSubmit ~ data', data);
    write(data as Record<string, any>);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between my-1">
        <strong className="text-xl font-bold">Empresa</strong>
        <Button title="Salvar" type="submit" />
      </div>
      {/* Nome/Razão */}
      <div className="flex flex-col max-w-xl">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          Nome/Razão
        </label>
        <div className="mt-1 relative shadow-sm">
          <input
            type="text"
            id="name"
            className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Nome/Razão"
            {...register('name', { required: true })}
          />
        </div>
        <span className="text-xs text-red-500">
          {errors.name && '* Campo requerido'}
        </span>
      </div>

      {/* Apelido/Fantasia */}
      <div className="flex flex-col max-w-xl">
        <label
          htmlFor="nickname"
          className="block text-sm font-medium text-gray-700"
        >
          Apelido/Fantasia
        </label>
        <div className="mt-1 relative shadow-sm">
          <input
            type="text"
            id="nickname"
            className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Apelido/Fantasia"
            {...register('nickname', { required: true })}
          />
        </div>
        <span className="text-xs text-red-500">
          {errors.nickname && '* Campo requerido'}
        </span>
      </div>

      {/* E-mail */}
      <div className="flex flex-col max-w-xl">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          E-mail
        </label>
        <div className="mt-1 relative shadow-sm">
          <input
            type="email"
            id="email"
            className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="E-mail"
            {...register('email', { required: true })}
          />
        </div>
        <span className="text-xs text-red-500">
          {errors.email && '* Campo requerido'}
        </span>
      </div>

      {/* CPF/CNPJ */}
      <div className="flex flex-col max-w-xl">
        <label
          htmlFor="cnpj_cpf"
          className="block text-sm font-medium text-gray-700"
        >
          CPF/CNPJ
        </label>
        <div className="mt-1 relative shadow-sm">
          <input
            type="text"
            id="cnpj_cpf"
            className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="CPF/CNPJ"
            {...register('cnpj_cpf', { required: true })}
          />
        </div>
        <span className="text-xs text-red-500">
          {errors.cnpj_cpf && '* Campo requerido'}
        </span>
      </div>
      {/* Telefone */}
      <div className="flex flex-col max-w-xl">
        <label
          htmlFor="phone"
          className="block text-sm font-medium text-gray-700"
        >
          Telefone
        </label>
        <div className="mt-1 relative shadow-sm">
          <input
            type="text"
            id="phone"
            className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Telefone"
            {...register('phone', { required: true })}
          />
        </div>
        <span className="text-xs text-red-500">
          {errors.phone && '* Campo requerido'}
        </span>
      </div>

      {/* Endereço */}
      <div className="flex flex-col max-w-xl">
        <label
          htmlFor="address"
          className="block text-sm font-medium text-gray-700"
        >
          Endereço
        </label>
        <div className="mt-1 relative shadow-sm">
          <input
            type="text"
            id="address"
            className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Endereço"
            {...register('address', { required: true })}
          />
        </div>
        <span className="text-xs text-red-500">
          {errors.address && '* Campo requerido'}
        </span>
      </div>
      {/* Endereço */}
      <div className="flex flex-col max-w-xl">
        <label
          htmlFor="baseUrl"
          className="block text-sm font-medium text-gray-700"
        >
          Dóminio/URL - link de avaliação
        </label>
        <div className="mt-1 relative shadow-sm">
          <input
            type="text"
            id="baseUrl"
            className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
            placeholder="Endereço"
            {...register('baseUrl', { required: true })}
          />
        </div>
        <span className="text-xs text-red-500">
          {errors.baseUrl && '* Campo requerido'}
        </span>
      </div>
    </form>
  );
}
