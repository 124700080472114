import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import Button from '../../components/layout/button';
import { useAuth } from '../../hooks/auth.hook';
import { useSocket } from '../../hooks/socket.context';
import { User } from '../cad/user/user.model';
import { list } from '../cad/user/user.service';

interface ModalComunicationSendProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export default function ModalComunicationSend(
  props: ModalComunicationSendProps,
) {
  const { sendComunication } = useSocket();
  const { getJwt } = useAuth();

  const [comunicationText, setComunicationText] = useState('');
  const [userId, setUserId] = useState<string>();
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    list().then(setUsers);
  }, []);

  function closeModal() {
    props.setIsOpen(false);
  }

  function send() {
    if (!userId) {
      toast.warn('Por favor, selecione um destinatário.');
      return;
    }

    if (comunicationText.length < 2) {
      toast.warn('Por favor, preencha o campo de mensagem.');
      return;
    }

    const payload: Record<string, string> = {
      emitter: getJwt()?.id || '',
      receiver: userId,
      message: comunicationText,
    };
    sendComunication(payload);

    setUserId('');
    setComunicationText('');
    closeModal();
    toast.success('Mensagem enviada com sucesso!');
  }

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={() => null}
      open={props.isOpen}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
          >
            <span className="text-lg font-bold leading-6 font-display">
              Enviar mensagem
            </span>
            <button onClick={closeModal} className="hover:text-gray-400">
              <XIcon className="w-5 h-5" />
            </button>
          </Dialog.Title>
          <div className="flex flex-col font-display py-4 px-6 space-y-2">
            <div className="flex flex-col md:flex-row md:justify-between">
              <span>Destinatário:</span>
              <select value={userId} onChange={e => setUserId(e.target.value)}>
                <option value={''}>Selecione um destinatário</option>
                {users.map(user => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>
            <ReactTextareaAutosize
              value={comunicationText}
              onChange={e => setComunicationText(e.target.value)}
              minRows={9}
              maxRows={9}
              className="w-full p-2 rounded-xl bg-slate-50"
            />
            <div className="flex justify-between">
              <Button
                title="Fechar"
                type="button"
                onClick={closeModal}
                colors="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
              />

              <Button
                title="Enviar"
                type="button"
                colors="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                onClick={send}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
