import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ROUTES_MAP from '../app.map.routes';
import Layout from '../components/layout';
import Dashboard from './dashboard';
import CadDepartment from './cad/department';
import MyProfile from './myProfile';
import CadMenu from './cad';
import NotFound from './NotFound';
import CadUser from './cad/user';
import FormUser from './cad/user/formUser';
import 'react-toastify/dist/ReactToastify.css';
import FormDepartment from './cad/department/formDepartment';
import Initial from './Initial';
import { useAuth } from '../hooks/auth.hook';
import CadPredefinedMessage from './cad/predefinedMessage';
import FormPredefinedMessage from './cad/predefinedMessage/formPredefinedMessage';
import Config from './config';
import CadBanlist from './cad/banlist';
import FormBanlist from './cad/banlist/formBanlist';
import AppCad from './cad/cad';
import { SocketProvider } from '../hooks/socket.context';
import Unauthorized from './Unauthorized';
import AppChats from './chat';
import AppChat from './chat/chat';
import CadRepository from './cad/repository';
import FormRepository from './cad/repository/formRepository';
import AppMonitor from './monitor';
import RelatMenu from './relat';
import AppRelat from './relat/relat';
import RelatAttendence from './relat/attendance';
import RelatProtocol from './relat/protocol';
import Rating from './Rating';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    return (
      <Navigate to={ROUTES_MAP.login} state={{ from: location }} replace />
    );
  }

  return children;
};

const RequireAdminAuthorization = ({ children }: { children: JSX.Element }) => {
  const { role } = useAuth();
  const location = useLocation();

  if (role !== 'admin') {
    return (
      <Navigate
        to={ROUTES_MAP.unauthorized}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
};

const App: React.FC = () => {
  return (
    <div className="font-display">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          {/* ROTAS PUBLICAS */}
          <Route path={ROUTES_MAP.login} element={<Initial />} />
          <Route path={ROUTES_MAP.rating} element={<Rating />} />
          {/* ROTAS PRIVADAS */}
          <Route
            element={
              <SocketProvider>
                <RequireAuth>
                  <Layout />
                </RequireAuth>
              </SocketProvider>
            }
          >
            {/* dasboard */}
            <Route index element={<Dashboard />} />
            {/* cad */}
            <Route path={ROUTES_MAP.cad} element={<CadMenu />}>
              {/* cadastro usuarios */}
              <Route
                path={ROUTES_MAP.cadUser}
                element={
                  <RequireAdminAuthorization>
                    <CadUser />
                  </RequireAdminAuthorization>
                }
              />
              <Route
                path={ROUTES_MAP.cadUserNew}
                element={
                  <RequireAdminAuthorization>
                    <FormUser />
                  </RequireAdminAuthorization>
                }
              />
              <Route
                path={ROUTES_MAP.cadUserEdit}
                element={
                  <RequireAdminAuthorization>
                    <FormUser />
                  </RequireAdminAuthorization>
                }
              />
              {/* cadastro departamentos */}
              <Route
                path={ROUTES_MAP.cadDepartment}
                element={
                  <RequireAdminAuthorization>
                    <CadDepartment />
                  </RequireAdminAuthorization>
                }
              />
              <Route
                path={ROUTES_MAP.cadDepartmentNew}
                element={
                  <RequireAdminAuthorization>
                    <FormDepartment />
                  </RequireAdminAuthorization>
                }
              />
              <Route
                path={ROUTES_MAP.cadDepartmentEdit}
                element={
                  <RequireAdminAuthorization>
                    <FormDepartment />
                  </RequireAdminAuthorization>
                }
              />
              {/* cadastro mensagens predefinidas */}
              <Route
                path={ROUTES_MAP.cadPredefinedMessage}
                element={<CadPredefinedMessage />}
              />
              <Route
                path={ROUTES_MAP.cadPredefinedMessageNew}
                element={<FormPredefinedMessage />}
              />
              <Route
                path={ROUTES_MAP.cadPredefinedMessageEdit}
                element={<FormPredefinedMessage />}
              />
              {/* cadastro banidos */}
              <Route
                path={ROUTES_MAP.cadBanlist}
                element={
                  <RequireAdminAuthorization>
                    <CadBanlist />
                  </RequireAdminAuthorization>
                }
              />
              <Route
                path={ROUTES_MAP.cadBanlistNew}
                element={
                  <RequireAdminAuthorization>
                    <FormBanlist />
                  </RequireAdminAuthorization>
                }
              />
              <Route
                path={ROUTES_MAP.cadBanlistEdit}
                element={
                  <RequireAdminAuthorization>
                    <FormBanlist />
                  </RequireAdminAuthorization>
                }
              />
              {/* rota default */}
              <Route index element={<AppCad />} />
            </Route>
            {/* chats */}
            <Route path={ROUTES_MAP.chats} element={<AppChats />} />
            <Route path={ROUTES_MAP.chatHandler} element={<AppChat />} />
            {/* repository */}
            <Route path={ROUTES_MAP.repository} element={<CadRepository />} />
            <Route
              path={ROUTES_MAP.repositoryNew}
              element={<FormRepository />}
            />
            <Route
              path={ROUTES_MAP.repositoryEdit}
              element={<FormRepository />}
            />
            {/* monitor */}
            <Route
              path={ROUTES_MAP.monitor}
              element={
                <RequireAdminAuthorization>
                  <AppMonitor />
                </RequireAdminAuthorization>
              }
            />
            {/* relat */}
            <Route
              path={ROUTES_MAP.relat}
              element={
                <RequireAdminAuthorization>
                  <RelatMenu />
                </RequireAdminAuthorization>
              }
            >
              {/* relatorio de atendimento */}
              <Route
                path={ROUTES_MAP.relatAttendance}
                element={<RelatAttendence />}
              />
              {/* relatorio de protocolo */}
              <Route
                path={ROUTES_MAP.relatProtocol}
                element={<RelatProtocol />}
              />
              {/* rota default */}
              <Route index element={<AppRelat />} />
            </Route>
            {/* my profile */}
            <Route path={ROUTES_MAP.myProfile} element={<MyProfile />} />
            {/* config */}
            <Route
              path={ROUTES_MAP.config}
              element={
                <RequireAdminAuthorization>
                  <Config />
                </RequireAdminAuthorization>
              }
            />
            {/* unauthorized */}
            <Route path={ROUTES_MAP.unauthorized} element={<Unauthorized />} />
            {/* not found */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
