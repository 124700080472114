import { Dialog } from '@headlessui/react';
import { PlusIcon, TrashIcon, XIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { WEEKS_DAY } from '../../app.settings';
import Button from '../../components/layout/button';
import SelectFilter from '../../components/selectFilter';
import { OpeningHours } from './config.model';
import { readGeneral, writeGeneral } from './config.service';

export function ConfigOpeningHours(): JSX.Element {
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [weekDays, setWeekDays] = useState<number[]>([]);

  const [openingHours, setOpeningHours] = useState<OpeningHours[]>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    readGeneral().then(data => {
      setOpeningHours(data.openingHours);
    });
  }, []);

  const insert = () => {
    setOpeningHours(state => [...state, { start, end, weekDays }]);
    setOpenModal(false);
  };

  const remove = (index: number) => {
    setOpeningHours(state => state.filter((_, i) => i !== index));
  };

  const submit = () => {
    writeGeneral({ openingHours });
  };

  return (
    <div>
      <div className="flex flex-col justify-center md:flex-row md:justify-between my-1">
        <strong className="text-xl font-bold">Horário de Atendimento</strong>
        <div className="flex space-x-2">
          <Button
            icon={<PlusIcon className="w-6 h-6" />}
            type="button"
            onClick={() => setOpenModal(true)}
          />
          <Button title="Salvar" type="button" onClick={submit} />
        </div>
      </div>

      <h3>Horários de Funcionamento</h3>
      {openingHours.length ? (
        <div className="flex flex-col space-y-2 md:grid md:grid-cols-3 md:gap-2 md:space-y-0">
          {openingHours.map((openingHours, i) => (
            <div
              key={i}
              className="flex flex-col bg-slate-300 rounded-lg p-2 text-xs"
            >
              <div className="flex items-center space-x-2">
                <strong>Dias</strong>
                <span>
                  {openingHours.weekDays
                    .reduce<string>((acc, cur) => {
                      const day = WEEKS_DAY[cur];
                      if (day) {
                        acc = acc + day + ', ';
                      }
                      return acc;
                    }, '')
                    .replace(/(,\s)$/, '')}
                </span>
              </div>
              <div className="flex justify-between">
                <div className="flex items-center space-x-2 text-md">
                  <strong>Início:</strong>
                  <span>{openingHours.start}</span>
                </div>
                <div className="flex items-center space-x-2 text-md">
                  <strong>Final:</strong>
                  <span>{openingHours.end}</span>
                </div>
                <button
                  className="hover:text-red-500"
                  onClick={() => remove(i)}
                >
                  <TrashIcon className="w-6 h-6" />
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center text-gray-400 h-full space-y-4">
          <strong>Nenhum horário de atendimento cadastrado.</strong>
          <strong>
            Será informado aos atendimentos a ausência de operadores online no
            momento!
          </strong>
        </div>
      )}
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => setOpenModal(false)}
        open={openModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="h3"
              className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
            >
              <span className="text-lg font-bold leading-6 font-display">
                Inserir novo horário
              </span>
              <button
                onClick={() => setOpenModal(false)}
                className="hover:text-gray-400"
              >
                <XIcon className="w-5 h-5" />
              </button>
            </Dialog.Title>
            <div className="flex flex-col justify-between font-display py-4 px-6 h-[50vh]">
              <div className="bg-gray-100 flex flex-col md:flex-row md:justify-between md:items-center p-2 md:space-x-3 space-y-3 md:space-y-0">
                <div className="w-full">
                  <SelectFilter
                    filters={WEEKS_DAY.map((d, i) => ({
                      display: d,
                      value: i,
                    }))}
                    placeholder={'Dias'}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    action={(
                      filters: {
                        display: string;
                        value: number;
                      }[],
                    ) =>
                      setWeekDays(
                        filters.reduce<number[]>((acc, cur) => {
                          if (cur) {
                            acc.push(cur as unknown as number);
                          }
                          return acc;
                        }, []),
                      )
                    }
                  />
                </div>
                <div className="flex justify-between md:justify-start md:items-center space-x-2">
                  <strong>Início</strong>
                  <input
                    type="time"
                    id="start"
                    className="form-input px-3 py-2 w-full max-w-min rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                    placeholder="Inicio"
                    value={start}
                    onChange={e => setStart(e.target.value)}
                  />
                </div>
                <div className="flex justify-between md:justify-start md:items-center space-x-2">
                  <strong>Final</strong>
                  <input
                    type="time"
                    id="end"
                    className="form-input px-3 py-2 w-full max-w-min rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                    placeholder="Inicio"
                    value={end}
                    onChange={e => setEnd(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <Button title="Inserir" onClick={insert} type="button" />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
