/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { getFormatedDate } from '../../utils/datetimeHandler';
import { Chat } from '../chat/chat.model';
import { getSatisfaction, secondsToMinutes } from '../../utils/functions';

// @ts-ignore: Unreachable code error
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// @ts-ignore: Unreachable code error
pdfMake.fonts = {
  Roboto: {
    normal:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

function customHeader(
  currentPage: number,
  pageCount: number,
  pageSize: { width: number },
  title: string,
): Content {
  return [
    {
      text: title,
      alignment: currentPage % 2 ? 'left' : 'right',
      style: 'header',
    },
    {
      canvas: [
        {
          type: 'line',
          x1: 30,
          y1: 0,
          x2: pageSize.width - 30,
          y2: 0,
          lineWidth: 1,
        },
      ],
    },
  ];
}

function customFooter(
  currentPage: number,
  pageCount: number,
  pageSize: any,
  user: string | null,
  period: string[] | null,
  filters: Record<string, any[]> | null,
): Content {
  const _filters = Object.entries(filters || {}) || [];
  const _filtersText = _filters.reduce<string[]>(
    (acc: string[], [key, value]) => {
      if (value.length > 0) {
        acc.push(`${key}: ${value.toString()}`);
      }
      return acc;
    },
    [],
  );
  return [
    {
      canvas: [
        {
          type: 'line',
          x1: 30,
          y1: 0,
          x2: pageSize.width - 30,
          y2: 0,
          lineWidth: 1,
        },
      ],
    },
    {
      columns: [
        {
          stack: [
            {
              text: `Página ${currentPage.toString()} de ${pageCount.toString()}`,
              style: 'footer',
            },
            {
              text: `Usuário: ${user}`,
              style: 'footer',
            },
            {
              text: `Gerado em ${getFormatedDate(new Date().toString())}`,
              style: 'footer',
            },
            {
              text: period
                ? `Período: ${moment(period[0]).format(
                    'DD/MM/YYYY',
                  )} até ${moment(period[1]).format('DD/MM/YYYY')}`
                : '',
              style: 'footer',
            },

            {
              text: _filtersText ? `Filtros: ${_filtersText.toString()}` : '',
              style: 'footer',
            },
          ],
        },
      ],
    },
  ];
}

export function generateAttendancePdf(
  chats: Chat[],
  period: string[] | null,
  filters: Record<string, any[]>,
  user: string,
) {
  // table
  const body = [
    [
      'Data',
      'Protocolo',
      'Satisfação',
      'Celular',
      'Atendente',
      'Espera',
      'Tempo',
      'Avaliado',
      'Obs.',
    ],
  ];

  chats.forEach(chat => {
    body.push([
      moment(chat.createdAt).format('DD/MM/YYYY'),
      chat.protocol,
      getSatisfaction(chat.satisfaction),
      chat.phone,
      chat.user.name,
      secondsToMinutes(
        moment(chat.attendedAt).diff(moment(chat.openedAt), 'seconds'),
      ),
      secondsToMinutes(
        moment(chat.finishedAt).diff(moment(chat.attendedAt), 'seconds'),
      ),
      chat.evaluated ? 'Sim' : 'Não',
      chat.obs ? 'Sim' : 'Não',
    ]);
  });

  const dd: TDocumentDefinitions = {
    defaultStyle: {
      font: 'Roboto',
    },

    pageSize: 'A4',

    pageOrientation: 'landscape',

    pageMargins: [30, 40, 30, 60],

    header: (currentPage, pageCount, pageSize) =>
      customHeader(
        currentPage,
        pageCount,
        pageSize,
        'Relatório de Atendimentos',
      ),

    footer: (currentPage, pageCount, pageSize) =>
      customFooter(currentPage, pageCount, pageSize, user, period, filters),

    content: [
      {
        table: {
          layout: 'lightHorizontalLines',
          widths: [50, 60, 'auto', '*', '*', 'auto', 'auto', 'auto', 'auto'],
          headerRows: 1,
          body,
        },
        layout: {
          fillColor: function (rowIndex) {
            return rowIndex === 0 ? '#CCCCCC' : null;
          },
        },
        style: {
          fontSize: 8,
        },
      },
    ],
    styles: {
      header: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
        margin: [30, 20, 30, 0],
      },
      footer: {
        fontSize: 8,
        bold: false,
        alignment: 'left',
        margin: [30, 0, 30, 0],
      },
    },
  };
  const doc = pdfMake.createPdf(dd);
  doc.open();
}

export function generateProtocolPdf(chat: Chat, user: string) {
  const dd: TDocumentDefinitions = {
    defaultStyle: {
      font: 'Roboto',
    },

    pageSize: 'A4',

    pageOrientation: 'portrait',

    pageMargins: [30, 40, 30, 60],

    header: (currentPage, pageCount, pageSize) =>
      customHeader(currentPage, pageCount, pageSize, 'Análise de Atendimento'),

    footer: (currentPage, pageCount, pageSize) =>
      customFooter(currentPage, pageCount, pageSize, user, null, null),

    content: [
      {
        stack: [
          {
            alignment: 'justify',
            columns: [
              {
                text: 'Protocolo:',
                bold: true,
              },
              {
                text: chat.protocol,
                bold: true,
              },
            ],
          },
          {
            alignment: 'justify',
            columns: [
              {
                text: 'Data:',
                bold: true,
              },
              {
                text: moment(chat.createdAt).format('DD/MM/YYYY'),
                bold: true,
              },
            ],
          },
          {
            alignment: 'justify',
            columns: [
              {
                text: 'Hora:',
                bold: true,
              },
              {
                text: moment(chat.createdAt).format('HH:MM'),
                bold: true,
              },
            ],
          },
          {
            alignment: 'justify',
            columns: [
              {
                text: 'Celular:',
                bold: true,
              },
              {
                text: chat.phone,
                bold: true,
              },
            ],
          },
          {
            alignment: 'justify',
            columns: [
              {
                text: 'Nome:',
                bold: true,
              },
              {
                text: chat.chatName || 'Não informado',
                bold: true,
              },
            ],
          },
          {
            alignment: 'justify',
            columns: [
              {
                text: 'Departamento:',
                bold: true,
              },
              {
                text: chat.department?.desc,
                bold: true,
              },
            ],
          },
          {
            alignment: 'justify',
            columns: [
              {
                text: 'Tempo de Atendimento:',
                bold: true,
              },
              {
                text: secondsToMinutes(
                  moment(chat?.finishedAt).diff(
                    moment(chat?.attendedAt),
                    'seconds',
                  ),
                ),
                bold: true,
              },
            ],
          },
          {
            alignment: 'justify',
            columns: [
              {
                text: 'Tempo de Espera:',
                bold: true,
              },
              {
                text: secondsToMinutes(
                  moment(chat?.attendedAt).diff(
                    moment(chat?.openedAt),
                    'seconds',
                  ),
                ),
                bold: true,
              },
            ],
          },
          {
            alignment: 'justify',
            columns: [
              {
                text: 'Nível de Satisfação:',
                bold: true,
              },
              {
                text: chat?.satisfaction
                  ? getSatisfaction(chat.satisfaction)
                  : 'N/D',
                bold: true,
              },
            ],
          },
          {
            alignment: 'justify',
            columns: [
              {
                text: 'Comentário:',
                bold: true,
              },
              {
                text: chat?.obs || 'Não informado',
                bold: true,
              },
            ],
          },
          {
            text: '\n',
          },
          {
            text: 'Conteudo das Mensagens:',
            bold: true,
          },
          {
            text: '\n',
          },
          chat.messages.map(message => {
            return {
              text:
                message.type === 'Text'
                  ? message.message
                  : `Tipo de mensagem: ${message.type}`,
              style: message.fromMe ? 'messageRight' : 'messageLeft',
            };
          }),
        ],
      },
    ],
    styles: {
      header: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
        margin: [30, 20, 30, 0],
      },
      footer: {
        fontSize: 8,
        bold: false,
        alignment: 'left',
        margin: [30, 0, 30, 0],
      },
      messageLeft: {
        fontSize: 8,
        bold: false,
        alignment: 'left',
        margin: [10, 10, 10, 10],
      },
      messageRight: {
        fontSize: 8,
        bold: false,
        alignment: 'right',
        margin: [10, 10, 10, 10],
      },
    },
  };

  const doc = pdfMake.createPdf(dd);
  doc.open();
}
