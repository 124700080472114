import React from 'react';
import Clock from '../components/clock';

const NotFound: React.FC = () => {
  return (
    <div className="mt-28 md:mt-0">
      <div className="h-screen flex flex-col justify-center items-center">
        <Clock size="100%" />
        <h1 className="text-3xl text-gray-400 font-bold text-center">
          Pagina não encontrada :(
        </h1>
      </div>
    </div>
  );
};

export default NotFound;
