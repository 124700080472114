/* eslint-disable @typescript-eslint/no-explicit-any */
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { classNames } from '../utils/functions';

interface LazyLoadingImageProps {
  src: string;
  alt: string;
  height: number;
  width: number;
  classNames?: unknown[];
  efect?: 'blur' | 'black-and-white' | 'opacity';
}

export default function LazyLoadingImage(props: LazyLoadingImageProps) {
  return (
    <div className={classNames(props.classNames)}>
      {props.efect ? (
        <LazyLoadImage
          alt={props.alt}
          height={props.height}
          src={props.src}
          width={props.width}
          effect={props.efect}
          placeholderSrc="Sem Imagem"
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = 'https://via.placeholder.com/' + props.width;
          }}
        />
      ) : (
        <LazyLoadImage
          alt={props.alt}
          height={props.height}
          src={props.src}
          width={props.width}
          placeholderSrc="Sem Imagem"
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = 'https://via.placeholder.com/' + props.width;
          }}
        />
      )}
    </div>
  );
}
