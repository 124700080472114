export const LOCAL_STORAGE_JWT = '@whatsapp-admin:jwt';
export const LOCAL_STORAGE_RJWT = '@whatsapp-admin:jwt-refresh';
export const LOCAL_STORAGE_USER = '@whatsapp-admin:user';
export const LOCAL_STORAGE_PREFIX = '@whatsapp-admin:';
export const API_URL = process.env.REACT_APP_API_URL || '';
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || '';

export const WEEKS_DAY = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];
