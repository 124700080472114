/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse } from '../../app.models';
import { Chat } from '../chat/chat.model';
import api from '../../axios';
import { showErrors } from '../../utils/functions';
import { Repository } from '../cad/repository/repository.model';

export const feed = (ref: React.RefObject<HTMLDivElement>) => {
  setTimeout(() => {
    ref.current?.scrollTo({
      top: ref.current.scrollHeight,
      behavior: 'smooth',
    });
  }, 150);
};

export async function search(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: Record<string, any>,
): Promise<Chat[]> {
  try {
    const url = `/chat/find`;
    const { data } = await api.post<ApiResponse<Chat[]>>(url, fields);
    return data.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function getById(id: string): Promise<Chat> {
  try {
    const url = '/chat/' + id;
    const { data } = await api.get<ApiResponse<Chat>>(url);
    return data.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function getRating(id: string): Promise<Record<string, any>> {
  try {
    const url = '/rating/' + id;
    const { data } = await api.get<ApiResponse<Record<string, any>>>(url);
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function create(phone: string, department: string): Promise<Chat> {
  try {
    const url = '/chat';
    const { data } = await api.post<Chat>(url, { phone, department });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function update(chat: Chat): Promise<Chat> {
  try {
    const url = '/chat';
    const { data } = await api.put<Chat>(url, chat);
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function setRating(
  chat: Record<string, any>,
): Promise<Record<string, any>> {
  try {
    const url = '/rating';
    const { data } = await api.put<Record<string, any>>(url, chat);
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function remove(chatId: string): Promise<boolean> {
  try {
    const url = '/chat/' + chatId;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { data } = await api.delete<any>(url);
    if (data) {
      return true;
    }
    return false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function sendAudioBase64(audio64: string): Promise<Repository> {
  try {
    const url = '/repository/audioBase64';
    const { data } = await api.post<ApiResponse<Repository>>(url, {
      audio64,
    });
    return data.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}
