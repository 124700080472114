/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { ApiResponse } from '../../../app.models';

import api from '../../../axios';
import { showErrors } from '../../../utils/functions';
import { Department } from './department.model';

export async function list(): Promise<Department[]> {
  try {
    const url = '/departments/all';
    const { data } = await api.get<ApiResponse<Department[]>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function findById(id: string): Promise<Department | null> {
  try {
    const url = '/departments/' + id;
    const { data } = await api.get<ApiResponse<Department>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function insert(department: Department): Promise<Department> {
  try {
    const url = '/departments';
    const { data } = await api.post<ApiResponse<Department>>(url, department);
    toast.success('Departamento cadastrado com sucesso!');
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function update(department: Department): Promise<Department> {
  try {
    const url = '/departments';
    const { data } = await api.put<Department>(url, department);
    toast.success('Departamento atualizado com sucesso!');
    return data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function remove(departmentId: string): Promise<boolean> {
  try {
    const url = '/departments/' + departmentId;
    const { data } = await api.delete<any>(url);
    if (data.affected > 0) {
      toast.success('Departamento removido com sucesso!');
      return true;
    }
    return false;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function addUser(
  departmentId: string,
  userId: string,
): Promise<Department> {
  try {
    const url = '/departments/add-user';
    const { data } = await api.post<ApiResponse<Department>>(url, {
      departmentId,
      userId,
    });
    toast.success('Departamento atualizado com sucesso!');
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function addTopic(
  department: string,
  desc: string,
): Promise<Department> {
  try {
    const url = '/departments/add-topic';
    const { data } = await api.post<ApiResponse<Department>>(url, {
      department,
      desc,
    });
    toast.success('Departamento atualizado com sucesso!');
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function delUser(
  departmentId: string,
  userId: string,
): Promise<Department> {
  try {
    const url = '/departments/del-user/' + departmentId + '/' + userId;
    const { data } = await api.delete<any>(url);
    toast.success('Usuário do departamento removido com sucesso!');
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function delTopic(
  departmentId: string,
  topicId: string,
): Promise<Department> {
  try {
    const url = '/departments/del-topic/' + departmentId + '/' + topicId;
    const { data } = await api.delete<any>(url);
    toast.success('Típico removido com sucesso!');
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}
