/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { ApiResponse, SearchResult } from '../../../app.models';

import api from '../../../axios';
import { PredefinedMessage } from './predefinedMessage.model';

export async function list(): Promise<PredefinedMessage[]> {
  try {
    const url = '/predefined_messages/all';
    const { data } = await api.get<ApiResponse<PredefinedMessage[]>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function search(
  field: Record<string, any>,
  page?: number,
  limit?: number,
): Promise<SearchResult<PredefinedMessage>> {
  try {
    const url = `/predefined_messages/find?page=${page}&limit=${limit}`;
    const { data } = await api.post<ApiResponse<PredefinedMessage[]>>(
      url,
      field,
    );
    return data.data as unknown as SearchResult<PredefinedMessage>;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function findById(id: string): Promise<PredefinedMessage | null> {
  try {
    const url = '/predefined_messages/' + id;
    const { data } = await api.get<ApiResponse<PredefinedMessage>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function insert(
  predefinedMessage: PredefinedMessage,
): Promise<PredefinedMessage> {
  try {
    const url = '/predefined_messages';
    const { data } = await api.post<ApiResponse<PredefinedMessage>>(
      url,
      predefinedMessage,
    );
    toast.success('Departamento cadastrado com sucesso!');
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function update(
  predefinedMessage: PredefinedMessage,
): Promise<PredefinedMessage> {
  try {
    const url = '/predefined_messages';
    const { data } = await api.put<PredefinedMessage>(url, predefinedMessage);
    toast.success('Departamento atualizado com sucesso!');
    return data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function remove(predefinedMessageId: string): Promise<boolean> {
  try {
    const url = '/predefined_messages/' + predefinedMessageId;
    const { data } = await api.delete<any>(url);
    if (data) {
      toast.success('Departamento removido com sucesso!');
      return true;
    }
    return false;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

function showErrors(errors: string | string[]): void {
  if (Array.isArray(errors)) {
    errors.map((error: string) => toast.error(error));
  } else {
    toast.error(errors);
  }
}
