import { useContext, createContext, useEffect, useState } from 'react';
import socketio, { Socket } from 'socket.io-client';
import { Comunication } from '../app.models';
import { Chat, Message } from '../pages/chat/chat.model';
import { readInstances } from '../pages/config/config.service';

export interface IInstance {
  id: string;
  token: string;
  connected: boolean;
  isDefaut: boolean;
  phone: string;
}

export interface IContext {
  socket: Socket;
  connectedClients: number;
  instances: IInstance[];
  getStatus: (instance: string) => void;
  chats: Chat[];
  getChats: () => void;
  sendMessage: (message: Message) => void;
  sendImage: (message: Message) => void;
  sendDocument: (message: Message) => void;
  sendAudio: (message: Message) => void;
  sendVideo: (message: Message) => void;
  deleteChatByPhone: (phone: string) => void;
  transferChat: (
    currentUser: string,
    chatId: string,
    newDepartment: string,
    newUser: string | null,
  ) => void;
  attendChat: (
    currentUserId: string,
    currentUserName: string,
    chatId: string,
    department: string | undefined,
  ) => void;
  finishChat: (currentUser: string, chatId: string) => void;
  comunications: Comunication[];
  toggleComunicationStatus: (comunicationId: string) => void;
  toggleComunicationArchived: (comunicationId: string) => void;
  sendComunication: (payload: Record<string, string>) => void;
}

interface IProvider {
  children: React.ReactNode;
}
const Context = createContext<IContext>({} as IContext);

const SocketProvider: React.FC<IProvider> = ({ children }: IProvider) => {
  const [socket, setSocket] = useState<Socket>();
  const [connectedClients, setConnectedClients] = useState(0);
  const [chats, setChats] = useState<Chat[]>([]);
  const [comunications, setComunications] = useState<Comunication[]>([]);
  const [instances, setInstances] = useState<IInstance[]>([]);

  useEffect(() => {
    const _socket = socketio(`${process.env.REACT_APP_SOCKET_URL}`, {
      transports: ['websocket'],
    });

    setSocket(_socket);
    _socket.on('connected:clients', setConnectedClients);
    _socket.on('chats:all', setChats);
    _socket.on('comunication:all', setComunications);
    _socket.on('instance:all', (newInstances: IInstance[]) =>
      setInstances(state => {
        if (JSON.stringify(newInstances) !== JSON.stringify(state)) {
          newInstances.forEach(instance => {
            _socket.emit('instance:status', instance);
          });
        }
        return newInstances;
      }),
    );

    return () => {
      _socket.close();
    };
  }, []);

  useEffect(() => {
    readInstances().then(data => setInstances(data.instances as IInstance[]));
  }, []);

  const getStatus = (instance: string) => {
    if (socket) socket.emit('instance:status', instance);
  };

  const getChats = () => {
    if (socket) socket.emit('chats:load');
  };

  const deleteChatByPhone = (phone: string) => {
    if (socket) socket.emit('chats:delete:phone', { phone });
  };

  const sendMessage = (message: Message) => {
    if (socket) socket.emit('chats:message:send', message);
  };

  const sendImage = (message: Message) => {
    if (socket) socket.emit('chats:image:send', message);
  };

  const sendDocument = (message: Message) => {
    if (socket) socket.emit('chats:document:send', message);
  };

  const sendAudio = (message: Message) => {
    if (socket) socket.emit('chats:audio:send', message);
  };

  const sendVideo = (message: Message) => {
    if (socket) socket.emit('chats:video:send', message);
  };

  const transferChat = (
    currentUser: string,
    chatId: string,
    newDepartment: string,
    newUser: string | null,
  ) => {
    if (socket) {
      socket.emit('chats:tranfer', {
        currentUserName: currentUser,
        chatId,
        newDepartment,
        newUser,
      });
    }
  };

  const attendChat = (
    currentUserId: string,
    currentUserName: string,
    chatId: string,
    department: string | undefined,
  ) => {
    if (socket) {
      socket.emit('chats:attend', {
        currentUserId,
        currentUserName,
        chatId,
        department,
      });
    }
  };

  const finishChat = (currentUser: string, chatId: string) => {
    if (socket) {
      socket.emit('chats:finish', {
        currentUserName: currentUser,
        chatId,
      });
    }
  };

  const toggleComunicationStatus = (comunicationId: string) => {
    const payload = {
      id: comunicationId,
    };
    if (socket) {
      socket.emit('comunication:status', payload);
    }
  };

  const toggleComunicationArchived = (comunicationId: string) => {
    const payload = {
      id: comunicationId,
    };
    if (socket) {
      socket.emit('comunication:achive', payload);
    }
  };

  const sendComunication = (payload: Record<string, string>) => {
    if (socket) {
      socket.emit('comunication:create', payload);
    }
  };

  if (!socket) return null;

  return (
    <Context.Provider
      value={{
        socket,
        connectedClients,
        instances,
        getStatus,
        chats,
        getChats,
        deleteChatByPhone,
        sendMessage,
        sendImage,
        sendAudio,
        sendVideo,
        sendDocument,
        transferChat,
        attendChat,
        finishChat,
        comunications,
        toggleComunicationStatus,
        toggleComunicationArchived,
        sendComunication,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useSocket = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { useSocket, SocketProvider };
