import React, { useEffect, useRef, useState } from 'react';
import { Chat } from '../chat/chat.model';
import { classNames } from '../../utils/functions';
import moment from 'moment';
import {
  ChatAlt2Icon,
  ChatIcon,
  ClockIcon,
  DotsCircleHorizontalIcon,
  PhoneOutgoingIcon,
} from '@heroicons/react/outline';
import ModalConfirmationBan from '../../components/modalConfirmation';
import ModalConfirmationReject from '../../components/modalConfirmation';
import { insert as insertBan } from '../cad/banlist/banlist.service';
import { useSocket } from '../../hooks/socket.context';
import ChatEmptySvg from '../../assets/svg/chat-empty.svg';
import { useNavigate } from 'react-router-dom';
import ROUTES_MAP from '../../app.map.routes';
import { useAuth } from '../../hooks/auth.hook';
import ModalPreview from './modalPreview';
import Button from '../../components/layout/button';
import ModalChatCreate from './modalChatCreate';
import useMedia from 'use-media';

type Props = {
  chats: Chat[];
};

const AppChatList: React.FC<Props> = (props: Props) => {
  const isSmall = useMedia({ maxWidth: 480 });
  const navigate = useNavigate();
  const { deleteChatByPhone, attendChat } = useSocket();
  const { getJwt } = useAuth();

  const [currentUserId, setCurrentUserId] = useState<string>('');
  const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
  const [modalConfirmBanIsOpen, setModalConfirmBanIsOpen] = useState(false);
  const [modalConfirmRejectIsOpen, setModalConfirmRejectIsOpen] =
    useState(false);
  const [modalChatCreateIsOpen, setModalChatCreateIsOpen] = useState(false);

  const chatIdRef = useRef<string>('');
  const selectedChat = useRef<Chat>({} as Chat);

  useEffect(() => {
    setCurrentUserId(getJwt()?.id || '');
  }, [getJwt]);

  const initBan = (num: string) => {
    chatIdRef.current = num;
    setModalConfirmBanIsOpen(true);
  };

  const initReject = (num: string) => {
    chatIdRef.current = num;
    setModalConfirmRejectIsOpen(true);
  };

  const ban = () => {
    const num = chatIdRef.current;
    if (num) {
      insertBan({ phone: num }).then(() => {
        deleteChatByPhone(num);
      });
    }
  };

  const reject = () => {
    const num = chatIdRef.current;
    if (num) {
      deleteChatByPhone(num);
    }
  };

  const handlerChat = (chatId: string) => {
    navigate(ROUTES_MAP.chatHandler, { state: { id: chatId }, replace: true });
  };

  const toMeet = async (chat: Chat) => {
    selectedChat.current = chat;
    setModalPreviewOpen(true);
  };

  const openChat = async (department: string | undefined) => {
    const chat = selectedChat.current;
    const userId = getJwt()?.id;
    const userName = getJwt()?.name;

    if (!chat || !userId || !userName) return;
    attendChat(userId, userName, chat.id, department);

    await new Promise(t => setTimeout(t, 1000));
    handlerChat(chat.id);
  };

  if (props.chats.length === 0) {
    return (
      <>
        <div className="flex justify-end">
          <Button
            icon={<PhoneOutgoingIcon className="w-5 h-5" />}
            title="Iniciar um atendimento"
            onClick={() => setModalChatCreateIsOpen(true)}
          />
        </div>
        <div className="flex flex-col justify-center items-center opacity-10 mt-5">
          <img src={ChatEmptySvg} className="w-[32vw] " />
          <h1 className="text-2xl text-center">
            Bom trabalho! Sem atendimentos!!!
          </h1>
        </div>
        <ModalChatCreate
          isOpen={modalChatCreateIsOpen}
          setIsOpen={setModalChatCreateIsOpen}
          action={chat => handlerChat(chat.id)}
        />
      </>
    );
  }

  return (
    <>
      <div
        className={classNames(
          'flex justify-center md:justify-end',
          isSmall && 'my-4',
        )}
      >
        <Button
          icon={<PhoneOutgoingIcon className="w-5 h-5" />}
          title="Iniciar um atendimento"
          onClick={() => setModalChatCreateIsOpen(true)}
        />
      </div>
      {/* mobile */}
      {isSmall && <div className="text-center">Atendimentos</div>}
      {/* mobile */}
      {isSmall && (
        <div className="flex flex-col space-y-2 mt-2 cursor-pointer">
          {props.chats.map((chat: Chat) => {
            return (
              <button
                key={chat.id}
                className={classNames(
                  'w-[90vw] mx-auto',
                  'px-2 rounded-lg py-2',
                  chat.user?.id !== currentUserId && chat.user
                    ? 'bg-gray-200 text-gray-400'
                    : chat.user
                    ? 'bg-green-200 text-green-800'
                    : chat.department?.id
                    ? 'bg-yellow-200 text-yellow-800'
                    : 'bg-gray-200 text-gray-800',
                  'text-xs',
                )}
                onClick={() =>
                  !chat.user ? toMeet(chat) : handlerChat(chat.id)
                }
              >
                <div className="flex items-center space-x-3">
                  <span className="">
                    {chat.photo ? (
                      <div className="aspect-1 w-10 rounded-full bg-gray-700 ring ring-gray-700 ring-offset-base-100 ring-offset-2 flex flex-col justify-center items-center">
                        <img className="rounded-full" src={chat.photo} />
                      </div>
                    ) : (
                      <div className="aspect-1 w-10 rounded-full bg-gray-700 ring ring-gray-700 ring-offset-base-100 ring-offset-2 flex flex-col justify-center items-center">
                        <span className="text-white font-bold text-2xl">
                          {chat.chatName
                            ? chat.chatName.substring(0, 1)
                            : chat.phone.substring(0, 1)}
                        </span>
                      </div>
                    )}
                  </span>
                  <div className="flex flex-col items-start text-slate-500">
                    <div className="line-clamp-1">
                      <strong>
                        {chat.chatName
                          ? `${chat.phone} - ${chat.chatName}`
                          : chat.phone}
                      </strong>
                    </div>
                    <div className="line-clamp-1">
                      <strong>
                        {chat?.department?.desc || 'Pré-atendimento'}
                      </strong>
                    </div>
                    <div className="line-clamp-1">
                      <strong>Tópico: {chat.topic || 'N/D'}</strong>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <span>
                    Usuário: {chat.user?.user?.toUpperCase() || 'N/D'}
                  </span>
                  <span>Tempo de espera:</span>
                </div>
                <div className="flex justify-between">
                  {/* data/hora */}
                  <span className="col-span-3 flex flex-col justify-center font-bold">
                    <span className="flex justify-start space-x-1">
                      <ClockIcon className="w-4 h-4" />{' '}
                      {chat?.createdAt &&
                        moment(chat.createdAt).format('DD/MM/YYYY HH:mm')}
                    </span>
                  </span>

                  {/* aviso de interação */}
                  <span className="flex flex-col justify-center items-center">
                    {chat.messages[chat.messages.length - 1]?.fromMe ? (
                      <ChatAlt2Icon className="w-6 h-6 text-green-500" />
                    ) : (
                      <ChatAlt2Icon className="w-6 h-6 text-yellow-500 animate-bounce" />
                    )}
                  </span>

                  {/* espera */}
                  <span className="col-span-2 flex items-center space-x-1">
                    <svg
                      className="animate-spin mr-1 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <strong>
                      {moment().diff(
                        moment(
                          chat.messages[chat.messages.length - 1]?.createdAt,
                        ),
                        'minutes',
                      )}
                      m
                    </strong>
                  </span>
                </div>
              </button>
            );
          })}
        </div>
      )}
      {/* web */}
      {!isSmall && (
        <div
          className={classNames(
            'grid grid-cols-24 gap-2 content-center',
            'my-1 py-2 px-1 rounded-lg',
            'text-gray-300',
            'text-xs',
          )}
        >
          {/* ordeanção */}
          <span className="">Ord.</span>

          {/* nome */}
          <span className="col-span-3">Nome</span>

          {/* numero */}
          <span className="col-span-3">Número</span>

          {/* departamento */}
          <span className="col-span-4">Departamento</span>

          {/* topic */}
          <span className="col-span-4">Tópico</span>

          {/* data/hora */}
          <span className="col-span-3">Data/Hora</span>

          {/* aviso de interação */}
          <span className=""></span>

          {/* espera */}
          <div className="col-span-2">Espera</div>

          {/* usuario */}
          <span className="col-span-2">Usuário</span>

          {/* controles */}
          <span className="col-span-1 text-center"></span>
        </div>
      )}
      {/* web */}
      {!isSmall && (
        <div className="mt-2">
          {props.chats.map((chat: Chat, index: number) => {
            return (
              <div
                key={chat.id}
                className={classNames(
                  'grid grid-cols-24 gap-2',
                  'my-1 px-1 rounded-lg py-2',
                  chat.user?.id !== currentUserId && chat.user
                    ? 'bg-gray-200 text-gray-400'
                    : chat.user
                    ? 'bg-green-200 text-green-800'
                    : chat.department?.id
                    ? 'bg-yellow-200 text-yellow-800'
                    : 'bg-gray-200 text-gray-800',
                  'text-xs',
                )}
              >
                {/* ordeanção */}
                <span className="text-sm flex flex-col justify-center font-bold">
                  <span className="flex justify-start space-x-1">
                    # {index + 1}
                  </span>
                </span>

                {/* nome */}
                <span className="col-span-3 flex flex-col justify-center font-bold">
                  <span className="flex justify-start space-x-1">
                    <p className="line-clamp-2">{chat.chatName || 'N/D'}</p>
                  </span>
                </span>

                {/* numero */}
                <span className="col-span-3 flex flex-col justify-center font-bold">
                  <span className="flex justify-start space-x-1">
                    <strong className="line-clamp-2">
                      {chat.phone || 'N/D'}
                    </strong>
                  </span>
                </span>

                {/* departamento */}
                <span
                  style={{
                    backgroundColor: chat?.department?.color || '#c5c5c5',
                    color: chat?.department?.fontColor || '#505050',
                  }}
                  className="col-span-4 flex flex-col justify-center items-center font-bold rounded-lg"
                >
                  {chat.department?.desc || 'PRÉ-ATENDIMENTO'}
                </span>

                {/* topico */}
                <span className="col-span-4 flex flex-col justify-center font-bold">
                  <div className="line-clamp-2">
                    <strong>{chat.topic || 'N/D'}</strong>
                  </div>
                </span>

                {/* data/hora */}
                <span className="col-span-3 flex flex-col justify-center font-bold">
                  <span className="flex justify-start space-x-1">
                    <ClockIcon className="w-4 h-4" />{' '}
                    {chat?.createdAt &&
                      moment(chat.createdAt).format('DD/MM/YYYY HH:mm')}
                  </span>
                </span>

                {/* aviso de interação */}
                <span className="flex flex-col justify-center items-center">
                  {chat.messages[chat.messages.length - 1]?.fromMe ? (
                    <ChatAlt2Icon className="w-6 h-6 text-green-500" />
                  ) : (
                    <ChatAlt2Icon className="w-6 h-6 text-yellow-500 animate-bounce" />
                  )}
                </span>

                {/* espera */}
                <span className="col-span-2 flex items-center space-x-1">
                  <svg
                    className="animate-spin mr-1 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <strong>
                    {moment().diff(
                      moment(
                        chat.messages[chat.messages.length - 1]?.createdAt,
                      ),
                      'minutes',
                    )}
                    m
                  </strong>
                </span>

                {/* usuario */}
                <span className="col-span-2 flex flex-col justify-center">
                  <span>{chat.user?.user?.toUpperCase() || 'N/D'}</span>
                </span>

                {/* controles */}
                <span className="col-span-1 flex flex-col justify-center">
                  <div className="flex justify-evenly">
                    {!chat.user ? (
                      <button
                        onClick={() => toMeet(chat)}
                        className="font-normal hover:scale-125"
                        title="Atender"
                      >
                        <DotsCircleHorizontalIcon className="w-6 h-6" />
                      </button>
                    ) : currentUserId === chat.user.id ? (
                      <button
                        onClick={() => handlerChat(chat.id)}
                        className="font-normal hover:scale-125"
                        title="Abrir chat"
                      >
                        <ChatIcon className="w-6 h-6" />
                      </button>
                    ) : null}
                  </div>
                </span>
              </div>
            );
          })}
        </div>
      )}
      <ModalChatCreate
        isOpen={modalChatCreateIsOpen}
        setIsOpen={setModalChatCreateIsOpen}
        action={chat => handlerChat(chat.id)}
      />
      <ModalConfirmationBan
        isOpen={modalConfirmBanIsOpen}
        setIsOpen={setModalConfirmBanIsOpen}
        title={'Banir número: ' + chatIdRef.current}
        question={
          'Após banir o atendimento será encerrado, e não será possível mais reabrir!'
        }
        answer={ban}
      />
      <ModalConfirmationReject
        isOpen={modalConfirmRejectIsOpen}
        setIsOpen={setModalConfirmRejectIsOpen}
        title={'Rejeitar Atendimento: ' + chatIdRef.current}
        question={
          'Após rejeitar o atendimento será encerrado, e não será possível mais reabrir!'
        }
        answer={reject}
      />
      <ModalPreview
        title="Atender?"
        isOpen={modalPreviewOpen}
        setIsOpen={setModalPreviewOpen}
        action={openChat}
        banAction={() => initBan(selectedChat.current.phone)}
        rejectAction={() => initReject(selectedChat.current.phone)}
        messages={selectedChat.current.messages}
        notDepartment={selectedChat.current.department?.id ? false : true}
      />
    </>
  );
};

export default AppChatList;
