import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTES_MAP from '../../../app.map.routes';
import Button from '../../../components/layout/button';
import TableUser from './tableUser';
import { User } from './user.model';
import { list } from './user.service';

const CadUser: React.FC = () => {
  const navigate = useNavigate();
  const [users, setUsers] = React.useState<User[]>([]);
  useEffect(() => {
    list().then(setUsers);
  }, []);
  return (
    <div className=" px-4">
      <div className="flex justify-between my-1">
        <strong className="text-xl">Usuários</strong>
        <Button
          title="Inserir"
          onClick={() => navigate(ROUTES_MAP.cadUserNew)}
        />
      </div>
      <TableUser data={users} />
    </div>
  );
};

export default CadUser;
