import { useEffect, useState } from 'react';
import './clock.css';

type Props = {
  size: string;
};

const Clock: React.FC<Props> = (props: Props) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <div style={{ fontSize: props.size }} className="clock">
        {/* hours */}
        <div
          className="hour"
          style={{
            transform: `rotateZ(${time.getHours() * 30}deg)`,
          }}
        />
        {/* minutes */}
        <div
          className="min"
          style={{
            transform: `rotateZ(${time.getMinutes() * 6}deg)`,
          }}
        />
      </div>
    </div>
  );
};

export default Clock;
