import { useState } from 'react';
import { Popover } from '@headlessui/react';
import MenuLeft from './menuLeft';
import MenuLeftBottom from './menuLeftBottom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import logoSvg from '../../assets/svg/logo-name.svg';
import { ReactSVG } from 'react-svg';

import MenuLeftMin from './menuLeftMin';
import { Outlet } from 'react-router-dom';

export default function Layout() {
  const [menuMin, setMenuMin] = useState(true);

  return (
    <div className="flex flex-no-wrap relative h-full">
      {/* menu mobile */}
      <Popover className="w-full absolute shadow z-50">
        <div className="">
          <div className="md:hidden p-2">
            <div className="pt-5 px-5">
              <div className="flex items-center justify-between">
                <div>
                  {/* logo */}
                  <ReactSVG
                    src={logoSvg}
                    className="text-default-white fill-primary1 w-52"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open Menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6"></div>
            </div>
          </div>
        </div>
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          {({ close }) => (
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    {/* logo */}
                    <ReactSVG
                      src={logoSvg}
                      className="text-default-white fill-primary1 w-52"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-1"></div>
              </div>
              <MenuLeft
                onClick={close}
                menuMin={menuMin}
                setMenuMin={setMenuMin}
                onClose={close}
              />

              {/* <MenuLeftBottom /> */}
            </div>
          )}
        </Popover.Panel>
      </Popover>
      {/* menu web */}
      <div
        className={`${
          menuMin ? 'w-16' : 'w-64'
        } transition-all duration-500 ease-in-out absolute sm:relative bg-negative shadow md:h-screen flex-col justify-between hidden sm:flex z-10`}
      >
        <MenuLeftMin menuMin={menuMin} setMenuMin={setMenuMin} />
        {/* {menuMin ? (
          <MenuLeftMin menuMin={menuMin} setMenuMin={setMenuMin} />
        ) : (
          <>
            <MenuLeft menuMin={menuMin} setMenuMin={setMenuMin} />
            <MenuLeftBottom />
          </>
        )} */}
      </div>

      {/* Sidebar ends */}
      {/* Remove class [ h-64 ] when adding a card block */}
      <div className="w-full">
        {/* <div className="h-14 w-full bg-primary1 drop-shadow-md hidden md:flex md:items-center"></div> */}
        <div className="p-0">
          {/* Remove class [ border-dashed border-2 border-gray-300 ] to remove dotted border */}
          <Outlet />
        </div>
      </div>
    </div>
  );
}
