/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { ApiResponse, SearchResult } from '../../../app.models';

import api from '../../../axios';
import { Banlist } from './banlist.model';

export async function list(): Promise<Banlist[]> {
  try {
    const url = '/ban_list/all';
    const { data } = await api.get<ApiResponse<Banlist[]>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function search(
  field: Record<string, any>,
  page?: number,
  limit?: number,
): Promise<SearchResult<Banlist>> {
  try {
    const url = `/ban_list/find?page=${page}&limit=${limit}`;
    const { data } = await api.post<ApiResponse<Banlist[]>>(url, field);
    return data.data as unknown as SearchResult<Banlist>;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function findById(id: string): Promise<Banlist | null> {
  try {
    const url = '/ban_list/' + id;
    const { data } = await api.get<ApiResponse<Banlist>>(url);
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function insert(banlist: Banlist): Promise<Banlist> {
  try {
    const url = '/ban_list';
    const { data } = await api.post<ApiResponse<Banlist>>(url, banlist);
    toast.success('Número cadastrado com sucesso!');
    return data.data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function update(banlist: Banlist): Promise<Banlist> {
  try {
    const url = '/ban_list';
    const { data } = await api.put<Banlist>(url, banlist);
    toast.success('Número atualizado com sucesso!');
    return data;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

export async function remove(banlistId: string): Promise<boolean> {
  try {
    const url = '/ban_list/' + banlistId;
    const { data } = await api.delete<any>(url);
    if (data) {
      toast.success('Número removido com sucesso!');
      return true;
    }
    return false;
  } catch (error: any) {
    if (error.response) {
      showErrors(error.response.data.message);
    }
    throw new Error(error);
  }
}

function showErrors(errors: string | string[]): void {
  if (Array.isArray(errors)) {
    errors.map((error: string) => toast.error(error));
  } else {
    toast.error(errors);
  }
}
