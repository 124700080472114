import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { updatePassword } from '../../app.service';
import Button from '../../components/layout/button';

interface ModalUpdatePasswordProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export default function ModalUpdatePassword(props: ModalUpdatePasswordProps) {
  function closeModal() {
    props.setIsOpen(false);
  }

  const [newPassword, setNewPassword] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');

  const confirm = async () => {
    if (!newPassword || !oldPassword) {
      toast.info('Preencha todos os campos');
      return;
    }

    if (newPassword === oldPassword) {
      toast.info('A nova senha não pode ser igual a antiga');
      return;
    }

    updatePassword({ newPassword, oldPassword });
    closeModal();
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={closeModal}
      open={props.isOpen}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
          >
            <span className="text-lg font-bold leading-6 font-display">
              Alterar senha
            </span>
            <button onClick={closeModal} className="hover:text-gray-400">
              <XIcon className="w-5 h-5" />
            </button>
          </Dialog.Title>
          <div className="flex flex-col font-display py-4 px-6 space-y-2">
            <div className="flex flex-col max-w-xl">
              <label
                htmlFor="department"
                className="block text-sm font-medium text-gray-700"
              >
                Senha Atual
              </label>
              <div className="mt-1 relative shadow-sm flex">
                <input
                  type="password"
                  placeholder={'Senha Atual'}
                  className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                  value={oldPassword}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => setOldPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col max-w-xl">
              <label
                htmlFor="department"
                className="block text-sm font-medium text-gray-700"
              >
                Nova Senha
              </label>
              <div className="mt-1 relative shadow-sm flex">
                <input
                  type="password"
                  placeholder={'Nova Senha'}
                  className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                  value={newPassword}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => setNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="flex justify-between">
              <Button
                title="Cancelar"
                type="button"
                onClick={closeModal}
                colors="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
              />

              <Button
                title="Alterar"
                type="button"
                colors="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                onClick={confirm}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
