import { ChatIcon, UsersIcon } from '@heroicons/react/outline';
import React, { useEffect } from 'react';
import { Chat } from '../chat/chat.model';
import { useSocket } from '../../hooks/socket.context';
import { arrayGroupBy, classNames } from '../../utils/functions';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface ChartData {
  title: string;
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
  }[];
}

const Dashboard: React.FC = () => {
  const { connectedClients, chats } = useSocket();
  const [chatsOnline, setChatsOnline] = React.useState<number>(0);
  const [chatsWaiting, setChatsWaiting] = React.useState<number>(0);
  const [chatsSolving, setChatsSolving] = React.useState<number>(0);
  const [chatsOnDepartment, setChatsOnDepartment] = React.useState<ChartData[]>(
    [],
  );

  useEffect(() => {
    const chatsFilter = chats.filter(chat => chat.department);
    const chatsGrouped = arrayGroupBy(chatsFilter, 'department.desc');
    setChatsWaiting(chatsFilter.filter(el => !el.user).length);
    setChatsSolving(chatsFilter.filter(el => el.user).length);
    setChatsOnline(chatsFilter.length);

    const groupedChats = Object.entries(chatsGrouped);

    const _chatsOnDepartment: ChartData[] = [];
    groupedChats.forEach(([key, value]) => {
      const labels = ['Aguardando', 'Em Atendiemnto'];
      const data = [
        value.filter((el: Chat) => !el.user).length,
        value.filter((el: Chat) => el.user).length,
      ];

      _chatsOnDepartment.push({
        title: key,
        labels: labels,
        datasets: [
          {
            label: key,
            data: data,
            backgroundColor: ['rgb(255, 206, 86)', 'rgb(104, 243, 129)'],
          },
        ],
      } as unknown as ChartData);
    });
    setChatsOnDepartment(_chatsOnDepartment);
  }, [chats]);

  return (
    <div className="mt-28 md:mt-0 p-1 md:p-4">
      <div className="flex flex-col space-y-2 md:space-y-0 md:grid md:grid-cols-4 md:gap-2">
        <div
          className={classNames(
            'w-full p-4 rounded-md',
            'drop-shadow-md bg-slate-200 text-gray-400',
            'grid grid-cols-3 gap-1',
          )}
        >
          <div className="flex justify-center">
            <UsersIcon className="w-10 h-10 mr-2" />
          </div>
          <div className="text-left">Atendentes Online</div>
          <div className="text-center text-5xl font-bold">
            {connectedClients}
          </div>
        </div>
        <div
          className={classNames(
            'w-full p-4 rounded-md',
            'drop-shadow-md bg-blue-200 text-blue-800',
            'grid grid-cols-3 gap-1',
          )}
        >
          <div className="flex justify-center">
            <ChatIcon className="w-10 h-10 mr-2" />
          </div>
          <div className="text-left">Chats</div>
          <div className="text-center text-5xl font-bold">{chatsOnline}</div>
        </div>
        <div
          className={classNames(
            'w-full p-4 rounded-md',
            'drop-shadow-md bg-amber-200 text-amber-800',
            'grid grid-cols-3 gap-1',
          )}
        >
          <div className="flex justify-center">
            <ChatIcon className="w-10 h-10 mr-2" />
          </div>
          <div className="text-left">Em Espera</div>
          <div className="text-center text-5xl font-bold">{chatsWaiting}</div>
        </div>
        <div
          className={classNames(
            'w-full p-4 rounded-md',
            'drop-shadow-md bg-green-200 text-green-800',
            'grid grid-cols-3 gap-1',
          )}
        >
          <div className="flex justify-center">
            <ChatIcon className="w-10 h-10 mr-2" />
          </div>
          <div className="text-left">Em Atendimento</div>
          <div className="text-center text-5xl font-bold">{chatsSolving}</div>
        </div>
        {chatsOnDepartment.length > 0 &&
          chatsOnDepartment.map((data, i) => (
            <div key={i} className="p-2 bg-slate-200 rounded-lg drop-shadow-md">
              <div className="text-center text-gray-400 font-bold">
                {data.title}
              </div>
              <Pie data={data} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Dashboard;
