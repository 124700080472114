import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTES_MAP from '../../../app.map.routes';
import Button from '../../../components/layout/button';
import { Department } from './department.model';
import { list } from './department.service';
import TableDepartment from './tableDepartment';

const CadDepartment: React.FC = () => {
  const navigate = useNavigate();
  const [departments, setDepartments] = React.useState<Department[]>([]);
  useEffect(() => {
    list().then(setDepartments);
  }, []);
  return (
    <div className="px-4">
      <div className="flex justify-between my-1">
        <strong className="text-xl">Departamentos</strong>
        <Button
          title="Inserir"
          onClick={() => navigate(ROUTES_MAP.cadDepartmentNew)}
        />
      </div>
      <TableDepartment data={departments} />
    </div>
  );
};

export default CadDepartment;
