import RelatSvg from '../../assets/svg/relat-index.svg';

const AppRelat = () => {
  return (
    <div className="max-w-3xl mx-auto">
      <img src={RelatSvg} className="w-full md:w-[55vw]" />
    </div>
  );
};

export default AppRelat;
