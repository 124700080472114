/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactTextareaAutosize from 'react-textarea-autosize';
import Button from '../../components/layout/button';
import AppTags from '../../components/tags';
import { read } from './config.service';
import { write } from './config.service';

export function AutoMessages(): JSX.Element {
  const [render, setRender] = useState(0);
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    read().then(data => {
      const entries = Object.entries(data);
      entries.forEach(entry => {
        switch (entry[0]) {
          default: {
            setValue(entry[0], entry[1]);
          }
        }
      });
    });
  }, [setValue]);

  const onSubmit = async (data: unknown) => {
    write(data as Record<string, any>);
  };

  const addTag = (
    tag: string,
    field: string,
    ref: RefObject<HTMLTextAreaElement>,
  ) => {
    const text = getValues(field);
    const selectionStart = ref.current?.selectionStart || text.length;
    const text1 = text.slice(0, selectionStart);
    const text2 = text.slice(selectionStart, text.length);
    const newText = text1 + tag + text2;
    setValue(field, newText);
    setRender(render + 1);
  };
  const transferMsgRef = useRef<HTMLTextAreaElement>(null);
  const attendMsgRef = useRef<HTMLTextAreaElement>(null);
  const preAttendMsgRef = useRef<HTMLTextAreaElement>(null);
  const preTopicsMsgRef = useRef<HTMLTextAreaElement>(null);
  const preAttendSelectedDepartmentMsgRef = useRef<HTMLTextAreaElement>(null);
  const finishMsgRef = useRef<HTMLTextAreaElement>(null);
  const initChatMsgRef = useRef<HTMLTextAreaElement>(null);
  const notAvaliableMsgRef = useRef<HTMLTextAreaElement>(null);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col justify-center md:flex-row md:justify-between my-1">
        <strong className="text-xl font-bold text-center">
          Mensagens Automáticas
        </strong>
        <Button title="Salvar" type="submit" />
      </div>
      <div className="flex flex-col space-y-4 md:space-y-0 md:grid md:grid-cols-2 md:gap-6">
        {/* Pré-atendimento */}
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="preAttendMsg"
            className="block text-sm font-medium text-gray-700"
          >
            Pré-atendimento
          </label>
          <p className="text-[0.6em] text-gray-400">
            Ocorre no momento do primeiro contato, quando é exibido o menu de
            Departamentos.
          </p>
          <div className="mt-1 relative shadow-sm">
            <Controller
              name="preAttendMsg"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ReactTextareaAutosize
                  {...field}
                  ref={preAttendMsgRef}
                  minRows={5}
                  className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                />
              )}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.preAttendMsg && '* Campo requerido'}
          </span>

          {/* tags */}
          <AppTags
            addTag={(tag: string) =>
              addTag(tag, 'preAttendMsg', preAttendMsgRef)
            }
          />
        </div>

        {/* Pré-atendimento-mensagem-de-espera */}
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="preAttendSelectedDepartmentMsg"
            className="block text-sm font-medium text-gray-700"
          >
            Pré-atendimento - Encaminhamento de Departamento
          </label>
          <p className="text-[0.6em] text-gray-400">
            Ocorre no momento que é selecionado um departamento é selecionado.
          </p>
          <div className="mt-1 relative shadow-sm">
            <Controller
              name="preAttendSelectedDepartmentMsg"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ReactTextareaAutosize
                  {...field}
                  ref={preAttendSelectedDepartmentMsgRef}
                  minRows={5}
                  className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                />
              )}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.preAttendSelectedDepartmentMsg && '* Campo requerido'}
          </span>

          {/* tags */}
          <AppTags
            addTag={(tag: string) =>
              addTag(
                tag,
                'preAttendSelectedDepartmentMsg',
                preAttendSelectedDepartmentMsgRef,
              )
            }
          />
        </div>

        {/* Pré-atendimento-topicos */}
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="preAttendSelectedDepartmentMsg"
            className="block text-sm font-medium text-gray-700"
          >
            Pré-atendimento - Tópicos ou Assunto
          </label>
          <p className="text-[0.6em] text-gray-400">
            Ocorre no momento que é selecionado um departamento é selecionado.
          </p>
          <div className="mt-1 relative shadow-sm">
            <Controller
              name="preTopicsMsg"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ReactTextareaAutosize
                  {...field}
                  ref={preTopicsMsgRef}
                  minRows={5}
                  className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                />
              )}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.preTopicsMsg && '* Campo requerido'}
          </span>

          {/* tags */}
          <AppTags
            addTag={(tag: string) =>
              addTag(
                tag,
                'preAttendSelectedDepartmentMsg',
                preAttendSelectedDepartmentMsgRef,
              )
            }
          />
        </div>

        {/* Atendimento Inicial */}
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="attendMsg"
            className="block text-sm font-medium text-gray-700"
          >
            Atendimento Inicial
          </label>
          <p className="text-[0.6em] text-gray-400">
            Ocorre no primeiro contato e após a escolha do departamento, quando
            o atendente pega o chat.
          </p>
          <div className="mt-1 relative shadow-sm">
            <Controller
              name="attendMsg"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ReactTextareaAutosize
                  {...field}
                  ref={attendMsgRef}
                  minRows={5}
                  className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                />
              )}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.attendMsg && '* Campo requerido'}
          </span>

          {/* tags */}
          <AppTags
            addTag={(tag: string) => addTag(tag, 'attendMsg', attendMsgRef)}
          />
        </div>

        {/* Transferência de Atendimento */}
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="desc"
            className="block text-sm font-medium text-gray-700"
          >
            Transferência de Atendimento
          </label>
          <p className="text-[0.6em] text-gray-400">
            Ocorre durante o atendimento, quando o atendente transfere o chat.
          </p>
          <div className="mt-1 relative shadow-sm">
            <Controller
              name="transferMsg"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ReactTextareaAutosize
                  {...field}
                  ref={transferMsgRef}
                  minRows={5}
                  className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                />
              )}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.transferMsg && '* Campo requerido'}
          </span>

          {/* tags */}
          <AppTags
            addTag={(tag: string) => addTag(tag, 'transferMsg', transferMsgRef)}
          />
        </div>

        {/* Finalizar Atendimento */}
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="finishMsg"
            className="block text-sm font-medium text-gray-700"
          >
            Finalizar Atendimento
          </label>
          <p className="text-[0.6em] text-gray-400">
            Ocorre durante o atendimento, quando o atendente finaliza o chat.
          </p>
          <div className="mt-1 relative shadow-sm">
            <Controller
              name="finishMsg"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ReactTextareaAutosize
                  {...field}
                  ref={finishMsgRef}
                  minRows={5}
                  className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                />
              )}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.finishMsg && '* Campo requerido'}
          </span>

          {/* tags */}
          <AppTags
            addTag={(tag: string) => addTag(tag, 'finishMsg', finishMsgRef)}
          />
        </div>

        {/* Inicializar Atendimento */}
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="initChatMsg"
            className="block text-sm font-medium text-gray-700"
          >
            Inicializar atendimento pelo operador
          </label>
          <p className="text-[0.6em] text-gray-400">
            Ocorre quando o próprio atendente inicia um atendimento novo.
          </p>
          <div className="mt-1 relative shadow-sm">
            <Controller
              name="initChatMsg"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ReactTextareaAutosize
                  {...field}
                  ref={initChatMsgRef}
                  minRows={5}
                  className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                />
              )}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.initChatMsg && '* Campo requerido'}
          </span>

          {/* tags */}
          <AppTags
            addTag={(tag: string) => addTag(tag, 'initChatMsg', initChatMsgRef)}
          />
        </div>

        {/* Atendimento indisponível */}
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="notAvaliableMsg"
            className="block text-sm font-medium text-gray-700"
          >
            Atendimento indisponível
          </label>
          <p className="text-[0.6em] text-gray-400">
            Ocorre quando o atendimento está fora do horário ou não há
            operadores online
          </p>
          <div className="mt-1 relative shadow-sm">
            <Controller
              name="notAvaliableMsg"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ReactTextareaAutosize
                  {...field}
                  ref={notAvaliableMsgRef}
                  minRows={5}
                  className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                />
              )}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.notAvaliableMsg && '* Campo requerido'}
          </span>

          {/* tags */}
          <AppTags
            addTag={(tag: string) =>
              addTag(tag, 'notAvaliableMsg', notAvaliableMsgRef)
            }
          />
        </div>
      </div>
    </form>
  );
}
