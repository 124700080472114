import {
  ExclamationIcon,
  PrinterIcon,
  SearchIcon,
} from '@heroicons/react/outline';
import moment from 'moment';
import { useRef, useState } from 'react';
import useMedia from 'use-media';
import Button from '../../../components/layout/button';
import PageLoading from '../../../components/pageLoading';
import { useAuth } from '../../../hooks/auth.hook';
import { getSatisfaction, secondsToMinutes } from '../../../utils/functions';
import { Chat } from '../../chat/chat.model';
import { search } from '../../chat/chat.service';
import ListMessages from '../../chat/listMessages';
import { generateProtocolPdf } from '../relat.service';

export default function RelatProtocol(): JSX.Element {
  const isSmall = useMedia({ maxWidth: 480 });
  const [chat, setChat] = useState<Chat | null>();
  const [protocol, setProtocol] = useState<string>();
  const messagesRef = useRef<HTMLDivElement>(null);
  const { getJwt } = useAuth();
  const [loading, setLoading] = useState(false);

  const find = (): void => {
    if (!protocol) {
      setProtocol(undefined);
      setChat(undefined);
      return;
    }
    if (protocol.length < 10) {
      setProtocol(undefined);
      setChat(undefined);
      return;
    }
    setLoading(true);
    search({ protocol })
      .then(data => {
        if (data.length > 0) {
          setChat(data[0]);
          // feed(messagesRef);
        } else {
          setChat(null);
          setProtocol(undefined);
        }
      })
      .finally(() => setLoading(false));
  };

  const printer = (): void => {
    const user = getJwt()?.user;
    if (!chat || !user) return;
    generateProtocolPdf(chat, user);
  };

  return (
    <div className="w-full px-2">
      <div className="flex justify-between items-center my-1">
        <h1 className="text-lg font-bold text-gray-300">
          Análise de Atendimento
        </h1>
        {chat?.id && (
          <Button
            title="Imprimir"
            icon={<PrinterIcon className="w-4 h-4" />}
            onClick={printer}
          />
        )}
      </div>
      <div className="flex justify-start items-center my-1 space-x-2">
        {/* <strong>Protocolo:</strong> */}
        <input
          type="text"
          id="phone"
          className="form-input px-3 py-2 w-full max-w-xs rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
          placeholder="Protocolo"
          value={protocol}
          onChange={(e): void => setProtocol(e.target.value)}
        />
        <Button
          title="Localizar"
          icon={<SearchIcon className="w-4 h-4" />}
          onClick={find}
        />
      </div>
      {loading ? (
        <div className="mt-24">
          <PageLoading height="w-24" width="h-24" />
        </div>
      ) : (
        <>
          {chat !== undefined ? (
            !chat?.id ? (
              <div className="mt-28 md:mt-0">
                <div className="h-[70vh] flex flex-col justify-center items-center">
                  <ExclamationIcon className="w-36 text-gray-400" />
                  <h1 className="text-3xl text-gray-400 font-bold text-center">
                    Protocolo não localizado :(
                  </h1>
                </div>
              </div>
            ) : (
              <div className="flex flex-col md:grid md:grid-cols-2 md:gap-4">
                <div className="flex flex-col divide-y overflow-hidden overflow-y-auto md:h-[70vh] pr-2">
                  <div className="flex justify-between items-center py-2">
                    <strong className="text-sm font-medium text-gray-700">
                      Protocolo:
                    </strong>
                    <span className="text-sm font-medium text-gray-700">
                      {chat?.protocol}
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <strong className="text-sm font-medium text-gray-700">
                      Data:
                    </strong>
                    <span className="text-sm font-medium text-gray-700">
                      {moment(chat?.createdAt).format('DD/MM/YYYY')}
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <strong className="text-sm font-medium text-gray-700">
                      Hora:
                    </strong>
                    <span className="text-sm font-medium text-gray-700">
                      {moment(chat?.createdAt).format('HH:MM')}
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <strong className="text-sm font-medium text-gray-700">
                      Celular:
                    </strong>
                    <span className="text-sm font-medium text-gray-700">
                      {chat?.phone}
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <strong className="text-sm font-medium text-gray-700">
                      Nome:
                    </strong>
                    <span className="text-sm font-medium text-gray-700">
                      {chat?.chatName}
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <strong className="text-sm font-medium text-gray-700">
                      Departamento:
                    </strong>
                    <span className="text-sm font-medium text-gray-700">
                      {chat?.department.desc}
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <strong className="text-sm font-medium text-gray-700">
                      Atendedente:
                    </strong>
                    <span className="text-sm font-medium text-gray-700">
                      {chat?.user.name}
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <strong className="text-sm font-medium text-gray-700">
                      Tempo de Atendimento:
                    </strong>
                    <span className="text-sm font-medium text-gray-700">
                      {secondsToMinutes(
                        moment(chat?.finishedAt).diff(
                          moment(chat?.attendedAt),
                          'seconds',
                        ),
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <strong className="text-sm font-medium text-gray-700">
                      Tempo de Espera:
                    </strong>
                    <span className="text-sm font-medium text-gray-700">
                      {secondsToMinutes(
                        moment(chat?.attendedAt).diff(
                          moment(chat?.openedAt),
                          'seconds',
                        ),
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between items-center py-2">
                    <strong className="text-sm font-medium text-gray-700">
                      Nível de satisfação:
                    </strong>
                    <span className="text-sm font-medium text-gray-700">
                      {chat?.satisfaction && getSatisfaction(chat.satisfaction)}
                    </span>
                  </div>
                  <div className="flex flex-col py-2">
                    <strong className="text-sm font-medium text-gray-700">
                      Comentário:
                    </strong>
                    <textarea
                      rows={4}
                      className="w-full text-sm font-medium text-gray-700 resize-none"
                    >
                      {chat?.obs}
                    </textarea>
                  </div>
                </div>
                <div>
                  {chat?.messages && chat.messages.length > 0 && (
                    <ListMessages
                      messages={chat.messages.sort((a, b) => {
                        return a.createdAt > b.createdAt ? 1 : -1;
                      })}
                      sending={false}
                      height={isSmall ? 'h-full' : '70vh'}
                      ref={messagesRef}
                    />
                  )}
                </div>
              </div>
            )
          ) : null}
        </>
      )}
    </div>
  );
}
