import { IMenu, MENU_ITEMS } from './menu';
import logoSvg from '../../assets/svg/logo-full.svg';
import { ReactSVG } from 'react-svg';
import { useLocation, NavLink, Link } from 'react-router-dom';
import MenuSwitch from './menuSwitch';
import MenuLeftPair from './menuLeftPair';
import { useSocket } from '../../hooks/socket.context';
import { useAuth } from '../../hooks/auth.hook';
import { Comunication } from '../../app.models';
import ROUTES_MAP from '../../app.map.routes';

interface MenuLeftProps {
  onClick?: () => void;
  menuMin: boolean;
  setMenuMin: (value: boolean) => void;
  onClose?: () => void;
}

const menuSimpleClass =
  'flex items-center justify-between w-full text-primary1 md:text-default-white hover:opacity-50 ml-0';
// const menuSubClass =
//   'flex flex-col w-full text-primary1 md:text-default-white cursor-pointer items-center hover:opacity-50 my-2 mx-1';

const MenuSimple = (menu: IMenu) => {
  const location = useLocation();
  return (
    <NavLink
      className={menuSimpleClass}
      to={menu.link}
      onClick={() => {
        if (location.pathname === menu.link.trim()) return;
        menu.onClick && menu.onClick();
      }}
    >
      {({ isActive }) => (
        <div className="flex items-center space-x-1">
          <span
            className={`p-2 ${
              isActive
                ? 'text-default-white bg-primary1'
                : 'bg-default-white text-primary1'
            } rounded-full shadow`}
          >
            {menu.icon}
          </span>
          <span className="text-sm font-bold truncate">{menu.title}</span>
        </div>
      )}
    </NavLink>
  );
};

export default function MenuLeft(props: MenuLeftProps) {
  const { comunications } = useSocket();
  const { getJwt } = useAuth();

  const filter = (c: Comunication) =>
    c.archived === false &&
    c.status === 'closed' &&
    c.receiver.id === getJwt()?.id;

  const comunicationsCount = comunications.filter(filter).length;
  return (
    <div className="px-8">
      <div className="py-1 hidden md:block">
        <MenuSwitch checked={props.menuMin} onChange={props.setMenuMin} />
      </div>
      <div className="h-14 w-full items-center hidden md:flex mt-4">
        <ReactSVG
          src={logoSvg}
          className="text-white fill-current w-full p-7 mt-2"
        />
      </div>
      {comunicationsCount > 0 && (
        <Link
          to={ROUTES_MAP.myProfile}
          className="text-xs text-red-400 mt-2 underline"
          onClick={props.onClose}
        >
          Você tem {comunicationsCount} recado(s) não lido(s)
        </Link>
      )}
      <ul className="mt-4 pb-6 w-full flex flex-col space-y-3">
        {MENU_ITEMS.map((item, idx) => (
          <li key={idx}>
            <MenuSimple onClick={props.onClick} {...item} />
          </li>
        ))}
      </ul>
      <MenuLeftPair />
    </div>
  );
}
