import moment from 'moment';
import React from 'react';
import { getPhoneFormat, secondsToMinutes } from '../../../utils/functions';
import { Chat } from '../../chat/chat.model';

type Props = {
  data: Chat[];
  rowClick?: (chat: Chat) => void;
};

const CardRelatAttendeance: React.FC<Props> = (props: Props) => {
  const satisfaction = (arg: number | string): string => {
    const value = parseInt(arg as string, 10);
    switch (value) {
      case 1:
        return 'Nada Satisfeito';
      case 2:
        return 'Satisfeito';
      case 3:
        return 'Muito Satisfeito';
      default:
        return 'N/D';
    }
  };
  return (
    <div className="flex flex-col space-y-2">
      {props.data.map(data => (
        <button
          key={data.id}
          className="cursor-pointer hover:bg-gray-100 bg-gray-200 rounded-lg text-xs p-1"
          onClick={() => props.rowClick && props.rowClick(data)}
        >
          <div className="flex justify-between">
            <div className="text-left">
              {moment(data.createdAt).format('DD/MM/YYYY')}
            </div>
            <div className="text-left">{data.protocol}</div>
          </div>
          <div className="text-left">Cel: {getPhoneFormat(data.phone)}</div>
          <div className="flex justify-between">
            <div className="text-left">{satisfaction(data.satisfaction)}</div>
            <div className="text-left">Usuário: {data.user.user}</div>
          </div>

          <div className="flex justify-between">
            {/* espera */}
            <div className="text-left">
              {secondsToMinutes(
                moment(data.attendedAt).diff(moment(data.openedAt), 'seconds'),
              )}
            </div>

            {/* tempo */}
            <div className="text-left">
              {secondsToMinutes(
                moment(data.finishedAt).diff(
                  moment(data.attendedAt),
                  'seconds',
                ),
              )}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-left">
              Avaliado: {data.evaluated ? 'Sim' : 'Não'}
            </div>
            <div className="text-left">
              Tem comentário{data.obs ? 'Sim' : 'Não'}
            </div>
          </div>
        </button>
      ))}
    </div>
  );
};

export default CardRelatAttendeance;
