import ReactPaginate from 'react-paginate';

type Props = {
  onPageChange: (selectedItem: { selected: number }) => void;
  pageCount: number;
  page: number;
};

export default function AppPaginate(props: Props) {
  return (
    <ReactPaginate
      className="flex text-xs"
      pageClassName="mx-1 flex flex-col justify-center bg-gray-200 text-gray-700 hover:bg-negative hover:text-gray-200 rounded-lg"
      activeClassName="bg-negative text-gray-200"
      pageLinkClassName="px-4 py-1"
      breakLabel="..."
      nextLabel="Próximo"
      nextClassName="mx-1 flex flex-col justify-center bg-gray-200 text-gray-700 hover:bg-negative hover:text-gray-200 rounded-lg"
      nextLinkClassName="px-4 py-1"
      onPageChange={props.onPageChange}
      pageRangeDisplayed={5}
      pageCount={props.pageCount}
      forcePage={props.page - 1}
      previousLabel="Anterior"
      previousClassName="mx-1 flex flex-col justify-center bg-gray-200 text-gray-700 hover:bg-negative hover:text-gray-200 rounded-lg"
      previousLinkClassName="px-4 py-1"
      renderOnZeroPageCount={() => null}
    />
  );
}
