import { useEffect, useState } from 'react';
import { readTags } from '../app.service';

type Props = {
  addTag: (tag: string) => void;
};

export default function AppTags(props: Props): JSX.Element {
  const [tags, setTags] = useState<Record<string, string>[]>([]);
  useEffect(() => {
    readTags().then(setTags);
  }, []);
  return (
    <>
      <h1 className="text-xs text-gray-300">Tags</h1>
      <div className="flex flex-wrap space-x-1">
        {tags.map((tag, i) => (
          <button
            key={i}
            type="button"
            onClick={() => {
              props.addTag(tag.value);
            }}
            className="px-2 py-[1px] mx-1 bg-gray-100 hover:bg-gray-200 text-gray-400 text-[.6em] rounded-md shadow-md"
          >
            {tag.key}
          </button>
        ))}
      </div>
    </>
  );
}
