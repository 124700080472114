/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosError } from 'axios';
import { API_URL, LOCAL_STORAGE_JWT, LOCAL_STORAGE_RJWT } from './app.settings';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const DEBUG = false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const serialize = (obj: any) => {
  const str = [];
  // eslint-disable-next-line prefer-const
  for (let p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }

  return str.join('&');
};

const _axios = axios.create({
  baseURL: API_URL,
});

_axios.interceptors.request.use(
  config => {
    /** In dev, intercepts request and logs it into console for dev */
    if (DEBUG) {
      console.info('✉️ axios-request: ', config);
    }
    const token = localStorage.getItem(LOCAL_STORAGE_JWT);
    const newHeards = config.headers;
    if (token && newHeards) newHeards.Authorization = `Bearer ${token}`;
    return { ...config, headers: newHeards };
  },
  error => {
    if (DEBUG) {
      console.error('✉️ axios-request: ', error);
    }
    return Promise.reject(error);
  },
);

const refreshAuthLogic = (failedRequest: any) =>
  axios
    .post(
      '/auth/refresh',
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_RJWT)}`,
        },
        baseURL: API_URL,
      },
    )
    .then(tokenRefreshResponse => {
      localStorage.setItem(
        LOCAL_STORAGE_JWT,
        tokenRefreshResponse.data.access_token,
      );
      localStorage.setItem(
        LOCAL_STORAGE_RJWT,
        tokenRefreshResponse.data.refresh_token,
      );
      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + tokenRefreshResponse.data.refresh_token;

      return Promise.resolve();
    });

createAuthRefreshInterceptor(_axios, refreshAuthLogic);

axios.interceptors.response.use(
  response => {
    return response;
  },
  (error: AxiosError) => {
    if (DEBUG) {
      console.info('✉️ axios-error: ', error);
    }
    if (error.response?.status === 401) {
      localStorage.removeItem(LOCAL_STORAGE_JWT);
      localStorage.removeItem(LOCAL_STORAGE_RJWT);
      // history.pushState(null, '/login');
      self.location.reload();
    }
    return Promise.reject(error);
  },
);

export default _axios;
