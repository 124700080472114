import { Dialog } from '@headlessui/react';
import {
  BanIcon,
  ShieldExclamationIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { Message } from '../chat/chat.model';
import Button from '../../components/layout/button';
import { feed } from './chat.service';
import ListMessages from './listMessages';
import { Department } from '../cad/department/department.model';
import { useAuth } from '../../hooks/auth.hook';

interface ModalPreviewProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  action?: (department: string | undefined) => void;
  banAction?: () => void;
  rejectAction?: () => void;
  onClose?: () => void;
  messages: Message[];
  title: string;
  notDepartment: boolean;
}

export default function ModalPreview(props: ModalPreviewProps) {
  const { myProfile } = useAuth();
  const ref = useRef<HTMLDivElement>(null);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [seletedDep, setSelectedDep] = useState<string>();
  const [info, setInfo] = useState<string>('');

  useEffect(() => {
    myProfile().then(({ departments }) => {
      setDepartments(departments);
    });
  }, [myProfile]);

  function closeModal() {
    if (props.onClose) {
      props.onClose();
    }
    props.setIsOpen(false);
  }

  const confirm = async () => {
    if (props.action) {
      if (props.notDepartment) {
        if (!seletedDep) {
          setInfo('Selecione um departamento');
          // await new Promise(t => setTimeout(t, 3000));
          // setInfo('');
          return;
        }
        props.action(seletedDep);
      } else {
        props.action(undefined);
      }
      closeModal();
    }
  };

  useEffect(() => {
    feed(ref);
  }, [props.messages]);

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={closeModal}
      open={props.isOpen}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
          >
            <span className="text-lg font-bold leading-6 font-display">
              {props.title}
            </span>
            <button onClick={closeModal} className="hover:text-gray-400">
              <XIcon className="w-5 h-5" />
            </button>
          </Dialog.Title>
          <div className="flex flex-col font-display py-4 px-6 space-y-2">
            <ListMessages
              messages={props.messages}
              sending={false}
              height={'60vh'}
              ref={ref}
            />
            {props.action && (
              <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:justify-between md:space-x-1">
                <div className="flex justify-between md:justify-start space-x-2">
                  <Button
                    title="Rejeitar"
                    type="button"
                    icon={<ShieldExclamationIcon className="w-4 h-4 " />}
                    onClick={() => {
                      if (props.rejectAction) {
                        props.rejectAction();
                      }
                      closeModal();
                    }}
                    colors="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
                  />
                  <Button
                    title="Banir"
                    type="button"
                    icon={<BanIcon className="w-4 h-4 " />}
                    onClick={() => {
                      if (props.banAction) {
                        props.banAction();
                      }
                      closeModal();
                    }}
                    colors="bg-red-500 text-white hover:bg-red-400 hover:text-gray-100"
                  />
                </div>
                {props.notDepartment && (
                  <select
                    className="form-input px-3 py-2 w-full bg-gray-200 text-gray-700 border-gray-200 focus:outline-none focus:bg-white focus:border-gray-500"
                    placeholder="Departamento"
                    value={seletedDep}
                    onChange={e => setSelectedDep(e.target.value)}
                  >
                    <option value="">Departamento</option>
                    {departments.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.desc}
                      </option>
                    ))}
                  </select>
                )}
                <Button
                  title="Atender"
                  type="button"
                  colors="bg-green-500 text-white hover:bg-green-400 hover:text-gray-100"
                  onClick={confirm}
                />
              </div>
            )}
          </div>
          {info && <div className="text-red-500 px-3 text-right">{info}</div>}
        </div>
      </div>
    </Dialog>
  );
}
