import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES_MAP from '../../../app.map.routes';
import Button from '../../../components/layout/button';
import ModalConfirmation from '../../../components/modalConfirmation';
import { PredefinedMessage } from './predefinedMessage.model';
import { findById, insert, remove, update } from './predefinedMessage.service';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { ReplyIcon } from '@heroicons/react/outline';
import AppTags from '../../../components/tags';
import useMedia from 'use-media';

interface LocationState {
  id: string | undefined;
}

const FormPredefinedMessage: React.FC = () => {
  const isSmall = useMedia({ maxWidth: 480 });
  const location = useLocation();
  const navigate = useNavigate();
  const [render, setRender] = useState(0);

  const ref = useRef<HTMLTextAreaElement>(null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useForm<PredefinedMessage>();

  const fieldId = watch('id');

  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState<boolean>(false);

  const onSubmit = async (data: unknown) => {
    const predefinedMessage = data as PredefinedMessage;
    if (!predefinedMessage.id) {
      delete predefinedMessage.id;
      await insert(predefinedMessage);
      navigate(ROUTES_MAP.cadPredefinedMessage);
    } else {
      const ret = await update(predefinedMessage);
      loadForm(ret);
    }
  };

  const openModal = () => setModalConfirmIsOpen(true);

  const del = async () => {
    const predefinedMessageId = getValues('id');
    if (predefinedMessageId) {
      await remove(predefinedMessageId);
      navigate(ROUTES_MAP.cadPredefinedMessage);
    }
  };

  const loadForm = useCallback(
    (data: PredefinedMessage) => {
      const entries = Object.entries(data);
      entries.forEach(entry => {
        switch (entry[0]) {
          default: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue<any>(entry[0], entry[1]);
          }
        }
      });
    },
    [setValue],
  );

  const back = () => {
    navigate(ROUTES_MAP.cadPredefinedMessage, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state: { params: (location.state as any)?.['params'] },
    });
  };

  const addTag = (tag: string) => {
    const text = getValues('message');
    const selectionStart = ref.current?.selectionStart || text.length;
    const text1 = text.slice(0, selectionStart);
    const text2 = text.slice(selectionStart, text.length);
    const newText = text1 + tag + text2;
    setValue('message', newText);
    setRender(render + 1);
  };

  useEffect(() => {
    async function load() {
      const { id } = (location.state as LocationState) || { id: undefined };
      if (id) {
        const predefinedMessage = await findById(id);
        if (predefinedMessage) {
          loadForm(predefinedMessage);
        }
      }
    }
    load();
  }, [loadForm, location.state, setValue]);

  return (
    <div className="md:h-[85vh] px-4 overflow-hidden overflow-y-auto">
      {isSmall && (
        <strong className="text-xl">
          {fieldId ? 'Alterar Mensagem' : 'Inserir Mensagem'}
        </strong>
      )}
      <form
        onSubmit={handleSubmit<PredefinedMessage>(onSubmit)}
        className="mx-auto max-w-xl mt-4"
      >
        <div className="flex justify-between my-1">
          <div className="flex space-x-1">
            <Button icon={<ReplyIcon className="w-4" />} onClick={back} />
            {!isSmall && (
              <strong className="text-xl">
                {fieldId ? 'Alterar Mensagem' : 'Inserir Mensagem'}
              </strong>
            )}
          </div>
          <div className="flex space-x-1">
            {fieldId && (
              <Button
                title="Excluir"
                colors="bg-red-500 text-white"
                type="button"
                onClick={openModal}
              />
            )}
            <Button title="Salvar" type="submit" />
          </div>
        </div>
        <input type="hidden" {...register('id')} />
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="desc"
            className="block text-sm font-medium text-gray-700"
          >
            Descrição
          </label>
          <div className="mt-1 relative shadow-sm">
            <input
              type="text"
              id="desc"
              className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
              placeholder="Descrição"
              {...register('desc', { required: true })}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.desc && '* Campo requerido'}
          </span>
        </div>
        <div className="flex flex-col max-w-xl">
          <label
            htmlFor="desc"
            className="block text-sm font-medium text-gray-700"
          >
            Mensagem
          </label>
          <div className="mt-1 relative shadow-sm">
            <Controller
              name="message"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ReactTextareaAutosize
                  {...field}
                  ref={ref}
                  minRows={5}
                  className="form-input px-3 py-2 w-full rounded-md bg-gray-200 text-gray-700 border-gray-200 border-2 focus:outline-none focus:bg-white focus:border-gray-500"
                />
              )}
            />
          </div>
          <span className="text-xs text-red-500">
            {errors.message && '* Campo requerido'}
          </span>

          {/* tags */}
          <div className="my-3 md:my-1">
            <AppTags addTag={(tag: string) => addTag(tag)} />
          </div>
        </div>
      </form>
      <ModalConfirmation
        isOpen={modalConfirmIsOpen}
        setIsOpen={setModalConfirmIsOpen}
        title={'Exclusão'}
        question={'Excluir mensagem: ' + getValues('desc') + '?'}
        answer={del}
      />
    </div>
  );
};

export default FormPredefinedMessage;
